<template>
  <div class="button">
    <b-icon-arrow-right-square-fill
      class="arrow h1"
    ></b-icon-arrow-right-square-fill>
  </div>
</template>

<script>
export default {
};
</script>

<style scoped>
.arrow :hover {
  color: #d1a431;
}
.arrow {
  color: #ffc83a;
  cursor: pointer;
  background-color: black;
  border-radius: 8px;
}
.button {
  position: absolute;
  bottom: 0;
  right: 10px;
}
</style>