<template>
  <WidgetBase :title="$t(item.title)" v-if="getCurrentRegion != ''" class="widgetItem">
    <Spinner v-if="loading"></Spinner>
    <BarChart
      v-if="chartData && !loading"
      :key="getCurrentRegion"
      class="chart"
      :chartData="chartData"
      :chartOptions="chartOptions"
    ></BarChart>
  </WidgetBase>
  <div v-else class="noRegionSelected"><h2>Please select a region</h2></div>
</template>

<script>
import BarChart from "./widgetComponents/charts/BarChart.vue";
import Spinner from "./widgetComponents/Spinner";
import WidgetBase from "./widgetComponents/WidgetBase.vue";
import { mapGetters } from "vuex";
import {
  getAreasByRegion,
  getContainersWithinArea,
  getAreas,
} from "@/services/reenapi.js";

export default {
  name: "AreaOverview",
  components: {
    WidgetBase,
    Spinner,
    BarChart,
  },
  props: {
    options: {
      type: Object,
      required: false,
    },item: {
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      chartData: null,
      chartOptions: {
        scales: {
          xAxes: [{
            maxBarThickness: 20
          }],
            yAxes: [{
                ticks: {
                    beginAtZero:true
                }
            }]
        },
        plugins: {
          tooltip: {
            
          },
          legend: {
            display: false
          }
        },
        layout: {
          padding: 15,
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    };
  },
  computed: {
    ...mapGetters(["getCurrentRegion"]),
  },
  watch: {
    getCurrentRegion(newValue) {
      this.getAreas(this.getCurrentRegion)
    }
  },
  methods: {
    async getAreas(region) {
      this.loading = true;
      let areasInRegion
      if (region == 'All') areasInRegion = await getAreas()
      else areasInRegion = await getAreasByRegion(region);

      for (let area of areasInRegion.data) {
        area.containers = await getContainersWithinArea(area.coordinates);
      }

      this.createDataset(areasInRegion.data)
    },
    createDataset(areas) {
      var chartData = {
        labels: [],
        datasets: [
          {
            label: "Områder",
            data: [],
            backgroundColor: [
            ],
            hoverOffset: 4,
          },
        ],
      };
      for (let item of areas) {
        chartData.labels.push(item.name);
        chartData.datasets[0].data.push(item.containers.length);
        chartData.datasets[0].backgroundColor.push('#2A085C')
      }
      this.loading = false
      this.chartData = chartData
    },
    returnObjectCoordinates(data) {
      // API returns X = lng, Y = lat, this needs to be inverted to interact with google maps functions.
      try {
        let coordinates = [];
        for (let value of data) {
          let coord = {
            y: value.x,
            x: value.y,
          };

          coordinates.push(coord);
        }
        return coordinates;
      } catch (e) {
        console.log(e);
      }
    },
    async getContainersWithinArea(coordinates) {
      coordinates = this.returnObjectCoordinates(coordinates);

      for (let coord of coordinates) {
        let x = parseFloat(coord.y).toFixed(6);
        let y = parseFloat(coord.x).toFixed(6);

        coord.x = x;
        coord.y = y;
      }

      let response = await getContainersWithinArea(coordinates);
      return response.data;
    },
  },
};
</script>

<style scoped>
.chart {
  cursor: pointer;
}
.noRegionSelected {
  text-align: center;
}

</style>
