<template>
  <ModuleBase class="inventory-widget">
    <div class="headline">
      <div class="emptyBlock"></div>
      <header>
        <h2>Utleid</h2>
        <p>Alle utleide containere.</p>
      </header>
      <span @click="$emit('remove')" class="removeWidgetBtn">
        <b-icon-x-circle style="width: 40px; height: 40px"></b-icon-x-circle>
      </span>
    </div>
    <p class="big-text">{{ getRentedOutContainers() }}</p>

    <b-button to="fleetmanagement/vehicle">Oversikt</b-button>
  </ModuleBase>
</template>

<script>
import ModuleBase from "@/components/controlcenter/modules/ModuleBase";

export default {
  name: "ContainerOut",
  components: {
    ModuleBase,
  },
  props: {
    options: {
      type: Object,
      required: false,
    },item: {
      type: Object
    }
  },
  data() {
    return {};
  },
  methods: {
    getRentedOutContainers() {
      let containers = 792;
      // Replace with actual information once API supports it.
      return containers;
    },
  },
};
</script>

<style scoped>
h6 {
  text-align: left;
}
</style>
