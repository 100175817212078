<template>
  <b-container>
    <!-- <b-row> -->
    <b-col>
      <b-row>
        <h2>OPPDRAGSLISTE</h2>
      </b-row>

      <b-row>
        <b-form-checkbox> Hent oppdrag fra alle brukere </b-form-checkbox>
      </b-row>

      <div class="divider"></div>

      <b-row>
        <Select
          label="1) Velg bil"
          v-on:input="car = $event"
          :values="carValues"
        />
      </b-row>

      <br /><br />
      <b-row>
        <h5>2) Velg et aktiv oppdrag:</h5>
      </b-row>
      <b-row>
        <ul>
          <div
            class="assignmentList"
            v-for="(item, index) in assignmentsValues"
            :key="index"
            @click="$emit('change', page)"
          >
            <li>
              <strong>{{ item.name }}</strong>
            </li>
            <li>Oppdrag på rode: {{ item.rode }}</li>
            <li>Containere i oppdrag: {{ item.container }}</li>
          </div>
        </ul>
      </b-row>
    </b-col>
    <!-- </b-row> -->
  </b-container>
</template>

<script>
import Select from "../driver/subComponents/SelectInput.vue";

export default {
  name: "AssignmentsList",
  components: {
    Select,
  },
  data() {
    return {
      page: "Assignment",
      carValues: ["Toyota", "BMW", "Audi"],
      assignmentsValues: [
        {
          name: "Oppdrag 1",
          rode: "3260/Larvik",
          container: "7/7",
        },
        {
          name: "Oppdrag 2",
          rode: "3160/Stokke",
          container: "5/7",
        },
        {
          name: "Oppdrag 3",
          rode: "3180/Høyjord",
          container: "5/5",
        },
      ],
    };
  },
};
</script>

<style scoped>
.container {
  color: black;
  display: flex;
  justify-content: center;
  margin-top: 70px;
  padding: 3%;
  position: relative;
}
.assignmentList {
  margin-top: 25px;
  cursor: pointer;
  border: 1px solid #b5b5b5;
  border-radius: 0.25rem;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  width: 100%;
}
.assignmentList:hover {
  box-shadow: 0px 4px #65bf95;
}
ul {
  padding-left: 0;
  width: 100%;
}
li {
  list-style-type: none;
}
.divider {
  border-bottom: 1px solid #65bf95;
  margin-top: 25px;
  margin-left: -15px;
  margin-right: -15px;
}
</style>
