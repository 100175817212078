<template>
<!-- <b-container> -->

  <div class="button" @click="$emit('change', 'UnloadContainer')">
    <strong>TØM</strong>
  </div>
<!-- </b-container> -->
</template>

<script>
export default {
};
</script>

<style scoped>
.button {
  position: fixed;
  z-index: 2000;
  background-color: #65bf95;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  bottom: 70px;
  right: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px #B5B5B5;
  cursor: pointer;
}
</style>
