<template>
  <WidgetBase class="base" :title="null">
    <GoogleMap v-if="location" :currentLocation="location"></GoogleMap>
    <MenuCard class="menuCard"></MenuCard>
  </WidgetBase>
</template>

<script>
import WidgetBase from "./widgetComponents/WidgetBase.vue";
import GoogleMap from "./widgetComponents/GoogleMap.vue";
export default {
  name: "Map",
  components: {
    WidgetBase,
    GoogleMap,
    MenuCard: () => import("./widgetComponents/mapComponents/ItemInfo"),
  },
  data() {
    return {
      errorStr: "",
      gettingLocation: false,
      location: null,
    };
  },
  created() {
    //do we support geolocation
    if (!("geolocation" in navigator)) {
      this.errorStr = "Geolocation is not available.";
      return;
    }
    this.gettingLocation = true;
    // get position
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.location = pos;

        this.gettingLocation = false;
      },
      (err) => {
        this.gettingLocation = false;
        console.log(err);
        this.location = {
          coords: {
            latitude: 59.05328,
            longitude: 10.03517,
          },
        };
      },
      { enableHighAccuracy: true }
    );
  },
};
</script>

<style scoped>
.menuCard {
  position: absolute;
  top: 10px;
  
  background-color: white;
}
</style>
