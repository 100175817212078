<template>
  <div>
    <b-col class="rightAlign">
      <button id="deleteBtn" @click="$emit('click', 'delete')">
        {{ $t("delete") }}
      </button>
      <button id="saveBtn" @click="$emit('click', 'save')">
        {{ $t("save") }}
      </button>
      <button id="cancelBtn" @click="$emit('click', null)">
        {{ $t("close") }}
      </button>
    </b-col>
  </div>
</template>

<script>
export default {};
</script>

<style>
.rightAlign {
  display: flex;
  justify-content: flex-end;
}
#saveBtn {
  margin: 5px;
  margin-top: 15px;
  background-color: #65bf95;
  box-shadow: 2px 2px 4px #b5b5b5;
  color: #000000;
  border: 0px;
  border-radius: 5px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
}
#saveBtn:hover {
  background-color: #7dddb0;
}
#cancelBtn {
  margin: 5px;
  margin-top: 15px;
  background-color: #b5b5b5;
  box-shadow: 2px 2px 4px #b5b5b5;
  color: #000000;
  border: 0px;
  border-radius: 5px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
}
#cancelBtn:hover {
  background-color: #c8c8c8;
}
#deleteBtn {
  margin: 5px;
  margin-top: 15px;
  background-color: #ff6a6a;
  box-shadow: 2px 2px 4px #b5b5b5;
  color: #000000;
  border: 0px;
  border-radius: 5px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
}
#deleteBtn:hover {
  background-color: #ff8585;
}
</style>