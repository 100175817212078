<template>
  <div class="widgetBase" id="widgetBase">
    <div v-if="title" class="title">{{ title }}</div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "WidgetBase",
  props: {
    title: {
      type: String,
      default: "title",
    },
  },
};
</script>

<style scoped>
.widgetBase {
  padding: 10px;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
}
.title {
  margin:0;
  font-size: 1vw;
}
</style>