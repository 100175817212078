<template>
  <div>
    <img :src="returnSource" alt="Statiskt Kart" />
  </div>
</template>

<script>
export default {
  props: {
    paths: Array,
  },
  computed: {
    returnSource() {
      
      let url = `https://maps.googleapis.com/maps/api/staticmap?size=600x400&maptype=satellite&path=color:0xff0000ff|weight:3`;
      let key = "&key=AIzaSyBcxJeZa_-_5WRx5vSW6ixb5yaA6bNAUSA";
      for (let path of this.paths) {
        let x = path.x;
        let y = path.y;
        url = url + `|${x},${y}`;
      }
      url = url + key;
      return url;
    },
  },
};
</script>

<style scoped>
</style>