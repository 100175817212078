<template>
  <b-container>
    <h1>404 page not found</h1>
  </b-container>
</template>

<script>
export default {
  name: "NotFound",
}
</script>
