<template>
  <b-container>
    <PageTitle title="Lag nytt oppdrag" />

    <b-row>
      <b-col md="12" lg="6">
        <h2>Fyll ut informasjon</h2>
        <AssignmentEdit />
      </b-col>

      <b-col>
        <b-row>
          <b-col>
            <h2>Velg containere</h2>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-input placeholder="Søk..." />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-select
              class="select"
              v-model="selectedOwner"
              :options="ownerOptions"
            />
          </b-col>

          <b-col>
            <b-select
              class="select"
              v-model="selectedType"
              :options="typeOptions"
            />
          </b-col>

          <b-col>
            <b-select
              class="select"
              v-model="selectedRode"
              :options="rodeOptions"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <SelectableList
              :labels="labels"
              :values="assignments"
              uniqueIdentifier="qr_code"
              :isCheckedFunc="(container) => doesIncludeContainer(container)"
              @itemChecked="containerChecked"
              @itemUnchecked="containerUnchecked"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import PageTitle from "../../components/common/PageTitle";
import AssignmentEdit from "../../components/fleetManagement/AssignmentEdit";
import SelectableList from "../../components/fleetManagement/SelectableList";

export default {
  components: {
    PageTitle,
    AssignmentEdit,
    SelectableList,
  },
  data() {
    return {
      labels: ["QR-kode", "Type", "Fyllegrad"],
      selectedAssignments: [],
      selectedOwner: null,
      selectedType: null,
      selectedRode: null,
      ownerOptions: [{ value: null, text: "Filtrér etter eier" }],
      typeOptions: [{ value: null, text: "Filtrér etter type" }],
      rodeOptions: [{ value: null, text: "Filtrér etter rode" }],
      assignments: [
        { qr_code: "ABCDEF", type: "Lift", rode: "80%" },
        { qr_code: "GHIJKL", type: "Lift", rode: "70%" },
        { qr_code: "MNOPQR", type: "Lift", rode: "50%" },
        { qr_code: "STUVWX", type: "Lift", rode: "20%" },
      ],
    };
  },
  methods: {
    ...mapMutations(["addContainer", "removeContainer"]),

    ...mapActions(["doesIncludeContainer"]),

    containerChecked(container) {
      this.addContainer(container);
      console.log("Checked:", container);
    },

    containerUnchecked(container) {
      this.removeContainer(container);
      console.log("Unchecked:", container);
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 1.5rem !important;
}
.select {
  margin-top: 15px;
}

input {
  margin-top: 5px;
}

.headlineStyle {
  margin-top: 40px !important;
  font-weight: bold;
  border: 0 !important;
  background-color: unset !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  cursor: default !important;
  margin-bottom: -15px !important;
}

.headlineStyle:hover {
  box-shadow: none !important;
}

.listStyle {
  margin-top: 25px;
  cursor: pointer;
  border: 1px solid #b5b5b5;
  background-color: white;
  border-radius: 0.25rem;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  width: 100%;
}

.listStyle:hover {
  box-shadow: 0px 4px #65bf95;
}

ul {
  padding-left: 0;
  width: 100%;
  margin-top: -20px;
  margin-left: 30px;
}

li {
  list-style-type: none;
}
</style>