<template>
  <b-modal :id="id" centered size="xl" hide-footer @hide="$emit('close')">
    <template #modal-header>
      <h1>{{ headline }}</h1>
      <ModalButtons
        @close="$bvModal.hide(id); $emit('close')"
        @save="$emit('save')"
        @delete="$emit('delete')"
      />
    </template>

    <slot />
  </b-modal>
</template>

<script>
import ModalButtons from "../buttons/ModalButtons";

export default {
  components: {
    ModalButtons,
  },

  props: {
    id: String,
    headline: String,
  },
};
</script>

<style>
h5 {
  margin-top: 20px !important;
}

.modal-header {
  border-bottom: 1px solid #65bf95 !important;
  margin: 0 2rem !important;
  padding: 1.5rem 0 !important;
}

.modal-body {
  margin: 0 1rem !important;
}

@media screen and (max-width: 577px) {
  .modal-header {
    flex-direction: column;
  }
}
</style>