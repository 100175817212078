import { getUser } from "../../services/reenapi";

const state = {
    user: {},
};

const actions = {
    async setUser({ commit }, id) {
        const response = await getUser(id);
        commit("user", response.data);
    },
};

const getters = {
    user: (state) => state.user,
};

const mutations = {
    user: (state, value) => state.user = value,
};

export default {
    state,
    getters,
    actions,
    mutations,
};