<template>
  <div class="container" id="container" v-if="userData">
    <Topbar
      v-on:addWidget="addWidget = $event"
      v-on:language="(userData.language = $event), saveUserSettings()"
    ></Topbar>
    <Sidebar></Sidebar>
    <AddWidgetModal
      class="addWidgetModal"
      v-if="addWidget"
      v-on:add="userData.widgets.push($event)"
      v-on:remove="removeWidget($event), saveUserSettings()"
      v-on:addWidget="(addWidget = $event), saveUserSettings()"
      :userData="userData"
    ></AddWidgetModal>
    <Widgets v-if="userData" :data="userData"></Widgets>
  </div>
</template>

<script>
import AddWidgetModal from "@/components/dashboard/dashboardComponents/AddWidget.vue";
import Topbar from "@/components/dashboard/dashboardComponents/DashboardTopbar";
import Sidebar from "@/components/dashboard/dashboardComponents/DashboardSidebar";
import Widgets from "@/components/dashboard/dashboardComponents/WidgetContainer";
import { getUserSettings, putUserSettings } from "@/services/reenapi.js";
import { mapGetters } from "vuex";

export default {
  components: {
    AddWidgetModal,
    Topbar,
    Sidebar,
    Widgets,
  },
  data() {
    return {
      addWidget: false,
      userData: null,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getData() {
      let response = await getUserSettings(this.user.email);
      this.userData = response.data;
      this.$i18n.locale = this.userData.language;
    },
    removeWidget(item) {
      const index = this.userData.widgets.indexOf(item);
      this.userData.widgets.splice(index, 1);
    },
    async saveUserSettings() {
      let data = this.userData;
      await putUserSettings(data.id, data);
    },
  },
  watch: {
    user() {
      this.getData();
    },
  },
  mounted() {},
};
</script>

<style lang="css" scoped>
.container {
  background-color: #e5e5e5;
  position: fixed;
  top: 90px;
  left: 230px;
  height: calc(100vh - 90px);
  min-width: calc(100vw - 230px);
  padding: 0;
  z-index: 9999;
  overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>