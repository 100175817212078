<template>
  <div class="assignment-edit">
    <b-input-group prepend="Oppdragsnavn">
      <b-input
        type="text"
        :value="$store.state.Assignments.assignmentInEdit.name"
        @input="setName"
      />
    </b-input-group>

    <b-input-group prepend="Type">
      <b-select
        :value="$store.state.Assignments.assignmentInEdit.type"
        @input="setType"
        :options="[
          { text: 'Slamtømming', value: '112425' },
          { text: 'Containertømming', value: '678453' },
        ]"
      />
    </b-input-group>

    <b-textarea
      placeholder="Beskrivelse"
      :value="$store.state.Assignments.assignmentInEdit.description"
      @input="setDescription"
    />

    <b-input-group prepend="Sjåfør">
      <b-select
        :value="$store.state.Assignments.assignmentInEdit.assignee"
        @input="setAssignee"
        :options="[
          { text: 'Rune Ruud', value: '112425' },
          { text: 'Lasse Lampeland', value: '678453' },
        ]"
      />
    </b-input-group>

    <SelectableList
      :labels="labels"
      :values="$store.state.Assignments.assignmentInEdit.containers"
      :isCheckedFunc="() => true"
      @itemUnchecked="uncheckContainer"
    />

    <div class="button-group">
      <b-button variant="success">Lagre</b-button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import SelectableList from "../fleetManagement/SelectableList";

export default {
  name: "AssignmentEdit",
  components: {
    SelectableList,
  },
  data() {
    return {
      labels: ["Navn", "Type", "Fyllegrad"],
      
      assignment: {
        area: {
          location: {},
        },
        task_type: {},
        containers: [],
        driver: {}, // *må* ikke settes nu
        vehicle: {}, // trengs ikke settes nu
      },
    };
  },
  methods: {
    ...mapMutations([
      "setName",
      "setType",
      "setArea",
      "setState",
      "setAssignee",
      "setDescription",
      "removeContainer",
    ]),

    uncheckContainer(container) {
      this.removeContainer(container);
    },
  },
};
</script>

<style scoped>
.assignment-edit {
  display: flex;
  flex-direction: column;
}

.assignment-edit > * {
  margin: 0.5rem 0;
  width: 100%;
}

.button-group {
  display: flex;
  justify-content: flex-end;
}
</style>