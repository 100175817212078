<template>
  <div>
    <b-select v-model="$i18n.locale" :options="langs"> </b-select>
  </div>
</template>

<script>
export default {
  name: "SelectLocale",
  data() {
    return {
      langs: [
        {
          value: "en",
          text: "🇬🇧 English",
        },
        {
          value: "no",
          text: "🇳🇴 Norsk",
        },
      ],
    };
  },
};
</script>