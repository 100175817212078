<template>
  <div class="spinnerContainer">
    <b-spinner class="spinner"></b-spinner>
  </div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped>
.spinnerContainer {
          position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
    color: #FFC83A;
}
.spinner {
    width: 50px;
    height: 50px;
}
</style>