"use strict";
/** @module reenapi */

import axios from "axios";
import https from "https";
import store from "../store/index";

const baseURL_staging = "https://reenapi-staging.azurewebsites.net/api";
const baseURL_production = "https://api.reen.cloud/api";

const IS_STAGING = false;

const baseURL = IS_STAGING ? baseURL_staging : baseURL_production;

/*
 * Area
 */

export async function getAreas(params) {
    return await apiRequest("GET", `areas${toQuery(params)}`);
}

export async function getAreasByRegion(region) {
    return await apiRequest("GET", `areas?region=${region}`)
}

export async function getArea(id) {
    return await apiRequest("GET", `areas/${id}`);
}

export async function postArea(area) {
    return await apiRequest("POST", "areas", area);
}

export async function putArea(area) {
    return await apiRequest("PUT", `areas/${area.id}`, area);
}

export async function deleteArea(id) {
    return await apiRequest("DELETE", `areas/${id}`);
}

/*
 * User
 */

export async function getUsers(params) {
    return await apiRequest("GET", `users${toQuery(params)}`);
}

/**
 * Gets a specific user.
 * 
 * @param {string} id User ID, either our GUID or Auth0's.
 * @returns {object} An object representing a user.
 */
export async function getUser(id) {
    if (!id) {
        throw new Error("id is null or undefined");
    }
    return await apiRequest("GET", `users/${id}`);
}

/**
 * Creates a new user.
 * @param {object} user
 */
export async function postUser(user) {
    return await apiRequest("POST", "users", user);
}

export async function putUser(user) {
    return await apiRequest("PUT", `users/${user.id}`, user);
}

export async function deleteUser(user_id) {
    return await apiRequest("DELETE", `users/${user_id}`);
}

/**
 * get user settings
 * @param {string} userId
 * @returns user settings object
 */
export async function getUserSettings(userId) {
    return await apiRequest('GET', `usersettings/${userId}`)
}

/**
 * set user settings
 * @param {string} userId email, usersetting id, auth0 id or user ID
 * @param {object} userSettings object with the new settings
 * @returns status
 */
export async function putUserSettings(userId, userSettings) {
    return await apiRequest('PUT', `usersettings/${userId}`, userSettings)
}




/*
 * Container
 */

export async function getContainers(params) {
    return await apiRequest("GET", `equipment/containers${toQuery(params)}`);
}

export async function getContainer(id) {
    return await apiRequest("GET", `equipment/containers/${id}`);
}

export async function GetContainerAmount(params) {
    return await apiRequest("GET", `equipment/containers/count${toQuery(params)}`);
}

export async function getAllContainers() {
    let page = 1;
    let data = [];
    let length;

    try {
        do {
            let response = await apiRequest("GET", `equipment/containers?page=${page}`)
            page++;
            data = data.concat(response.data);
            length = response.data.length;
        } while (length == 500);
        return data;
    } catch (e) {
        console.log(e)
    }
}
export async function getContainerStats() {
    try {
        let response = await apiRequest("GET", `equipment/containers/stats`)
        return response;
    } catch (e) {
        console.log(e)
    }
}

export async function getAllContainersByState() {
    let page = 1;
    let length;
    let data = {
        a: [],
        b: [],
        c: []
    }
    try {
        for (let state of Object.keys(data)) {
            do {
                let response = await apiRequest("GET", `equipment/containers?state=${state}&page=${page}`)
                console.log(response)
                page++;
                data[state] = data[state].concat(response.data);
                length = response.data.length;
            }
            while (length == 500);
            page = 1
        }
        return data;
    } catch (e) {
        console.log(e)
    }
}

export async function GetLostContainers(params) {
    try {
        let response = await apiRequest("GET", `equipment/containers/lost_contact${toQuery(params)}`)
        return response;
    } catch (e) {
        console.log(e)
    }
}

export async function getContainersWithinRadius(bounds, radius) {
    let page = 1;
    let data = [];
    let length;

    try {
        do {
            let response = await apiRequest("GET", `equipment/containers/container_positions?within_radius={latitude:${bounds.lat},longitude:${bounds.lng},radius:${radius}}&page=${page}`);
            page++;
            data = data.concat(response.data);
            length = response.data.length;
        } while (length == 500);

        return data;
    } catch (e) {
        console.log(e)
    }
}

export async function getContainersWithinArea(coordinates) {
    let page = 1;
    let data = [];
    let length;
    let params = `within_area={coordinates:[`

    for (let coord of coordinates) {
        let addString = `{x:${coord.x},y:${coord.y}},`
        params += addString
    }
    params += `]}`;
    do {
        let response = await apiRequest("GET", `equipment/containers?${params}&page=${page}`);
        page++;
        console.log(page)
        data = data.concat(response.data);
        length = response.data.length;
    } while (length == 500);
    return data;
}

export async function getContainersWithinSquare(item) {
    var data = [];
    var page = 1;
    var length;

    try {
        for (let coords of item.locations) {
            do {
                let response = await apiRequest("GET", `equipment/containers?within_square={latitude1:${coords[0].lat},longitude1:${coords[0].lng}, latitude2:${coords[2].lat},longitude2:${coords[2].lng},latitude3:${coords[1].lat},longitude3:${coords[1].lng}, latitude4:${coords[3].lat},longitude4:${coords[3].lng}}&page=${page}`);
                data = data.concat(response.data);
                length = response.data.length;
                if (length == 500) page++;
            } while (length == 500);

            return data;
        }
    } catch (e) {
        console.log(e);
    }
}

export async function putContainer(container) {
    return await apiRequest("PUT", `equipment/containers/${container.id}`, container);
}

export async function deleteContainer(container_id) {
    return await apiRequest("DELETE", `equipment/containers/${container_id}`);
}

/*
 * Vehicle
 */

export async function getVehicles(params) {
    return await apiRequest("GET", `vehicles${toQuery(params)}`);
}

export async function getVehicle(id) {
    return await apiRequest("GET", `vehicles/${id}`);
}

export async function getAllVehicles() {
    let page = 1;
    let data = [];
    let length;

    try {
        do {
            let response = await apiRequest("GET", `vehicles?page=${page}`)
            page++;
            data = data.concat(response.data);
            length = response.data.length;
        } while (length == 500);
        return data;
    } catch (e) {
        console.log(e)
    }
}

export async function getVehicleAmount() {
    return await apiRequest("GET", `equipment/vehicles/count`);
}

export async function getVehiclesWithinRadius(bounds, radius) {
    return (await getVehicles({
        within_radius: `{latitude:${bounds.lat},longitude:${bounds.lng},radius:${radius}}`
    })).data;
}


/*
 * Unit
 */

export async function getUnits(params) {
    return await apiRequest("GET", `iot${toQuery(params)}`);
}

export async function getUnitDistances(unit_id) {
    return await apiRequest("GET", `iot/${unit_id}`);
}


/*
 * Diverse
 */

export async function getOrganizations(params) {
    return await apiRequest("GET", `organizations${toQuery(params)}`);
}

export async function getRegions(organization_id, params) {
    if (!organization_id) throw new Error("organization_id is null or undefined")
    return await apiRequest("GET", `organizations/${organization_id}/regions${toQuery(params)}`);
}

export async function getAreaTypes(params) {
    return await apiRequest("GET", `areas/areatype${toQuery(params)}`);
}

export async function getRoles(user_id) {
    return await apiRequest("GET", `tenants/users/${user_id}/roles`);
}

export async function getContainerStates() {
    return await apiRequest("GET", "equipment/states");
}

/*
 * ContainerType
 */

export async function getContainerTypes(params) {
    return await apiRequest("GET", `equipment/containers/ContainerTypes/container_type${toQuery(params)}`);
}

/**
 * 
 * @param {string} method HTTP method to use (GET, POST, PUT, etc.)
 * @param {string} endpoint API endpoint.
 * @param {object} payload data to send with request.
 * @returns the response from axios.
 */
async function apiRequest(method, endpoint, payload) {
    if (store.getters.getToken === null) {
        await store.dispatch("fetchToken");
    }
    let request = {
        httpsAgent: new https.Agent({
            rejectUnauthorized: false
        }),
        headers: {
            Authorization: `Bearer ${store.getters.getToken}`,
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
        },
        method,
        baseURL,
        url: endpoint,
        data: payload,
    };

    return await axios(request).catch((error) => {
        throw new Error(error);
    });
}

/**
 * Transforms an object `params` to a HTTP query string on the form
 *   `?{key1}={value1}&{key2}={value2}&...`
 * @param {object} params
 */
function toQuery(params) {
    if (params === undefined) return "";

    let values = [];
    for (let key in params) {
        values.push(`${key}=${params[key]}`);
    }
    return `?${values.join("&")}`;
}