<template>
  <div v-if="show">
    <b-container class="tableContainer" fluid>
      <!-- User Interface controls -->
      <b-row>
        <b-col lg="6" class="my-1 filterContainer">
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Angi et søk..."
            ></b-form-input>
          </b-input-group>
        </b-col>
        <div class="exitCross" @click="setShowList">
          <Icon :data="getCurrentTheme.exitCross.icon"></Icon>
        </div>
      </b-row>
      <!-- Main table element -->
      <b-row>
        <b-table
          ref="inventoryTable"
          selectable
          striped
          small
          stacked="md"
          hover
          :filter="filter"
          :fields="fields"
          :items="allTableEntries"
          class="inventoryTable"
          caption-top
          select-mode="single"
          @row-selected="showDetails"
        >
        </b-table>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Icon from "../icons/Icons.vue";

export default {
  components: {
    Icon,
  },
  props: ["tableData"],
  data: function () {
    return {
      filter: "",
      show: true,
      inventoryData: [],
      fields: [
        {
          key: "hentesMed",
          sortable: true,
        },
        {
          key: "utstyrstype",
          sortable: true,
        },
        {
          key: "beskrivelse",
          sortable: false,
        },
        {
          key: "tilstandFin",
          sortable: false,
        },
        {
          key: "tilstandOk",
          sortable: false,
        },
        {
          key: "tilstandStygg",
          sortable: false,
        },
        {
          key: "tilstandSkadet",
          sortable: false,
        },
        {
          key: "totalt",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    ...mapActions([
      "setCurrentInventory",
      "getCurrentInventory",
      "setShowList",
    ]),
    log(arg) {
      console.log(arg);
    },
    showDetails(items) {
      console.log(items[0].completeStats);
    },
  },
  computed: {
    ...mapGetters(["getCurrentTheme"]),
    allTableEntries() {
      let allContainerStats = [];
      try {
        var finalEntry = {
          hentesMed: "",
          utstyrstype: "",
          beskrivelse: "",
          tilstandFin: 0,
          tilstandOk: 0,
          tilstandStygg: 0,
          tilstandSkadet: 0,
          totalt: 0,
        };
        for (let container of this.tableData) {
          if (container.container_type) {
            let stats = allContainerStats.find(
              (cd) => cd.utstyrstype === container.container_type.name
            );
            finalEntry.totalt++;
            if (!stats) {
              stats = {
                hentesMed: container.container_type.mount_type,
                utstyrstype: container.container_type.name,
                beskrivelse: container.container_type.description,
                tilstandFin: 0,
                tilstandOk: 0,
                tilstandStygg: 0,
                tilstandSkadet: 0,
                totalt: 0,
                // This is called on when a row is clicked.
                completeStats: container,
              };
              allContainerStats.push(stats);
            }
            stats.totalt++;
            let state = container.state.slice(0, 1);
            if (state == "A") {
              stats.tilstandFin++;
              finalEntry.tilstandFin++;
            } else if (state == "B") {
              finalEntry.tilstandOk++;
              stats.tilstandOk++;
            } else if (state == "C") {
              finalEntry.tilstandStygg++;
              stats.tilstandStygg++;
            } else {
              finalEntry.tilstandStygg++;
              stats.tilstandSkadet++;
            }
          }
        }
        allContainerStats.push(finalEntry);
        return allContainerStats;
      } catch {
        return null;
      }
    },
  },
  updated() {},
};
</script>

<style scoped>
#filterInput {
  border-radius: 15px;
  background-color: rgb(166, 203, 231);
  color: black;
}
.inventoryTable {
  margin: auto;
  margin-top: 25px;
  max-width: 90vw;
  cursor: pointer;
}
.tableContainer {
  position: fixed;
  top: 60px;
  left: 0px;
  width: 100vw;
  background-color: white;
  box-shadow: 4px 13px 30px 1px rgba(65, 68, 70, 0.2);
  overflow: scroll;
  z-index: 3000;
}
.exitCross {
  cursor: pointer;
  position: absolute;
  right: 1%;
  top: 0.2%;
}
.filterContainer {
  width: 70%;
  padding-top: 2%;
}
@media (min-width: 768px) {
  .tableContainer {
    position: fixed;
    top: 70px;
    right: 10px;
    left: unset;
    z-index: 3000;
    border-radius: 25px;
    padding: 25px;
    padding-top: 0px;
    width: 70vw;
    overflow-y: scroll;
    max-height: 60vh;
    border-radius: 25px;
  }
  .exitCross {
    right: 2%;
    top: 2%;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tableContainer::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.tableContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>