<template>
  <ModuleBase class="distance-module">
    <header>
      <h2>Sensormålinger</h2>
    </header>
    <LineChart class="chart" v-if="chartDataLoaded" :chartdata="chartData" />
    <b-button>SE MER</b-button>
  </ModuleBase>
</template>

<script>
import { getUnitDistances } from "../../../services/reenapi";

import ModuleBase from "./ModuleBase";
import LineChart from "../LineChart";

export default {
  name: "DistanceModule",
  components: {
    ModuleBase,
    LineChart,
  },
  props: {
    options: {
      type: Object,
      required: true,
      validator: (value) => {
        console.log(typeof value.unitId);
        return typeof value.unitId === "string";
      },
    },
  },
  data() {
    return {
      distances: [
        {
          _id: "98a238f9-d1c0-495c-ab51-485300a775f5",
          DeviceId: "89470060190430751169",
          timestamp: "12/02/2021 08:24",
          value: 510,
        },
        {
          _id: "d9b69fa8-14fa-4bfb-b6e6-0499f4749b67",
          DeviceId: "89470060190430751169",
          timestamp: "12/02/2021 08:24",
          value: 450,
        },
        {
          _id: "b207e4ec-facb-4c48-9cde-30ae463e942a",
          DeviceId: "89470060190430751169",
          timestamp: "12/02/2021 08:24",
          value: 450,
        },
        {
          _id: "33264704-4761-4510-bd76-b24391991977",
          DeviceId: "89470060190430751169",
          timestamp: "12/02/2021 08:24",
          value: 200,
        },
        {
          _id: "b6f8da07-7124-4981-ae7e-0a64fc3e1567",
          DeviceId: "89470060190430751169",
          timestamp: "12/02/2021 08:27",
          value: 210,
        },
        {
          _id: "907e8409-bcd0-4bfd-8089-0a60e7bb1876",
          DeviceId: "89470060190430751169",
          timestamp: "12/02/2021 08:33",
          value: 320,
        },
        {
          _id: "ab9ebd5f-14d5-4837-9b8c-7b6e19826190",
          DeviceId: "89470060190430751169",
          timestamp: "12/02/2021 08:34",
          value: 200,
        },
      ],
      chartData: {},
      chartDataLoaded: false,
    };
  },
  computed: {
    chartLabels() {
      return this.distances.map((distance) => distance.timestamp.split(" ")[0]);
    },

    chartDataPoints() {
      return this.distances.map((distance) => distance.value);
    },
  },
  methods: {
    setChartData() {
      this.chartData = {
        labels: this.chartLabels,
        datasets: [
          {
            label: "mm",
            backgroundColor: "#7B0051",
            data: this.chartDataPoints,
          },
        ],
      };
      this.chartDataLoaded = true;
    },
  },
  created() {
    // this.setChartData();
  },
  async mounted() {
    let response = await getUnitDistances(this.options.unitId);
    this.distances = response.data;
    this.setChartData();
  },
};
</script>

<style scoped>
.chart {
  height: 300px !important;
  width: 300px !important;
  margin: 0 auto;
}
</style>