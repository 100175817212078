<template>
<div>
  <span class="hover">
    <div class="buttonContainer" @click="routeTo(destination), clicked = !clicked">
    <b-icon class="child icon " :icon="icon"></b-icon>
    <div class="child text">{{ text }}</div>
  </div>
  </span>
    <div v-if="dropdown && clicked" class="dropdown">
      <div class="dropdownItem" v-for="(item, index) in dropdown" :key="index" @click="routeTo(item.to)">{{item.name}}</div>
    </div>
</div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
    },
    text: {
      type: String,
    },
    destination: {
      type: String,
    },
    dropdown: {
      type: Array
    }
  },
  data() {
    return {
      clicked: false
    }
  },
  methods: {
    routeTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
.hover :hover {
    background-color: #7e5dff;
}
.buttonContainer {
    white-space: nowrap;
    margin: 17px;
    width: 196px;
    border-radius: 8px;
    text-align: center;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    position: relative;
}
.dropdown {
  font-weight: 100;
  text-align: left;
  position: absolute;
  left: 50px;
  width: 165px;
}
.dropdown :hover {
    background-color: #7e5dff;
}
.dropdownItem {
  padding: 10px;
  border-radius: 8px;
  margin: 5px;
}
.child {
    display: inline-block;
}
.icon {
    margin-right: 10px;
    height: 25px;
    width: 25px;
}
</style>