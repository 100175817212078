const state = {
    errors: [

    ]
};

const getters = {
    getErrors: (state) => state.errors
};

const actions = {
    addError({
        commit
    }, variable) {
        const response = variable;
        commit("mutateErrors", response)
        console.log(state.errors)
    },
    removeError({
        commit
    }, variable) {
        const response = variable
        commit("removeError", response)
    }
};

const mutations = {
    mutateErrors: (state, errors) => (state.errors.push(errors)),
    removeError: (state, errors) => (state.errors.splice(errors, 1))

};

export default {
    state,
    getters,
    actions,
    mutations,
};