<template>
  <div class="sidebarContainer">
    <div class="topBar">
      <img :src="logo" alt="" class="logo" />
    </div>
    <div class="middleBar">
      <NavigationButton
        v-for="(n, index) in navigationButtons"
        :key="index"
        :icon="n.icon"
        :text="n.text"
        :destination="n.to"
        :dropdown="n.dropdown"
      ></NavigationButton>
    </div>
    <div class="bottomBar">
      <b-icon-person-circle class="userIcon"></b-icon-person-circle>
      <div class="userText">{{ $auth.user.name }}</div>
    </div>
  </div>
</template>

<script>
import Logo from "@/assets/images/logo/ReenLogoNegative.svg";
import NavigationButton from "./sidebarComponents/NavigationButton.vue";

export default {
  components: {
    NavigationButton,
  },
  data() {
    return {
      logo: Logo,
      navigationButtons: [
        {
          icon: "grid-1x2-fill",
          text: "Dashboard",
          dropdown: null,
        },
        // {
        //   icon: "list-check",
        //   text: "Assignments",
        //   dropdown: null,
        // },
        {
          icon: "map",
          text: this.$t("map"),
          to: "/",
          dropdown: null,
        },
        {
          icon: "sliders",
          text: this.$t("fleetManagement"),
          dropdown: [
            {
              name: this.$t("areas"),
              to: "/fleetmanagement/area",
            },
            {
              name: this.$t("containers"),
              to: "/fleetmanagement/containers",
            },
            {
              name: this.$t('container_types'),
              to: "fleetmanagement/containers/types"
            },
            // {
            //   name: this.$t("vehicles"),
            //   to: "/fleetmanagement/vehicle",
            // },
            // {
            //   name: this.$t("users"),
            //   to: "/fleetmanagement/users",
            // },
            {
              name: this.$t("units"),
              to: "/fleetmanagement/devices",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="css" scoped>
.sidebarContainer {
  width: 230px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #190833;
  z-index: 9999;
}
.topBar {
  position: absolute;
  top: 0px;
  left: 0px;
  text-align: left;
  width: 100%;
  padding: 25px;
  border-bottom: 1px solid rgb(78, 78, 78);
}
.logo {
  width: 130px;
}
.middleBar {
  position: absolute;
  top: 100px;
  color: white;
}
.bottomBar {
  border-top: 1px solid rgb(78, 78, 78);
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  text-align: center;
  color: white;
  padding: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.userIcon {
  width: 33px;
  height: 33px;
  order: 0;
  margin: 5px;
}
.userText {
  order: 1;
  margin: 5px;
}
</style>