<template>
  <div>
    <select
      required
      name="regions"
      class="selectBox"
      v-model="selectedRegion"
      @change="setCurrentRegion(selectedRegion)"
    >
      <option value="Select region" selected hidden>
        {{ $t("dashboard.selectRegion") }}
      </option>
      <option value="All" class="option">{{ $t("dashboard.all") }}</option>
      <option
        v-for="(region, index) in regions"
        :key="index"
        :value="region.name"
        class="option"
      >
        {{ region.name }}
      </option>
    </select>
  </div>
</template>

<script>
import { getRegions, getOrganizations } from "@/services/reenapi.js";
import { mapActions } from "vuex";

export default {
  name: "SelectRegion",
  data() {
    return {
      selectedRegion: "Select region",
      regions: [],
    };
  },
  methods: {
    ...mapActions(["setCurrentRegion"]),
  },
  async mounted() {
    let organization = await getOrganizations();
    let data = [];
    for (let org of organization.data) {
      let orgData = await getRegions(org.id);
      for (let orgRegion of orgData.data) data.push(orgRegion);
    }
    // let data = await getRegions(organization.data[0].id);
    this.regions = data;
  },
};
</script>

<style scoped>
.selectBox {
  color: white;
  background-color: #261440;
  height: 48px;
  width: 150px;
  border: 1px solid white;
  border-radius: 5px;
  padding: 8px 16px;
  font-weight: 100;
  font-size: 0.9rem;
}
.option {
  font-weight: 100;
}
</style>