<script>
import { Line } from "vue-chartjs";

export default {
  name: "LineChart",
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  },
  extends: Line,
  props: {
    chartdata: Object,
  },
  mounted() {
    this.renderChart(this.chartdata, this.options);
    console.log(this.chartdata);
  },
};
</script>
