<template>
  <WidgetBase :title="$t(item.title)" class="base">
    <Spinner v-if="loading"> </Spinner>
    <div v-else class="bottom">
      <div class="amount">{{ amount }}</div>
      <span @click="$router.push('fleetmanagement/containers')">
        <!-- <ArrowButton class="button"></ArrowButton> -->
      </span>
    </div>
  </WidgetBase>
</template>

<script>
import WidgetBase from "./widgetComponents/WidgetBase.vue";
// import ArrowButton from "./widgetComponents/ArrowButton.vue";
import {
  getAreasByRegion,
  getContainersWithinArea,
  getAreas,
} from "@/services/reenapi.js";
import Spinner from "./widgetComponents/Spinner";
import { mapGetters } from "vuex";
export default {
  name: "ContainerStock",
  components: {
    WidgetBase,
    Spinner,
    // ArrowButton,
  },
  props: {
    options: {
      type: Object,
      required: false,
    },
    item: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["getCurrentRegion"]),
  },
  watch: {},
  data() {
    return {
      amount: null,
      loading: false,
    };
  },
  
  methods: {
    async getAreas() {
      this.loading = true;
      let areasInRegion;
      let amount = 0;
      areasInRegion = await getAreas();
      for (let area of areasInRegion.data) {
        area.containers = await getContainersWithinArea(area.coordinates);
        amount = amount + area.containers.length;
      }
      this.amount = amount;
      this.loading = false;
    },
    returnObjectCoordinates(data) {
      // API returns X = lng, Y = lat, this needs to be inverted to interact with google maps functions.
      try {
        let coordinates = [];
        for (let value of data) {
          let coord = {
            y: value.x,
            x: value.y,
          };

          coordinates.push(coord);
        }
        return coordinates;
      } catch (e) {
        console.log(e);
      }
    },
    async getContainersWithinArea(coordinates) {
      coordinates = this.returnObjectCoordinates(coordinates);

      for (let coord of coordinates) {
        let x = parseFloat(coord.y).toFixed(6);
        let y = parseFloat(coord.x).toFixed(6);

        coord.x = x;
        coord.y = y;
      }

      let response = await getContainersWithinArea(coordinates);
      return response.data;
    },
  },
  mounted() {
    this.getAreas();
  },
};
</script>

<style scoped>
.base {
  position: relative;
}
.button {
  float: right;
  margin: 8px 0px 8px 8px;
}
.amount {
  float: left;
  font-size: 5vh;
  position: absolute;
  bottom: 0;
}
</style>
