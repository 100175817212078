import {
  colors
} from "./colors";
import * as Icon from "../icons/icons";
import LogoPos from "../../assets/images/logo/ReenLogoPositiv.png";
import LogoNeg from "../../assets/images/logo/REEN-logo-negative-trans.png";

export const darkTheme = {
  colors,
  login: {
    style: {
      color: colors.white,
      backgroundColor: colors.black,
    },
    icon: Icon.Profile("white", 25, {}),
  },
  filter: {
    icon: Icon.Filter(colors.greyMediumLight, 25, {})
  },
  search: {
    icon: Icon.Search("white", 16, {}),
    style: {
      color: colors.white,
      backgroundColor: "rgb(53, 53, 53)"
    }
  },
  themeButton: {
    icon: Icon.Sun("white", 30, {}),
    style: {
      backgroundColor: "black",
    },
  },
  themeSwitch: {
    icon: Icon.Moon("white", 20, {}),
    style: {
      backgroundColor: colors.greyDark
    }
  },
  logo: LogoNeg,
  menubox: {
    style: {
      color: colors.white,
      backgroundColor: colors.greyDark,
    },
  },
  terrainButton: {
    icon: Icon.Cube("white", 40, {}),
    style: {
      backgroundColor: "black"
    }
  },
  containerCrane: {
    icon: Icon.ContainerInCrane("white", 60, {}),
    style: {
      backgroundColor: "black"
    }
  },
  carIcon: {
    icon: Icon.Truck("white", 60, {}),
    style: {
      backgroundColor: "black"
    }
  },

  exitCross: {
    icon: Icon.Close("white", 35, {}),
    style: {
      backgroundColor: "black"
    }
  },
  navbar: {
    type: "dark",
    style: {
      backgroundColor: colors.greyDark,
    }
  },

};

export const lightTheme = {
  colors,
  navbar: {
    type: "light",
    style: {
      backgroundColor: "white",
    },
    terrainDropdown: {}
  },
  login: {
    backgroundColor: colors.greyXLight,
    color: colors.black,
    icon: Icon.Profile("black", 25, {}),
  },
  filter: {
    icon: Icon.Filter("white", 25, {})
  },
  search: {
    icon: Icon.Search("black", 16, {}),
    style: {
      backgroundColor: "rgb(219, 234, 245)"
    }
  },
  caret: {
    icon: Icon.Caret("black", 25, {})
  },
  themeButton: {
    icon: Icon.Moon("black", 25, {}),
    style: {
      backgroundColor: "white",
    },
  },
  themeSwitch: {
    icon: Icon.Sun("black", 25, {}),
    style: {
      backgroundColor: "white",
    },
  },
  logo: LogoPos,
  customMarker: {
    style: {
      backgroundColor: "#005458",
      borderRadius: "50%"
    },
    icon: Icon.Truck("white", 30, {})
  },
  menubox: {
    style: {
      color: colors.white,
      backgroundColor: colors.black,
    }
  },
  terrainButton: {
    icon: Icon.Cube("black", 40, {}),
    style: {
      backgroundColor: "white"
    }
  },
  containerCrane: {
    icon: Icon.ContainerInCrane("black", 60, {}),
    style: {
      backgroundColor: "white"
    }
  },
  carIcon: {
    icon: Icon.Truck("black", 60, {}),
    style: {
      backgroundColor: "white"
    }
  },
  exitCross: {
    icon: Icon.Close("black", 35, {}),
    style: {
      backgroundColor: "white"
    }
  },
  exitCrossGreen: {
    icon: Icon.Close("#65bf95", 30, {}),
    style: {
      backgroundColor: "black"
    }
  },
  exitCrossWhite: {
    icon: Icon.Close("white", 30, {}),
    style: {
      backgroundColor: "black"
    }
  },

  addIcon: {
    icon: Icon.Close("#65bf95", 80, {}),
    style: {
      backgroundColor: "#65bf95"
    }
  },
  closeWindow: Icon.Close("black", 25, {})
};


export const theme = {
  colors,
  navbar: {
    backgroundColor: colors.reenPurple,
    color: colors.reenWhite,
  },
  mapButtons: {
    backgroundColor: colors.reenWhite,
    color: colors.reenBlack,
  },
  









}