<template>
  <div>
    <GoogleMap v-if="location" :currentLocation="location"></GoogleMap>
    <!-- <HereMap></HereMap> -->
  </div>
</template>

<script>
import GoogleMap from "./googleMap/GoogleMap.vue";
// import HereMap from "./hereMap/HereMap.vue"
export default {
  components: {
    GoogleMap,
    // HereMap
  },
  data() {
    return {
      errorStr: "",
      gettingLocation: false,
      location: null,
    };
  },
  created() {
    //do we support geolocation
    if (!("geolocation" in navigator)) {
      this.errorStr = "Geolocation is not available.";
      return;
    }
    this.gettingLocation = true;
    // get position
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.location = pos;

        this.gettingLocation = false;
      },
      (err) => {
        this.gettingLocation = false;
        console.log(err);
        this.location = {
          coords: {
            latitude: 59.05328,
            longitude: 10.03517,
          },
        };
      },
      { enableHighAccuracy: true }
    );
  },
};
</script>

<style scoped>
</style>