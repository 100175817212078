<template>
    <div>
        <Dashboard></Dashboard>
    </div>
</template>

<script>
import Dashboard from "@/components/dashboard/Dashboard";

    export default {
        components: {
            Dashboard,
        },
    }
</script>

<style lang="css" scoped>

</style>