import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import i18n from './i18n'

// Authorization
import { domain, clientId, audience, } from "../auth_config.json";
import { Auth0Plugin } from "./auth/index.js";
Vue.use(
  Auth0Plugin, {
  axios,
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl ?
        appState.targetUrl :
        window.location.pathname
    );
  },
},
);

// Google Maps
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from 'vue2-google-maps/src/components/cluster';
Vue.component('GmapCluster', GmapCluster);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBcxJeZa_-_5WRx5vSW6ixb5yaA6bNAUSA",
    libraries: "places, geolocation, direction", // necessary for places input
  }
});

// Bootstrap and animations
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueAnimated from '@codekraft-studio/vue-animated';
Vue.use(VueAnimated);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// Lokale komponenter, importert globalt.
import Button from "./components/common/Button";
Vue.component("Button", Button);
Vue.use(VueGoogleCharts)

// Components for dashboard
import VueGoogleCharts from 'vue-google-charts'

// Vue configuration.
Vue.config.productionTip = false;

// 
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");