<template>
  <div>
    <div class="areaInformationContainer" v-if="item">
      <div class="header"><h3>Område</h3></div>
      <div class="body">
        <div
          class="item"
          v-for="(item, index) in returnInformationObject"
          :key="index"
        >
          <strong class="title">{{ item.key }}</strong>
          <span class="value">{{ item.value }}</span>
        </div>
      </div>
      <!-- <div class="buttonContainer">
        <b-button class="button" @click="deleteArea(item.id)">Slett</b-button>
        <b-button class="button" @click="updateArea(item.id)">Endre</b-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { putArea, deleteArea } from "../../../../../services/reenapi";

export default {
  props: ["item"],
  computed: {
    returnInformationObject() {
      let data = [
        { key: "Name:", value: this.item.name },
        { key: "Address:", value: this.item.address },
        { key: "Region:", value: `${this.item.region.plant_number} ${this.item.region.name}` },
        { key: "Area Type:", value: this.item.area_type },
      ];

      return data;
    },
  },

  methods: {
    updateArea(id) {
      putArea(id); // TODO: wtf
      location.reload();
    },

    async deleteArea(id) {
      await deleteArea(id); // TODO: wtf
      location.reload();
    },
  },
};
</script>

<style scoped>
.areaInformationContainer {
  background-color: white;
  width: 400px;
  border-radius: 25px;
  padding: 25px;
  box-shadow: 0px 0px 5px 1px black;
}
.header {
  border-bottom: 1px solid #65bf95;
}
.buttonContainer {
  text-align: center;
}
.button {
  margin: 5px 10px;
}
.value {
  float: right;
}
</style>