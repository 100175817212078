<template>
  <div>
    <img :src="returnSource" alt="Statiskt Kart" />
  </div>
</template>

<script>
export default {
  props: {
    location: Object,
  },
  computed: {
    returnSource() {
      
      let url = `https://maps.googleapis.com/maps/api/staticmap?maptype=satellite&center=${this.location.lat},${this.location.lng}&zoom=19&size=600x400&markers=color:red%7C%7C${this.location.lat},${this.location.lng}&key=AIzaSyBcxJeZa_-_5WRx5vSW6ixb5yaA6bNAUSA`;
      console.log(url)
      let key = "&key=AIzaSyBcxJeZa_-_5WRx5vSW6ixb5yaA6bNAUSA";
      url = url + key;
      return url;
    },
  },
};
</script>

<style scoped>
</style>