import clone from "just-clone";

/**
 * Sorterer arrayen `arr` uten å endre på kilde-arrayen
 * (du får tilbake en kopi).
 */
export function sortByKey(arr, key, asc = true) {
    if (key === undefined) {
        return arr;
    }

    return clone(arr).sort((a, b) => {
        let valueA = a[key] && a[key].toLowerCase ? a[key].toLowerCase() : a[key];
        let valueB = b[key] && b[key].toLowerCase ? b[key].toLowerCase() : b[key];

        if (asc) {
            return valueA > valueB;
        }
        return valueA < valueB;
    });
}

/**
 * Finds and returns the organization_id from an arbitrary object,
 * eg. a Container or Area.
 *
 * @param {object} obj the object to extract the organization id from
 * @returns {string}
 */
export function extractOrganizationId(obj) {
    if (obj.organization_id) {
        return obj.organization_id;
    }
    else if (obj.organization && obj.organization.id) {
        return obj.organization.id;
    }
    else if (obj.region && obj.region.organization_id) {
        return obj.region.organization_id;
    }
}

/**
 * Calculates the fill level in percentage for a container.
 *
 * @param container
 * @return {string|number}
 */
export function getFillLevel(container) {
    if (!container.fill_depth) return "Ukjent";

    let heightInMm = container.container_type.height * 1000;
    let sensorValue = container.fill_depth.value;
    let remainingPercentage = 100 - (sensorValue / heightInMm) * 100;
    if (remainingPercentage < 0) return 0;
    return Math.round(remainingPercentage);
}

export function userIsSuperadmin(user) {
    return !!(user
        && user.app_metadata
        && user.app_metadata.roles
        && user.app_metadata.roles.includes("superadmin"));
}

export function userHasTenant(user, tenantId) {
    return !!(user
        && user.app_metadata
        && user.app_metadata.tenant_id
        && user.app_metadata.tenant_id.toLowerCase() === tenantId.toLowerCase());
}