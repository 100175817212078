<template>
  <div class="checkedBox">
    <b-container>
      <div class="row-element">
        <b-col>
          <b-row class="text-center">
            <b-col>
              <div class="buttonContainer">
                <button>Scan Container</button>
              </div>
            </b-col>
          </b-row>
          <b-row class="text-center">
            <b-col>
              <div class="buttonContainer" id="manualBtn">
                <button @click="$emit('change', 'UnloadContainer')">
                  Manuell tømming
                </button>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "ContainerUnloaded",
};
</script>

<style scoped>
.checkedBox {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
.container {
  background-color: #f7f8f8;
  border-radius: 10px;
  color: #65bf95;
  position: absolute;
  z-index: 999;
  overflow: auto;
  width: 80%;
  height: 70%;
  top: 15%;
}
.row-element {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  height: 100%;
  margin-top: -25px;
}
#manualBtn {
  margin-top: 50px;
}
button {
  margin: 5px;
  background-color: #65bf95;
  box-shadow: 2px 2px 4px #b5b5b5;
  color: #000000;
  border: 0px;
  border-radius: 10px;
  padding: 5px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 600;
  /* letter-spacing: 1px; */
  /* font-size: 1.125em; */
  /* text-shadow: 0px 0px 4px #000000a6; */
}
.buttonContainer :hover {
  background-color: #7dddb0;
}
</style>