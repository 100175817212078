<template>
  <div>
    <div v-if="areaSaved == false" class="createAreaInfoBox">
      <div>Trykk på kartet for å lage området.</div>
      <button @click="(areaSaved = true), setShowDraw()">Ferdig</button>
    </div>

    <b-form class="container" id="formContainer" v-if="areaSaved">
      <div class="header">
        <h3>Lag nytt Område</h3>
        <b-icon-x-circle
          class="exitCross h3"
          @click="$emit('abort')"
        ></b-icon-x-circle>
      </div>
      <div class="body">
        <b-form-group
          id="input-group-1"
          label="Navn på Området:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="name"
            type="text"
            placeholder="Navn"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="Adressen til Området:"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="address"
            type="text"
            placeholder="Adresse"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-5"
          label="Organisasjon"
          label-for="input-5"
        >
          <b-form-select
            id="input-5"
            type="text"
            :options="organizations"
            :value="organization_id"
            @change="changeOrganization"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group
          id="input-group-3"
          label="Regionen området tilhører"
          label-for="input-3"
        >
          <b-form-select
            id="input-3"
            type="text"
            :options="
              regions.length === 0
                ? [{ text: this.$t('noRegion'), value: null }]
                : regions
            "
            :value="regionValue"
            @change="region_id = $event"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group id="input-group-4" label="Fabrikk:" label-for="input-4">
          <b-form-select
            id="input-4"
            v-model="factory"
            type="text"
            :options="areaType"
            required
          ></b-form-select>
        </b-form-group>
      </div>
      <!-- <b-button class="submitButton" @click="saveArea">Lagre</b-button> -->
      <b-button @click="saveArea">Lagre</b-button>
    </b-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { postArea } from "../../../../../services/reenapi";

export default {
  props: {
    coords: Array,
  },

  data() {
    return {
      name: "",
      organization_id: "",
      region_id: "",
      factory: "",
      address: "",
      areaSaved: false,
      areaType: [
        { value: "Depot", text: "Depot" },
        { value: "Service", text: "Service" },
      ],
    };
  },

  computed: {
    ...mapGetters(["organizations", "regions", "user"]),
    
    /**
     * Gets the correct default value.
     * @returns {string?}
     */
    regionValue() {
      if (this.regions.length === 1) {
        return this.regions[0].value;
      }
      return null;
    },
  },

  methods: {
    ...mapActions(["setShowDraw", "cacheRegions", "cacheOrganizations"]),

    async saveArea() {
      let form = {
        name: this.name,
        owner: this.$auth.user.email,
        region_id: this.region_id,
        organization_id: this.user.app_metadata.organization_id,
        address: this.address,
        area_type: this.factory,
        coordinates: [],
      };

      for (let coord of this.coords) {
        let obj = {
          x: coord.lng,
          y: coord.lat,
        };
        form.coordinates.push(obj);
      }

      let finalCoord = {
        x: this.coords[0].lng,
        y: this.coords[0].lat,
      };
      form.coordinates.push(finalCoord);

      await postArea(form);
      this.$router.push({ name: "AreaOverview" });
    },
    async changeOrganization(organization_id) {
      this.organization_id = organization_id;
      await this.cacheRegions(organization_id);
      this.region_id = this.regionValue;
    },
  },
  
  async mounted() {
    await this.cacheOrganizations();
    this.organization_id = this.user.app_metadata.organization_id;
    await this.cacheRegions(this.user.app_metadata.organization_id);
  },
};
</script>

<style scoped>
button {
  width: 100%;
  height: 30px;
  margin-top: 10px;
  text-decoration: bold;
  background-color: rgb(166, 203, 231);
  border-radius: 5px;
  border: 0;
}
.createAreaInfoBox {
  position: fixed;
  top: 100px;
  padding: 10px;
  border-radius: 15px;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
}
.container {
  width: 400px;
  border-radius: 25px;
  box-shadow: 0px 0px 5px 1px black;
  padding: 25px;
  background-color: white;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
}
.submitButton {
  background-color: rgb(166, 203, 231);
  border: 0;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 1px black;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.body {
  justify-content: space-between;
  align-items: center;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.body::-webkit-scrollbar {
  display: none;
}
</style>