<template>
  <div id="mapContainer">
    <div id="map"></div>
  </div>
</template>

<script>
export default {
  props: {
    zoom: Number,
    mapStyle: Object,
    center: navigator.geolocationCoordinates,
  },
  data() {
    return {
      map: null,
    };
  },
  computed: {},
  methods: {
    findWidth() {
      let width = document.getElementById("mapContainer").offsetWidth;
      if (width > 768) return true;
      else return false;
    },
    updateMap() {
      window.google.maps.MapOptions(document.getElementById("map"), {
        zoom: this.zoom,
        mapTypeId: this.mapStyle.map.style,
        styles: this.mapStyle.value,
      });
    },
    initMap() {
      this.map = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: this.center.latitude, lng: this.center.longitude },
        zoom: this.zoom,
        mapTypeId: this.mapStyle.value,
        styles: this.mapStyle.map.style,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: true,
        rotateControl: false,
        fullscreenControl: this.findWidth(),
      });
      this.$emit("map", this.map);
    },
  },
  watch: {
    mapStyle(newValue) {
      newValue.map.style;
      this.updateMap();
      this.$emit("map", this.map);
    },
  },
  mounted() {
    this.initMap();
  },
  beforeDestroy() {
    
  }
};
</script>

<style scoped>
#map {
  height: calc(100vh - 60px) !important;
  width: 100vw !important;
  position: absolute !important;
  top: 60px !important;
  overflow: hidden !important;
}
</style>