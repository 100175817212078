<template>
  <div>
    <pre>
      <code>
        {{ user }}
      </code>
    </pre>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Profile",

  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
