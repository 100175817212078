<template>
  <div class="selectable-list">
    <b-row class="listStyle headlineStyle">
      <b-checkbox @input="checkAll" disabled />
      <b-col v-for="(headlinesList, index) in labels" :key="index">
        {{ headlinesList }}
      </b-col>
    </b-row>

    <ListItem
      v-for="(listItem, index) of values"
      :key="index"
      :listItem="listItem"
      :uniqueIdentifier="uniqueIdentifier"
      @checked="updateChecked"
      @unchecked="updateUnchecked"
      :isCheckedFunc="isCheckedFunc"
    />
  </div>
</template>

<script>
import ListItem from "./ListItem";

export default {
  components: { ListItem },
  name: "SelectableList",
  data() {
    return {
      checkedItems: [],
    };
  },
  props: {
    labels: Array,
    values: Array,
    uniqueIdentifier: String,
    isCheckedFunc: {
      type: Function,
      default: () => false,
    },
  },
  methods: {
    updateChecked(listItem) {
      this.$emit("itemChecked", listItem);
    },
    
    updateUnchecked(listItem) {
      this.$emit("itemUnchecked", listItem);
    },

    checkAll() {},
  },
};
</script>

<style scoped>
/* .selectable-list {
  width: 100%;
} */

.headlineStyle {
  /* margin-top: 40px !important; */
  font-weight: bold;
  border: 0 !important;
  background-color: unset !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  cursor: default !important;
  margin-bottom: -15px !important;
  /* margin-right: 30px !important; */
}

.headlineStyle:hover {
  box-shadow: none !important;
}

ul {
  padding-left: 0;
  width: 100%;
  margin-top: -20px;
  margin-left: 30px;
}

li {
  list-style-type: none;
}

input[type="checkbox"] {
  cursor: pointer !important;
}

.listStyle {
  margin-top: 25px;
  margin-left: 0;
  margin-right: 0;
  cursor: pointer;
  border: 1px solid #b5b5b5;
  background-color: white;
  border-radius: 0.25rem;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  /* width: 100%; */
}

.listStyle:hover {
  box-shadow: 0px 4px #65bf95;
}
</style>