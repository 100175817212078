<template>
  <div>
    <Map></Map>
    <MenuCard></MenuCard>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
import Map from "@/components/map/Map.vue";

export default {
  name: "Home",
  components: {
    Map,

    // MenuBox
    MenuCard: () => import("@/components/infoCards/MenuCard.vue"),
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getCurrentTheme", "getToken"]),
  },
  methods: {
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
  },
};
</script>

<style scoped>
.logo {
  display: flex;
  padding: 50px 50px;
  justify-content: left;
  position: fixed;
  z-index: 1025;
}
</style>