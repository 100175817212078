<template>
  <b-container>
    <PageTitle :title="$t('areas')">
      <Button :name="$t('areaOverview.showAreas')" @clicked="showAllAreas" />
    </PageTitle>

    <List
      :labels="labels"
      :valueFunc="getAreasFunc"
      @rowClicked="showAreaModal"
      defaultSort="region.name"
      :key="listKey"
    />

    <AreaModal
      id="area-modal"
      :area="areaShowedInModal"
      :key="areaShowedInModal"
      @update="listKey = listKey + 1"
    />
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getAreas } from "../../services/reenapi";
import PageTitle from "../../components/common/PageTitle";
import List from "../../components/common/List";
import AreaModal from "../../components/fleetManagement/modalComponents/AreaModal";
import { extractOrganizationId } from "../../services/utils";

export default {
  components: {
    PageTitle,
    List,
    AreaModal,
  },

  data() {
    return {
      areaShowedInModal: null,
      listKey: 0,
    };
  },

  computed: {
    ...mapGetters(["user"]),

    labels() {
      return [
        { name: this.$t("name"), value: "name", filterable: false },
        { name: this.$t("areaType"), value: "area_type", filterable: false },
        // { name: this.$t("owner"), value: "owner", filterable: false },
        { name: this.$t("region"), value: "region.name", filterable: true },
        { name: this.$t("address"), value: "address", filterable: false },
      ];
    },
  },

  methods: {
    ...mapActions(["setShowAreaList", "cacheAreaTypes", "cacheRegions"]),

    async showAreaModal(area) {
      this.areaShowedInModal = area;
      await this.cacheRegions(extractOrganizationId(area));
      this.$bvModal.show("area-modal");
    },

    showAllAreas() {
      this.setShowAreaList(true);
      this.$router.push({ name: "Home" });
    },

    async getAreasFunc(params) {
      return {
        data: (await getAreas(params)).data,
        totalCount: -1,
      };
    },
  },

  async mounted() {
    await this.cacheRegions(this.user.app_metadata.organization_id);
    await this.cacheAreaTypes();
  },
};
</script>
