<template>
  <div class="clearArea">
    <button class="areaButton" @click="clearArea">Fjern Område</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      polygon: {},
    };
  },
  computed: {},
  props: {
    coordinates: Array,
    map: Object,
  },
  methods: {
    initPolygon(data) {
      this.polygon = new window.google.maps.Polygon({
        paths: data,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      });
      this.placePolygon();
    },
    placePolygon() {
      this.polygon.setMap(this.map);
    },
    clearArea() {
      this.polygon.setMap(null);
      this.polygon = [];
      this.$emit("polygonCreated", this.polygon);
    },
  },
  mounted() {
    this.initPolygon(this.coordinates);
  },
  beforeDestroy() {
    this.polygon.setMap(null);
    this.polygon = null;
  },
};
</script>

<style scoped>
.clearArea {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 1px black;
}
.areaButton {
  border-radius: 15px;
  background-color: rgb(166, 203, 231);
  border: 0px;
  box-shadow: 0px 0px 5px 1px gray;
}
.clearArea :hover {
  background-color: rgb(122, 148, 168);
}
</style>