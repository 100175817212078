<template>
  <div class="autocompleteMain">
    <!-- <div class="menuIconContainer">
        <div class="menuIconSubcontainer" @click="showSearch = !showSearch">
          <b-icon-search class="h5 menuIcon"></b-icon-search>
        </div>
      </div> -->

    <div class="autocompleteContainer" v-if="showSearch">
      <div class="autocompleteSubcontainer">
        <b-icon-search class="searchIcon" @click="showSearch = !showSearch" />
        <input
          id="autocompleteInput"
          class="autocomplete"
          type="text"
          placeholder="Enter a location..."
        />
      </div>

      <div class="filterIconContainer" @click="showFilter">
        <b-icon-sliders color="gray" class="h2 icon" />
      </div>

      <div class="spinnerContainer">
        <b-spinner class="spinner" v-if="loading" variant="info" height="10px" width="10px"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    map: Object,
    mapType: String,
    loading: Boolean,
  },

  data() {
    return {
      showSearch: true,
      autocomplete: "",
      autocompleteInitiated: false,
      options: {
        fields: ["formatted_address", "geometry", "name"],
        origin: this.map.getCenter(),
      },
      input: "",
    };
  },

  methods: {
    ...mapActions(["showFilter"]),

    setPlace(place) {
      this.place = place;

      if (this.mapType == "googleMap") {
        let lat = this.place.geometry.location.lat();
        let lng = this.place.geometry.location.lng();
        this.map.panTo({ lat: lat, lng: lng });
      } else {
        this.map.setCenter({
          lat: this.place.geometry.location.lat(),
          lng: this.place.geometry.location.lng(),
        });
      }
    },

    addListener() {
      this.autocomplete.addListener("place_changed", () => {
        const place = this.autocomplete.getPlace();
        this.setPlace(place);
      });
    },

    initAutocomplete() {
      let inputField = document.getElementById("autocompleteInput");
      this.autocomplete = new window.google.maps.places.Autocomplete(
        inputField,
        this.options
      );
    },
  },

  mounted() {
    this.initAutocomplete();
    this.addListener();
  },
};
</script>

<style scoped>
.icon {
  margin: 10px;
  padding: 5px;
}
.spinner {
}
.autocompleteMain {
  position: fixed;
  top: 60px;
  left: 0px;
  /* animation-duration: 0.3s; */
  transition: max-width 1s ease-in;
  /* animation-name: autocompleteCollapse; */
}

.autocompleteContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: #261440;
  width: 100vw;
  padding: 5px;
  height: 40px;
}

.autocompleteSubcontainer {
  background-color: white;
  box-shadow: 0px 0px 3px 0.1px #8aa9c0;
  height: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}

.autocomplete {
  background-color: white;
  border: 0;
  border-radius: 20px;
  height: 20px;
  font-size: 15px;
}

.filterIconContainer :hover {
  color: black;
}

.filterIconContainer > {
  cursor: pointer;
  margin: 0 !important;
}

.spinnerContainer {
  right: 5%;
  top: 13px;
  cursor: pointer;
  /* animation-duration: 0.3s;
  animation-name: spinnerContainerGrow; */
}

@media (min-width: 768px) {
  .icon {
    margin: 10px;
    padding: 0px;
  }
  .autocompleteMain {
    position: fixed;
    top: 70px;
    left: 10px;
  }

  .autocompleteContainer {
    height: 60px;
    border-radius: 30px;
    box-shadow: 0px 0px 10px 2px black;
    overflow: hidden;
    background-color: white;
    padding: 10px;
    width: 350px;
  }

  .autocomplete {
    background-color: #d4e6f1;
    border: 0;
    border-radius: 20px;
    height: 40px;
    padding-left: 10px;
    width: 80%;
  }

  .autocompleteSubcontainer {
    background-color: #d4e6f1;
    border-radius: 20px;
    height: 40px;
    width: 80%;
    max-width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* .menuIconContainer {
  position: fixed;
  top: 70px;
  left: 10px;
  z-index: 5;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  text-align: center;
  box-shadow: 0px 0px 5px 1px gray;
  background-color: white;
}
.menuIconSubcontainer {
  background-color: #d4e6f1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 10px;
}
.menuIcon {
  padding: 1px;
  position: absolute;
  left: 10px;
  top: 10px;
} */
@keyframes autocompleteCollapse {
  from {
    width: 30px;
    font-size: 0;
  }
  to {
    width: 400px;
    font-size: 14px;
  }
}

@keyframes spinnerContainerGrow {
  from {
    width: 0px;
  }
  to {
    width: 50px;
  }
}
</style>