<template>
  <b-container>
    <PageTitle :title="$t('taskOverview.title')">
      <Button
        :name="$t('taskOverview.newTask')"
        @click.native="$router.push('/fleetmanagement/newassignment')"
      />
    </PageTitle>

    <b-row>
      <b-col>
        <b-select
          v-model="selectedArea"
          :options="returnOptionsArea"
          class="select"
        ></b-select>
      </b-col>
      <b-col>
        <b-select
          v-model="selectedStatus"
          :options="returnOptionsStatus"
          class="select"
        ></b-select>
      </b-col>
    </b-row>

    <List
      :labels="$t('taskOverview.labels')"
      :values="taskList"
      @rowClicked="$bvModal.show('modalWindow')"
    />
    <!-- <List :labels="headlines" :values="returnTableItems" /> -->

    <AssignmentModal />
  </b-container>
</template>

<script>
// import List from "../Administration/subComponents/TaskList";
import List from "../../components/common/List";
import PageTitle from "../../components/common/PageTitle";
import AssignmentModal from "../../components/fleetManagement/modalComponents/AssignmentModal";

export default {
  components: {
    List,
    PageTitle,
    AssignmentModal,
  },
  data() {
    return {
      taskName: "Slamtømming",
      rodeName: "829 Åsgrend",
      descriptionText: "Informasjon om det spesifikke oppdraget står her",
      taskList: [
        {
          area: "829 Åsgrend",
          name: "Hent containere",
          responsible: "Kåre Vanndam",
          status: "Aktiv - ikke påbegynt",
        },
        {
          area: "829 Åsgrend",
          name: "Hent containere",
          responsible: "Hans Vanndam",
          status: "Aktiv - ikke påbegynt",
        },
      ],
      tasks: [
        {
          area: {
            name: "Example",
            organization: {
              name: "Example Organization",
            },
            location: [],
          },
          taskType: "Eksempel Oppgave",
          containers: [
            {
              alias: "JBYSRJ Container",
              State: "A2",
              QrCode: "JBYSRJ",
              ContainerType: "Type1",
              useType: "Residual Waste",
            },
            {
              alias: "JBYSRJ Container",
              State: "A2",
              QrCode: "JBYSRJ",
              ContainerType: "Type1",
              useType: "Residual Waste",
            },
            {
              alias: "JBYSRJ Container",
              State: "A2",
              QrCode: "JBYSRJ",
              ContainerType: "Type1",
              useType: "Residual Waste",
            },
          ],
          state: "Aktiv - Under Arbeid",
          assignedTo: "Kari Nordmann",
        },
        {
          area: {
            name: "Example 2",
            organization: {
              name: "Example Organization",
            },
            location: [],
          },
          taskType: "Eksempel Oppgave",
          containers: [
            {
              alias: "JBYSRJ Container",
              State: "A2",
              QrCode: "JBYSRJ",
              ContainerType: "Type1",
              useType: "Residual Waste",
            },
            {
              alias: "JBYSRJ Container",
              State: "A2",
              QrCode: "JBYSRJ",
              ContainerType: "Type1",
              useType: "Residual Waste",
            },
            {
              alias: "JBYSRJ Container",
              State: "A2",
              QrCode: "JBYSRJ",
              ContainerType: "Type1",
              useType: "Residual Waste",
            },
          ],
          state: "Aktiv - Ikke Påbegynt",
          assignedTo: "Tom Fjell",
        },
      ],
      filter: "",
      selectedArea: null,
      selectedStatus: null,
    };
  },
  computed: {
    returnTasks() {
      return this.tasks;
    },
    returnOptionsArea() {
      let options = [
        {
          value: null,
          text: "Velg en Rode",
        },
      ];
      for (var item of this.tasks) {
        let obj = {
          value: item.area.name,
          text: item.area.name,
        };
        options.push(obj);
      }
      options = this.returnUniques(options);
      return options;
    },
    returnOptionsStatus() {
      let options = [
        {
          value: null,
          text: "Velg en Status",
        },
      ];
      for (var item of this.tasks) {
        let obj = {
          value: item.state,
          text: item.state,
        };
        options.push(obj);
      }
      options = this.returnUniques(options);
      return options;
    },
    returnTableItems() {
      let area = this.selectedArea;
      let status = this.selectedStatus;
      let filter = this.filter;
      let items = [];

      for (var item of this.tasks) {
        if (
          item.state.startsWith(filter) ||
          item.area.name.startsWith(filter) ||
          item.assignedTo.startsWith(filter) ||
          item.taskType.startsWith(filter)
        ) {
          if (
            (!area && !status) ||
            (item.state == status && item.area.name == area) ||
            (item.area.name == area && !status) ||
            (item.state == status && !area)
          )
            items.push(item);
        }
      }
      return items;
    },
  },
  methods: {
    returnUniques(arr) {
      arr = arr.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(
            (t) => t.value === thing.value && t.text === thing.text
          )
      );
      return arr;
    },
  },
};
</script>

<style scoped>
.background {
  overflow-y: scroll;
  height: 100vh;
  width: 100vw;
  background-color: #f7f8f8;
}
.assignmentInput {
  padding: 15px;
}
.assignmentDetails {
  margin-top: 10px;
  border: 1px solid black;
  box-shadow: 0px 0px 10px 1px rgb(139, 139, 139);
  padding: 20px;
}
.addAssignmentContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 60vw;
  padding: 25px;
  background-color: white;
  border-radius: 25px;
}
.buttonContainer {
  text-align: right;
  padding: 5px;
}
</style>