<template>
  <div>
    <b-col class="modal-buttons">
      <button id="deleteBtn" @click="$emit('delete')">
        {{ $t("delete") }}
      </button>
      <button id="saveBtn" @click="$emit('save')">
        {{ $t("save") }}
      </button>
      <button id="cancelBtn" @click="$emit('close')">
        {{ $t("close") }}
      </button>
    </b-col>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.modal-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

.btn {
  margin: 5px;
  margin-top: 15px;
  box-shadow: 2px 2px 4px #b5b5b5;
  color: #000000;
  border: 0px;
  border-radius: 5px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
}

button:first-child {
  margin-left: 0 !important;
}

button:last-child {
  margin-right: 0 !important;
}

#saveBtn {
  background-color: #65bf95;
}

#saveBtn:hover {
  background-color: #7dddb0;
}

#cancelBtn {
  background-color: #b5b5b5;
}

#cancelBtn:hover {
  background-color: #c8c8c8;
}

#deleteBtn {
  background-color: #ff6a6a;
}

#deleteBtn:hover {
  background-color: #ff8585;
}
</style>