<template>
  <b-container>
    <PageTitle :title="$t('vehicles')" />

    <List
      :labels="labels"
      :valueFunc="getVehiclesFunc"
      v-on:rowClicked="selectedVehicle = $event"
    />

    <!-- <VehicleModal
      v-if="selectedVehicle"
      :vehicleObj="selectedVehicle"
      v-on:close="selectedVehicle = $event"
    /> -->
  </b-container>
</template>

<script>
import { getVehicles } from "../../services/reenapi.js";

import PageTitle from "../../components/common/PageTitle";
import List from "../../components/common/List";
// import VehicleModal from "../../components/fleetManagement/modalComponents/VehicleModal";

export default {
  components: {
    PageTitle,
    List,
    // VehicleModal,
  },
  data() {
    return {
      selectedVehicle: null,
      doShowModal: false,
      vehicleShowedInModal: null,
    };
  },
  computed: {
    labels() {
      return [
        { name: this.$t("licensePlate"), value: "license_plate.number", filterable: false },
        { name: this.$t("serialNumber"), value: "unit.serial_number", filterable: true },
        { name: this.$t("region"), value: "region.name", filterable: false },
      ];
    },
  },
  methods: {
    async getVehiclesFunc(params) {
      return {
        data: (await getVehicles(params)).data,
        totalCount: -1,
      };
    },

    showVehicleModal(vehicle) {
      this.doShowModal = true;
      this.vehicleShowedInModal = vehicle;
      this.$bvModal.show("modalWindow");
    },
  },
};
</script>
