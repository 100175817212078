<template>
  <div></div>
</template>

<script>
"use strict";
import cEmpty from "@/assets/images/icons-src/TrashCanEmpty.png";
import cFull from "@/assets/images/icons-src/TrashCanFull.png";
import cAlmost from "@/assets/images/icons-src/TrashCanAlmostFull.png";
import cMid from "@/assets/images/icons-src/TrashCanMid.png";
import cNull from "@/assets/images/icons-src/TrashCanNull.png";
import tUnknown from "@/assets/images/icons-src/TruckUnknown.svg";
import tKnown from "@/assets/images/icons-src/TruckKnown.svg";
import unknown from "@/assets/images/icons-src/questionMark.png";
import { mapActions } from "vuex";

export default {
  props: {
    map: Object,
    data: Object,
    oms: Object,
    cluster: Object,
  },
  computed: {},
  data() {
    return {
      truckKnown: tKnown,
      truckUnknown: tUnknown,
      containerEmpty: cEmpty,
      containerFull: cFull,
      containerAlmostFull: cAlmost,
      containerMid: cMid,
      containerNull: cNull,
      unk: unknown,
      googleMarkers: [],
    };
  },
  methods: {
    ...mapActions(["setSelectMarker"]),
    
    initMarker() {
      for (let type in this.data) {
        for (let item of this.data[type]) {
          let marker = new window.google.maps.Marker({
            position: {
              lat: item.location.latitude,
              lng: item.location.longitude,
            },
            icon: this.returnIcon(item),
            value: item,
          });
          this.addClickListener(marker);

          this.googleMarkers.push(marker);
          // Adds the marker to both the map, and to the cluster
          this.oms.addMarker(marker);
        }
      }
      this.cluster.addMarkers(this.googleMarkers);
    },

    returnIcon(c) {
      // This method will return the correct icon based
      // on how full the container is.
      if (c.qr_code) {
        let p = this.calculatePercentage(c);
        if (p >= 90) return this.containerFull;
        if (p <= 30 && p > 0) return this.containerEmpty;
        if (p > 30 && p <= 60) return this.containerMid;
        if (p > 60 && p < 90) return this.containerAlmostFull;
        else return this.containerNull;
      } else if (c.unit && (c.unit.serial_number.startsWith("MUS") || c.unit.serial_number.startsWith("ANS"))) {
        return this.truckKnown;
      } else return this.unk;
    },

    calculatePercentage(c) {
      if (c.fill_depth && c.qr_code) {
        let heightInMm = c.container_type.height * 1000;
        let sensorValue = c.fill_depth.value;
        let remainingPercentage = 100 - (sensorValue / heightInMm) * 100;
        return remainingPercentage;
      } else return null;
    },

    addClickListener(marker) {
      marker.addListener("click", () => this.setSelectMarker(marker.value), {
        passive: true,
      });
    },
  },

  mounted() {
    this.initMarker();
  },
  beforeDestroy() {
    // CLEANUP TO PREVENT MEMORY LEAK
    this.cluster.removeMarkers(this.googleMarkers);
    for (let marker of this.googleMarkers) {
      this.oms.removeMarker(marker);
      marker.setMap(null);
      this.googleMarkers[marker] = null;
      delete this.googleMarkers[marker];
    }
    this.googleMarkers = null;

    this.truckKnown = null;
    this.truckUnknown = null;
    this.containerEmpty = null;
    this.containerFull = null;
    this.containerAlmostFull = null;
    this.containerMid = null;
    this.containerNull = null;
    this.unk = null;

    this.cluster.clearMarkers();
  },
};
</script>

<style scoped>
</style>