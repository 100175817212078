<template>
  <WidgetBase class="inventory-widget" title="Inventory">
    <!-- <div class="headline">
      <div class="emptyBlock"></div>
      <span @click="$emit('remove')" class="removeWidgetBtn">
        <b-icon-x-circle style="width: 40px; height: 40px"></b-icon-x-circle>
      </span>
    </div>
    <p class="big-text">{{ getContainersInStorage() }}</p>
    <b-button to="dashboard/inventory"
      >Oversikt over type og plassering</b-button
    > -->
  </WidgetBase>
</template>

<script>
import WidgetBase from "./widgetComponents/WidgetBase.vue";
export default {
  name: "Inventory",
  components: {
    WidgetBase,
  },
  props: {
    options: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    getContainersInStorage() {
      let containers = 199;
      // Replace with actual information once API supports it.
      return containers;
    },
  },
};
</script>
