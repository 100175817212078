<template>
  <b-row>
    <b-col>
      <b-row v-b-toggle="collapseId">
        <span class="when-closed">
          <b-icon-caret-right-fill
            style="color: #65bf95; margin: 20 10 5 5"
            font-scale="2"
          ></b-icon-caret-right-fill>
        </span>
        <span class="when-opened">
          <b-icon-caret-down-fill
            style="color: #65bf95; margin: 20 10 5 5"
            font-scale="2"
          ></b-icon-caret-down-fill>
        </span>
        <h3>{{ headline }}</h3>
      </b-row>
      <b-collapse :visible="isVisible" :id="collapseId">
        <b-row>
          <slot />
        </b-row>
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    collapseId: String,
    headline: String,
    isVisible: Boolean,
    labels: Array,
    values: Array,
  },
};
</script>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

h3, .when-closed > *, .when-opened > * {
  margin-top: 20px;
}
</style>
