<template>
  <div v-show="$auth.isAuthenticated">
      <b-container>

    <div class="menu">
      <b-row>
        <b-col class="menuLink" :class="highlight === 'Assignment' ? 'active' : ''">
            <div class="text" @click="$emit('change', 'Assignment')">
                Oppdrag
            </div>
        </b-col>
        <b-col class="menuLink" :class="highlight === 'map' ? 'active' : ''">
            <div class="text" @click="$router.push('/')">
                Kart
            </div>
        </b-col>
        <b-col class="menuLink" :class="highlight === 'UserSettings' ? 'active' : ''">
            <div class="text" @click="$emit('change', 'UserSettings')">
                Innstillinger
            </div>
        </b-col>
      </b-row>
    </div>
      </b-container>
  </div>
</template>

<script>
export default {
    name: "UserSettings",
    data() {
        return {
        }
    },
    props: {
        highlight: String,
    }
};
</script>

<style scoped>
.menu {
  background-color: #261440;
  /* border-top: 1px solid #f7f8f8; */
  position: fixed;
  height: 60px;
  width: 100vw;
  z-index: 1000;
  color: #F7F8F8;
}
.container {
    padding: 0;
    margin: 0;
}
.col {
  display: flex;
  justify-content: center;
}
.text {
margin-top: 15px;
}
.menuLink {
border-top: 1px solid #F7F8F8;
cursor: pointer;
}
.active {
border-top: 5px solid #65BF95;
z-index: 1500;
margin-top: -4px;
}
</style>
