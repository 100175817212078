<template>
  <div v-show="$auth.isAuthenticated" class="header">
    <div
      class="nav-content"
      :class="$route.meta.theme === 'map' ? 'map-theme' : ''"
    >
      <div
        class="navbar"
        :class="$route.meta.theme === 'map' ? 'map-theme' : ''"
      >
        <router-link to="/">
          <img
            class="logo"
            src="../assets/images/logo/REEN-logo-negative-trans.png"
            alt="logo"
          />
        </router-link>
        <div class="menuButtons">
          <a class="menuLink" to="/dashboard" href="/dashboard">Dashboard</a>
          <div class="menuLink" @click="showAdmin = !showAdmin">
            {{ $t("fleetManagement") }}
          </div>
          <router-link class="menuLink" to="/">{{ $t("map") }}</router-link>
        </div>

        <!-- <div class="iconContainer">
        <div class="iconChild">
          <b-icon-person-fill class="h2" color="white"></b-icon-person-fill>
        </div>
        <div>
          <b-icon-person-fill class="h1" color="white"></b-icon-person-fill>
          <b-icon-list
            class="dropdown h1"
            color="white"
            @click="dropdownShow = !dropdownShow"
          ></b-icon-list>
        </div>
      </div> -->

        <SelectLocale class="selectLocale"></SelectLocale>

        <!-- Right aligned icons -->
        <div class="iconContainer">
          <div class="iconChild profileIcon">
            <b-icon-person-fill
              class="h2 icon"
              color="white"
              @click="profileOptions = !profileOptions"
            ></b-icon-person-fill>
          </div>

          <!-- <div class="iconChild">
            <b-icon-bell-fill class="h2 icon" color="white"></b-icon-bell-fill>
          </div> -->

          <div class="iconChild dropdown">
            <b-icon-list
              class="h2 icon"
              color="white"
              @click="dropdownShow = !dropdownShow"
            ></b-icon-list>
          </div>

          <!-- <div class="iconChild">
          <b-icon-gear-wide
            color="white"
            class="h2 icon"
            @click="showSettings = !showSettings"
          ></b-icon-gear-wide>
        </div> -->
        </div>
      </div>

      <!-- DROPDOWN MENU MOBILE -->
      <Animated enter="slideInDown" leave="slideOutUp">
        <div class="dropdownMenu" v-if="dropdownShow">
          <!-- <div>
            <router-link class="dropdownLink" to="/dashboard"
              >Dashboard</router-link
            >
          </div>
          <div>
            <router-link class="dropdownLink" to="/">Kart</router-link>
          </div>
          <div>
            <router-link class="dropdownLink" to="/driver">Sjåfør</router-link>
          </div>
          <div>
            <router-link
              class="dropdownLink"
              to=""
              @click="showAdmin = !showAdmin"
              >Flåtestyring</router-link
            >
          </div> -->
          <div
            v-for="item in adminMenu"
            :key="item.to"
            class="userOption dropdownLink"
            @click="clickDropdownLink(item.to)"
          >
            {{ item.name }}
          </div>
        </div>
      </Animated>

      <!-- ADMIN DROPDOWN -->
      <div @click="showAdmin = !showAdmin">
        <!-- <div v-if="showAdmin" class="adminDropdown"> -->
        <div v-if="showAdmin" class="adminDropdown">
          <div
            v-for="item in adminMenu"
            :key="item.to"
            class="userOption"
            @click="routeTo(item.to)"
          >
            {{ $t(`${item.key}`) }}
          </div>
        </div>
      </div>

      <!-- USER INFORMATION DROPDOWN -->
      <div @click="profileOptions = !profileOptions">
        <div v-if="profileOptions" class="userDropdown">
          <div class="userTitle">{{ $auth.user.email }}</div>
          <div class="content">
            <!-- <div class="userOption">
              <a
                href="https://reenstorageaccount.blob.core.windows.net/dokumenter/PP_M%C3%B8te.pdf"
                >Profil</a
              >
            </div>
            <div class="userOption">Endre passord</div>
            <div class="userOption">Fakturaoversikt</div>
            <router-link class="userOption" to="/controlcenter"
              >Enhetsoversikt</router-link
            >
            <div class="userOption">Brukerveiledning</div> -->
            <div
              class="userOption"
              v-if="$auth.isAuthenticated"
              @click="logout"
            >
              {{ $t("userOptions.logOut") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- SETTINSG DROPDOWN -->
    <div v-if="showSettings" class="userDropdown">
      <div class="userTitle">Settings</div>
      <div class="content">
        <div class="userOption">Trip Planner</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SelectLocale from "./switches/SelectLocale.vue";
import {userIsSuperadmin} from "@/services/utils";

export default {
  components: {
    SelectLocale,
  },
  data() {
    return {
      dropdownShow: false,
      profileOptions: false,
      showSettings: false,
      showAdmin: false,
    };
  },

  computed: {
    ...mapGetters(["user"]),

    adminMenu: function() {
      let navItems = [
        {
          name: "Oppdrag",
          to: "/fleetmanagement/assignments",
          isVisible: false,
          key: 'assignments'
        },
        {
          name: "Områder",
          to: "/fleetmanagement/area",
          isVisible: true,
          key: "areas",
        },
        {
          name: "Containere",
          to: "/fleetmanagement/containers",
          isVisible: true,
          key: "containers",
        },
        {
          name: "Container-typer",
          to: "/fleetmanagement/containers/types",
          isVisible: true,
          key: "container_types",
        },
        {
          name: "Enhetsoversikt",
          to: "/fleetmanagement/devices",
          isVisible: true,
          key: "units",
        },
        {
          name: "Brukere",
          to: "/fleetmanagement/users",
          isVisible: false,
          key: "users"
        },
        {
          name: "Kjøretøy",
          to: "/fleetmanagement/vehicle",
          isVisible: false,
          key: "vehicles",

        },
        {
          name: "Kunder",
          to: "/fleetmanagement/customers",
          isVisible: false,
          key: "customers"
        },
        {
          name: "Rapporter",
          to: "/fleetmanagement/reports",
          isVisible: false,
          key: "reports"
        },
        {
          name: "Avviksregister",
          to: "/fleetmanagement/deviations",
          isVisible: false,
          key: "deviations"
        },
        {
          name: this.$t("dashboard.lost"),
          to: "/fleetmanagement/containerslost",
          isVisible: true,
          key: "dashboard.lost",
        }
      ].filter((i) => i.isVisible)

      return navItems;
    }
  },

  methods: {
    linkTo() {
      // let showPDF = "https://reenstorageaccount.blob.core.windows.net/dokumenter/PP_M%C3%B8te.pdf";
      // return showPDF;
      // window.open("http://reenstorageaccount.blob.core.windows.net/dokumenter/PP_M%C3%B8te.pdf", "_blank");
      this.$router.push(
        "https://reenstorageaccount.blob.core.windows.net/dokumenter/PP_M%C3%B8te.pdf"
      );
    },
    routeTo(path) {
      this.$router.push(path);
    },
    testSaveCookie() {
      document.cookie = "123=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    },
    logStuff() {
      let cookies = document.cookie;
      cookies.split("; ");

      console.log(cookies);
    },

    documentClick(e) {
      if (!this.$el.contains(e.target)) {
        this.showAdmin = false;
        this.profileOptions = false;
      }
    },

    clickDropdownLink(to) {
      this.routeTo(to);
      this.dropdownShow = false;
    },

    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
  created() {
    document.addEventListener("click", this.documentClick);
  },
  destroyed() {
    document.removeEventListener("click", this.documentClick);
  },
};
</script>
<style scoped>
.selectLocale {
  position: absolute;
  right: 100px;
  top: 10px;
  z-index: 5000;
}
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #261440;
  height: 60px;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 1410px;
  z-index: 2000;
  transition: 0.5s ease-out;
}

.nav-content {
  width: 1410px;
  margin: auto;
  transition: 0.5s ease-out;
}

.map-theme {
  width: 100%;
  max-width: 100%;
}

/* .userDropdown {
  background: rgb(25, 25, 25);
  border: solid rgb(61, 61, 61) 1px;
  color: white;
  position: fixed;
  top: 60px;
  right: 10%;
  width: 150px;
} */

.userDropdown {
  background: white;
  color: black;
  position: absolute;
  top: 60px;
  right: 0;
  width: 250px;
  font-size: 20px;
  z-index: 7000;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.2);
}

.userTitle {
  font-weight: 550;
  text-align: center;
  padding: 7px;
  border-bottom: solid #65bf95 1px;
  margin-bottom: 10px;
}
.iconContainer {
  max-height: 60px;
  position: absolute;
  height: 60px;
  right: 10px;
  text-align: right;
}
.icon {
  margin-top: 14px;
  margin-left: 5px;
  margin-right: 5px;
}
.iconContainer :hover {
  background-color: #190e29;
  cursor: pointer;
}
.iconChild {
  float: left;
}
.dropdownMenu {
  position: fixed;
  top: 60px;
  left: 0;
  height: auto;
  background-color: #261440;
  /* background-color: white; */

  width: 100vw;
  z-index: 1000;
}
.dropdownLink {
  color: white;
}
.logo {
  height: 45px;
  padding: 7.5px;
  padding-left: 0;
}
.menuButtons {
  display: none;
}
.menuLink {
  color: white;
  font-size: 20px;
  cursor: pointer;
  user-select: none;
}
.menuLink:hover {
  text-decoration: none;
}

.userOption {
  cursor: pointer;
  width: 100%;
  display: block;
  text-decoration: none;
  /* margin: 20px; */
  padding: 10px 15px;
  /* text-align: right; */
  user-select: none;
}

@media (min-width: 750px) {
  /* .adminDropdown {
    color: white;
    background-color: rgb(25, 25, 25);
    position: fixed;
    top: 60px;
    left: 365px;
    border: 1px solid white;
    z-index: 7000;
  } */

  /* .adminDropdown :hover {
    background-color: black;
  } */

  .selectLocale {
    right: 60px;
  }

  .adminDropdown {
    color: black;
    background-color: white;
    position: relative;
    text-align: left;
    font-size: 20px;
    top: 60px;
    left: 150px;
    width: 200px;
    z-index: 100000;
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.2);
  }

  .adminDropdown :hover {
    /* background-color: #65bf95; */
    background: #b5b5b5;
  }

  .adminDropdown .userOption {
    text-align: left;
  }

  .menuButtons {
    display: flex;
    text-align: center;
    position: absolute;
    left: 150px;
    top: 0;
    height: 60px;
  }

  .menuLink {
    padding: 15px;
    height: 100%;
    text-align: center;
  }

  .menuButtons :hover {
    background-color: #7b0051;
  }

  .dropdown {
    display: none;
  }

  .profileIcon {
    display: inline;
  }

  .content :hover {
    background-color: #65bf95;
  }
}
</style>