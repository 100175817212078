import Vue from "vue";
import Vuex from "vuex";

import SwapTheme from "./modules/swapTheme";
import GetToken from "./modules/getToken";
import MapOptions from "./modules/mapOptions";
import FilterInformation from "./modules/filterInformation";
import ItemCache from "./modules/itemCache";
import AlertBox from "./modules/alertBox";
import Assignments from "./modules/assignments";
import Map from "./modules/map";
import Area from "./modules/area";
import CachedData from "./modules/cachedData";
import Profile from "./modules/profile";
import Dashboard from "./modules/dashboard"
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Dashboard,
    SwapTheme,
    GetToken,
    MapOptions,
    FilterInformation,
    ItemCache,
    AlertBox,
    Assignments,
    Map,
    Area,
    CachedData,
    Profile,
  },
});