<template>
  <div class="checkedBox">
    <b-container>
      <div class="text">
        <strong> Tømming registrert! </strong>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "ContainerUnloaded",

  data() {
    return {
      interval: null,
    };
  },
  created() {
    this.interval = setTimeout(
      function () {
        this.$emit("change", "Assignment");
      }.bind(this),
      2000
    );
  },
};
</script>

<style scoped>
.checkedBox {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
}
.container {
  border-radius: 10px;
  color: #65bf95;
  position: absolute;
  z-index: 999;
  overflow: auto;
  width: 80%;
  height: 70%;
  top: 20%;
}
.text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 3rem;
  height: 100%;
  margin-top: -25px;
}
</style>