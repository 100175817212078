<template>
  <div>
    <ChooseRegistration
      class="chooseRegistrationBox"
      v-if="isButtonClicked"
      @change="$emit('change', $event), isButtonClicked = false"
    />
    <b-container>
        <b-col>
          <b-row>
            <h2>Oppdrag nr. 5</h2>
          </b-row>

          <div class="divider"></div>

          <b-row>
            <ul>
              <div
                class="assignmentList"
                v-for="(item, index) in containerList"
                :key="index"
              >
                <li><strong>Anlegg:</strong> {{ item.anlegg }}</li>
                <li><strong>Adresse:</strong> {{ item.adress }}</li>
                <li><strong>Anleggstype:</strong> {{ item.type }}</li>
                <li><strong>Volum:</strong> {{ item.volume }}</li>
              </div>
            </ul>
          </b-row>
        </b-col>
          <Button @change="isButtonClicked = true"></Button>
    </b-container>
    <Menu class="menu" highlight="Assignment" @change="$emit('change', $event)"></Menu>
  </div>
</template>

<script>
import Menu from "../driver/subComponents/Menu";
import Button from "../driver/subComponents/EmptyButton";
import ChooseRegistration from "./subComponents/ChooseRegistration";

export default {
  name: "Assignment",
  components: {
    Menu,
    Button,
    ChooseRegistration
  },
  data() {
    return {
      page: "UnloadContainer",
      isButtonClicked: false,
      containerList: [
        {
          anlegg: "5/Fyresdal",
          adress: "Snarteland",
          type: "Slamavskiller",
          volume: "3 kbm",
        },
        {
          anlegg: "263/Fyresdal",
          adress: "Snarteland Nordre",
          type: "Slamavskiller",
          volume: "4 kbm",
        },
        {
          anlegg: "264/Fyresdal",
          adress: "Landstedet",
          type: "Slamavskiller",
          volume: "5 kbm",
        },
        {
          anlegg: "264/Fyresdal",
          adress: "Landstedet",
          type: "Slamavskiller",
          volume: "5 kbm",
        },
      ],
    };
  },
};
</script>

<style scoped>
.background {
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  overflow-y: scroll;
  height: 100vh;
  width: 100vw;
}
.container {
  color: black;
  display: flex;
  justify-content: center;
  margin-top: 70px;
  padding-bottom: 70px !important;
  padding: 3%;
  position: relative;
}
.assignmentList {
  margin-top: 25px;
  cursor: pointer;
  border: 1px solid #b5b5b5;
  border-radius: 0.25rem;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  width: 100%;
}
.assignmentList:hover {
  box-shadow: 0px 4px #65bf95;
}
ul {
  padding-left: 0;
  width: 100%;
}
li {
  list-style-type: none;
}
.divider {
  border-bottom: 1px solid #65bf95;
  margin-top: 25px;
  margin-left: -15px;
  margin-right: -15px;
}
.menu {
  position: absolute;
  bottom: 0;
  width: 100vw;
  z-index: 5000;
}
.chooseRegistrationBox {
  z-index: 4000;
}
</style>
