<template>
  <div>
    <b-container class="tableContainer" fluid>
      <!-- User Interface controls -->
      <b-row class="headline">
        <b-col lg="6" class="my-1 filterContainer">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <!-- <b-form-checkbox>
                This is a test
              </b-form-checkbox> -->
              <b-form-input
                v-model="filter"
                type="search"
                id="filterInput"
                placeholder="Type to Search"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Main table element -->
      <b-row no-gutters>
        <b-table
          ref="inventoryTable"
          selectable
          striped
          small
          stacked="md"
          hover
          :filter="filter"
          :fields="fields"
          :items="allTableEntries"
          class="inventoryTable"
          caption-top
          select-mode="single"
          @row-selected="showDetails"
        >
          <template style="color: white !important" v-slot:table-caption>
            <h4>
              {{ getCurrentInventory.address }}
            </h4></template
          >
          <!-- <template
            v-for="(option, index) in checkboxOptions.Regions"
            :key="index"
          >
          </template> -->

          <!-- <template #cell(name)="row">
            {{ row.value.first }} {{ row.value.last }}
          </template> -->
        </b-table>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      filter: "",
      inventoryData: [],
      fields: [
        {
          key: "hentesMed",
          sortable: true,
        },
        {
          key: "utstyrstype",
          sortable: true,
        },
        {
          key: "beskrivelse",
          sortable: false,
        },
        {
          key: "tilstandFin",
          sortable: false,
        },
        {
          key: "tilstandOk",
          sortable: false,
        },
        {
          key: "tilstandStygg",
          sortable: false,
        },
        {
          key: "tilstandSkadet",
          sortable: false,
        },
        {
          key: "totalt",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getCurrentInventory", "getLocations"]),
    allTableEntries() {
      let allContainerStats = [];

      try {
        var finalEntry = {
          hentesMed: "",
          utstyrstype: "",
          beskrivelse: "",
          tilstandFin: 0,
          tilstandOk: 0,
          tilstandStygg: 0,
          tilstandSkadet: 0,
          totalt: 0,
        };
        for (let container of this.getCurrentInventory.data) {
          if (container.container_type) {
            let stats = allContainerStats.find(
              (cd) => cd.utstyrstype === container.container_type.name
            );
            finalEntry.totalt++;

            if (!stats) {
              stats = {
                hentesMed: container.container_type.mount_type,
                utstyrstype: container.container_type.name,
                beskrivelse: container.container_type.description,
                tilstandFin: 0,
                tilstandOk: 0,
                tilstandStygg: 0,
                tilstandSkadet: 0,
                totalt: 0,
                completeStats: container,
              };
              allContainerStats.push(stats);
            }
            stats.totalt++;
            let state = container.state.slice(0, 1);
            if (state == "A") {
              stats.tilstandFin++;
              finalEntry.tilstandFin++;
            } else if (state == "B") {
              finalEntry.tilstandOk++;
              stats.tilstandOk++;
            } else if (state == "C") {
              finalEntry.tilstandStygg++;
              stats.tilstandStygg++;
            } else {
              finalEntry.tilstandStygg++;
              stats.tilstandSkadet++;
            }
          }
        }
        allContainerStats.push(finalEntry);

        return allContainerStats;
      } catch {
        return null;
      }
    },
  },
  methods: {
    showDetails(items) {
      console.log(items[0].completeStats);
    },
  },
  updated() {},
};
</script>

<style scoped>
.tableContainer {
  height: 100vh;
  background: #f7f8f8;
  position: relative;
  overflow: scroll !important;
}

.tableContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tableContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.headline {
  position: relative;
  text-align: center;
  padding: 50px;
  top: 40px;
}

p {
  color: white;
  margin: 0;
}

#filterInput {
  border-radius: 15px;
  background-color: rgb(166, 203, 231);
  color: black;
}

.inventoryTable {
  margin: auto;
  margin-top: 25px;
  max-width: 90vw;
}

.filterContainer {
  position: absolute;
  right: 100px;
  top: 30px;
}
</style>