<template>
  <b-container class="unit-list">
    <h1>Enheter</h1>

    <List :labels="labels" :values="units" @rowClicked="rowClicked" />
  </b-container>
</template>

<script>
import { getUnits } from "../../services/reenapi";

import List from "../common/List";

export default {
  name: "UnitList",
  components: {
    List,
  },
  data() {
    return {
      units: [],
    };
  },
  computed: {
    labels() {
      if (this.units.length > 0) {
        return Object.getOwnPropertyNames(this.units[0]);
      }
      return [];
    },
  },
  methods: {
    rowClicked(unit) {
      console.log(unit);
      this.$router.push(`/controlcenter/${unit.id}`)
    },
  },
  async mounted() {
    this.units = await getUnits();
  },
};
</script>
