<template>
  <div></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  getContainersWithinRadius,
  getVehiclesWithinRadius,
} from "../../../services/reenapi";
import * as Wait from "@/services/wait.js";

export default {
  props: {
    map: Object,
    mapType: String,
    center: GeolocationCoordinates,
  },
  components: {
  },
  data() {
    return {
      // Initial radius for the first call to the API
      radius: 2000,

      // The data from the API
      combinedData: {
        vehicles: [],
        containers: [],
      },

      currentBounds: { lat: 59.05328, lng: 10.03517 },
      currentZoom: "",

      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getSelectedType"]),
  },
  methods: {
    ...mapActions(["setItemCache"]),
    async addEventListener() {
      // Will listen for changes in the map (Zoom, moving the map, etc) then execute methods
      // See the wait.js file in services for more information
      if (this.mapType == "googleMap") {
        this.map.addListener(
          "bounds_changed",
          Wait.debounce(async () => {
            this.getBounds();
            this.getRadiusAndCentre();
            await this.getData(this.currentBounds);
          }, 1500),
          {
            passive: true,
          }
        );
      }
    },
    async getData(pos) {

      this.$emit("loading", true);
      // Collects data from the API
      var containers = await getContainersWithinRadius(this.currentBounds, this.radius);
      var vehicles = await getVehiclesWithinRadius(
        this.currentBounds,
        this.radius
      );
      this.loading = false;

      const combinedData = { containers, vehicles };
      this.$emit("dataCollected", combinedData);
      this.$emit("loading", false);
      this.setItemCache({});

      this.setItemCache(combinedData);
    },
    getBounds() {
      // Gets the current bounds of the map.
      if (this.mapType == "googleMap") {
        this.currentBounds = this.map.getBounds();
        this.currentZoom = this.map.zoom;
      }
    },
    getRadiusAndCentre() {
      // Sets the current centre of the map, and calculates a radius based on the bounds.
      // Yes, this function looks kinda janky, but it was the best way to deal with google changing
      // the fucking names of their bounds keys
      let googleBoundsData = [];
      for (var item in this.currentBounds) {
        for (var val in this.currentBounds[item]) {
          if (typeof this.currentBounds[item][val] == "number")
            googleBoundsData.push(this.currentBounds[item][val]);
        }
      }

      var centreLat = (googleBoundsData[0] + googleBoundsData[1]) / 2;
      var centreLng = (googleBoundsData[2] + googleBoundsData[3]) / 2;
      this.currentBounds = { lng: centreLng, lat: centreLat };
      var diffLat = Math.abs(googleBoundsData[0] - googleBoundsData[1]);
      var diffLng = Math.abs(googleBoundsData[2] - googleBoundsData[3]);
      this.radius = (Math.min(diffLat, diffLng) / 2) * 150000;
    },
  },
  async mounted() {
    let pos = { lat: this.center.latitude, lng: this.center.longitude };
    await this.getData(pos);
    await this.addEventListener();
  },
  updated() {},
  beforeDestroy() {

  },
};
</script>

<style scoped>
.loadingOverlay {
  color: rgb(166, 203, 231);
  /* position: relative; */
  top: calc(35vh - 60px);
}
.backgroundSpinner {
  /* background-color: rgba(25, 25, 25, 0.5);
  position: fixed;
  text-align: center;
  top: 60px;
  height: 100vh;
  width: 100%; */
  z-index: 5000;
  position: fixed;
  top: 60px;
  left: 50px;
}
</style>