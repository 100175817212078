<template>
  <b-container>
    <PageTitle :title="$t('container_types')" />

    <List
      :labels="labels"
      :valueFunc="getContainerTypes"
      @rowClicked="showModal"
      defaultSort="name"
    />

    <ContainerTypeModal
      id="container-type-modal"
      :containerType="containerTypeShownInModal"
      :container="containerShowedInModal"
      @update="listKey = listKey + 1"
    />
  </b-container>
</template>

<script>
import { getContainerTypes } from "../../services/reenapi";

import PageTitle from "../../components/common/PageTitle";
import List from "../../components/common/List";
import ContainerTypeModal from "../../components/fleetManagement/modalComponents/ContainerType";

export default {
  name: "ContainerTypeOverview",

  components: {
    PageTitle,
    List,
    ContainerTypeModal,
  },

  data() {
    return {
      containerTypeShownInModal: {},
      listKey: 0,
    };
  },

  computed: {
    labels() {
      return [
        {
          name: this.$t("name"),
          value: "name",
          filterable: false,
        },
        {
          name: this.$t("mount_type"),
          value: "mount_type",
          filterable: false,
        },
        {
          name: this.$t("construction"),
          value: "construction",
          filterable: false,
        },
        {
          name: this.$t("volume"),
          value: "volume",
          filterable: false,
        },
      ];
    },
  },

  methods: {
    showModal(containerType) {
      this.containerTypeShownInModal = containerType;
      this.$bvModal.show("container-type-modal");
    },

    async getContainerTypes(params) {
      return {
        data: (await getContainerTypes(params)).data,
        totalCount: -1,
      };
    },
  },
};
</script>
