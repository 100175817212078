<template>
  <div id="app">
    <div v-if="!$auth.loading && !$auth.isAuthenticated">
      <Login />
    </div>
    <div v-else>
      <Navbar />
      <router-view class="view" />
    </div>
  </div>
</template>

<script>
import Login from "@/components/Login";
import Navbar from "@/components/Navbar";
import { mapActions } from "vuex";

export default {
  components: {
    Login,
    Navbar,
  },
  methods: {
    ...mapActions(["fetchToken", "setUser"]),
  },
  async mounted() {
    await this.fetchToken();
    await this.setUser(this.$auth.user.sub);
  },
};
</script>
<style>
@import "./assets/fonts/SofiaPro.css";
@import "./assets/fonts/SofiaProLight.css";

body {
  margin: 0;
  background-color: #f7f8f8 !important;
  font-family: "Sofia Pro", "Helvetica Neue", Helvetica, sans-serif !important;
  letter-spacing: 0.1ch;
  background-image: url("assets/images/illustrations/PatternArrowAndBlock.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
/* body,
body .mapboxgl-map {
  font-family: sofia-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
} */

body.fontLoaded {
  /* font-family: sofia-pro, "Helvetica Neue", Helvetica, Arial, sans-serif; */
  font-weight: 400;
  font-style: normal;
}

/* .mapboxgl-map {
  font-family: sofia-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
} */

#app {
  /* background-color: #f7f8f8 !important; */
  min-height: 100%;
  min-width: 100%;
}

.view {
  margin-top: 90px;
}

@media screen and (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 1380px !important;
  }
}

p,
label {
  font-weight: 400;
  font-style: normal;
  line-height: 1.5em;
}
</style>
