<template>
  <div class="module-base" id="module-base">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ModuleBase",
  props: ["image"],
  methods: {
    addBackgroundImage(image) {
      if (image) {
        let d = (document.getElementById(
          "module-base"
        ).style = `background-image:url(${image})`);
      }
    },
  },
  mounted() {
    this.addBackgroundImage(this.image);
  },
};
</script>

<style scoped>
.module-base {
  background: white;
  box-shadow: 0 0 3px 1px hsla(0, 0%, 78.4%, 0.9);
  padding: 15px;
  cursor: move;
  user-select: none;
  border-radius: 8px;
  min-height: fit-content !important;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
h2 {
  padding: 0px 0px 10px 0px;
  text-transform: uppercase;
}

p {
  font-size: 1.125em;
}

.removeWidgetBtn {
  color: #9964ec;
  cursor: pointer;
}
header {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: auto;
}
.emptyBlock {
  width: 40px;
}

.headline {
  top: 0;
  display: flex;
  justify-content: space-between;
}

.big-text {
  font-size: 6em;
  /* color: #7b0051; */
  color: #9964ec;
}

/* TODO: button burde global? Ihvertfall deler av den */
.btn {
  display: block;
  /* background-color: #7b0051; */
  background-color: #9964ec;
  text-decoration: none;
  padding: 20px;
  margin: 20px auto;
  height: 70px !important;
  width: 80% !important;
  border-radius: 35px;
  border: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  color: white;
  font-weight: 600;
  font-size: 1.125em;
  height: fit-content !important;
}

.btn:hover {
  /* background-color: #943374; */
  background-color: #8356ca;
}
</style>