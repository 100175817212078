const state = {
    // This is where you declare the variable.
    show: false,
    currentFilter: null,
    lastFilter: null,
    selectedType: null,
    lastSelectedType: null,
    showDraw: false,
    showList: false,
    selectedRegion: null,
    lastSelectedRegion: null
};

const getters = {
    // Getters makes the variable available to the components.
    getSelectedRegion: (state) => state.selectedRegion,
    getShowList: (state) => state.showList,
    getShowDraw: (state) => state.showDraw,
    getSelectedType: (state) => state.selectedType,
    getShow: (state) => state.show,
    getCurrentFilter: (state) => state.currentFilter
};

const actions = {
    setShowList({
        commit
    }) {
        commit("mutateShowList", state.showList = !state.showList)
        console.log(state.showList)

    },
    setShowDraw({
        commit
    }) {
        commit("mutateShowDraw", state.showDraw = !state.showDraw)
        console.log(state.showDraw)

    },
    setSelectedRegion({ commit }, obj) {
        commit("mutateSelectedRegion", obj)
        try {
            if (state.lastSelectedRegion == state.selectedRegion) {
                commit("mutateSelectedRegion", null)
                commit("mutateLastSelectedRegion", null)

            } else {
                commit("mutateLastSelectedRegion", obj)
            }
        } catch (e) {
            commit("mutateLastSelectedRegion", obj)
        }
    },
    setSelectedType({
        commit
    }, obj) {
        commit("mutateSelectedType", obj)
        try {
            if (state.lastSelectedType == state.selectedType) {
                commit("mutateSelectedType", null)
                commit("mutateLastSelectedType", null)

            } else {
                commit("mutateLastSelectedType", obj)
            }
        } catch (e) {
            commit("mutateLastSelectedType", obj)
        }
    },
    showFilter({
        commit
    }) {
        commit("mutateShow")
    },
    setCurrentFilter({
            commit
        },
        obj
    ) {
        commit("mutateCurrentFilter", obj)
        try {
            if (state.lastFilter.index == state.currentFilter.index && state.lastFilter.item == state.currentFilter.item) {
                commit("mutateCurrentFilter", null)
                commit("mutateLastFilter", null)

            } else {
                commit("mutateLastFilter", obj)
            }
        } catch (e) {
            commit("mutateLastFilter", obj)
        }
    }


};

const mutations = {
    // The mutation is the change of the value of a variable
    mutateShowList: (state, showList) => (state.showList = showList),
    mutateShowDraw: (state, showDraw) => (state.showDraw = showDraw),
    mutateSelectedType: (state, selectedType) => (state.selectedType = selectedType),
    mutateSelectedRegion: (state, selectedRegion) => (state.selectedRegion = selectedRegion),
    mutateLastSelectedRegion: (state, lastSelectedRegion) => (state.lastSelectedRegion = lastSelectedRegion),
    mutateShow: (state) => (state.show = !state.show),
    mutateCurrentFilter: (state, currentFilter) => (state.currentFilter = currentFilter),
    mutateLastFilter: (state, lastFilter) => (state.lastFilter = lastFilter),
    mutateLastSelectedType: (state, lastSelectedType) => (state.lastSelectedType = lastSelectedType)

};

export default {
    state,
    getters,
    actions,
    mutations,
};