import Vue from "vue";
import VueRouter from "vue-router";
import { authGuard } from "../auth/authGuard";

import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";
import Dashboard from '../views/Dashboard.vue';
import Inventory from "../views/Inventory"
import InventoryTable from "../views/inventoryContent/InventoryTable"
import ControlCenter from "../views/ControlCenter"
import UnitList from "../components/controlcenter/UnitList";
import Driver from "../views/Driver.vue";
import NotFound from "../views/errors/NotFound.vue";

// Administration
import AssignmentOverview from "../views/FleetManagement/AssignmentOverview"
import AreaOverview from "../views/FleetManagement/AreaOverview"
import ContainerOverview from "../views/FleetManagement/ContainerOverview"
import DeviceOverview from "../views/FleetManagement/DeviceOverview"
import UserList from "../views/FleetManagement/UserList"
import VehicleList from "../views/FleetManagement/VehicleList"
import CreateNewAssignment from "../views/FleetManagement/CreateNewAssignment"
import ContainerTypeOverview from "../views/FleetManagement/ContainerTypeOverview"
import ContainersLostOverview from "../views/FleetManagement/ContainersLostOverview";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      theme: "map",
    }
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    beforeEnter: authGuard,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "/dashboard/inventory",
    name: "Inventory",
    component: Inventory,
  },
  {
    path: "/dashboard/inventory/table",
    name: "InventoryTable",
    component: InventoryTable,
  },
  
  {
    path: "/controlcenter",
    name: "UnitList",
    component: UnitList,
  },
  {
    path: "/controlcenter/:unitId",
    name: "ControlCenter",
    component: ControlCenter,
  },
  {
    path: "/driver",
    name: "Driver",
    component: Driver
  },
  {
    path: "/fleetmanagement/assignments",
    name: "AssignmentOverview",
    component: AssignmentOverview
  },
  {
    path: "/fleetmanagement/area",
    name: "AreaOverview",
    component: AreaOverview
  },
  {
    path: "/fleetmanagement/containers",
    name: "containerOverview",
    component: ContainerOverview,
    props: true
  },
  {
    path: "/fleetmanagement/containers/types",
    name: "/fleetmanagement/containers",
    component: ContainerTypeOverview,
  },
  {
    path: "/fleetmanagement/devices",
    name: "DeviceOverview",
    component: DeviceOverview
  },
  {
    path: "/fleetmanagement/users",
    name: "UserList",
    component: UserList
  },
  {
    path: "/fleetmanagement/vehicle",
    name: "VehicleList",
    component: VehicleList
  },
  {
    path: "/fleetmanagement/newassignment",
    name: "CreateNewAssignment",
    component: CreateNewAssignment
  },
  {
    path: "/fleetmanagement/containerslost",
    name: "ContainersLostOverview",
    component: ContainersLostOverview
  },
  {
    path: "*",
    name: "404",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;