<template>
  <div class="topbarContainer">
    <SelectLocale class="hover" v-on:language="$emit('language', $event)"></SelectLocale>
    <SelectRegion class="hover" ></SelectRegion>
    <AddWidgetButton class="hover" style="height: 48px" v-on:addWidget="$emit('addWidget', true)"></AddWidgetButton>
    <!-- <MountTypeSelect class="hover"></MountTypeSelect> -->
    <!-- <Notification class="hover"></Notification> -->
  </div>
</template>

<script>
import SelectRegion from "./topbarComponents/SelectRegion.vue";
import SelectLocale from "./topbarComponents/SelectLocale.vue";
// import Notification from "./topbarComponents/Notification.vue";
// import MountTypeSelect from "./topbarComponents/MountTypeSelect.vue"
import AddWidgetButton from "./topbarComponents/AddWidgetButton.vue";

export default {
  components: {
    SelectLocale,
    // Notification,
    AddWidgetButton,
    SelectRegion,
    // MountTypeSelect
  },
};
</script>

<style lang="css" scoped>
.topbarContainer {
  position: fixed;
  min-width: 100vw;
  top: 0px;
  left: 0px;
  height: 90px;
  background-color: #261440;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: white;
  gap: 30px;
  padding: 18px;
}
.hover :hover {
  background-color: #7e5dff;
}
</style>