export const colors = {
    white: "rgb(255, 255, 255)",

    black: "rgba(0, 0, 0, 1)",

    greyXLight: "rgb(230, 230, 230)",
    greyMediumLight: "rgb(151, 151, 151)",
    greyMedium: "rgb(53, 53, 53)",
    greyDark: "rgb(25, 25, 25)",

    pink: "rgb(255, 126, 169)",

    green: "rgba(101, 191, 149, 1)",

    violet: "rgba(38, 20, 64, 1)",
    violetB: "rgba(81, 67, 102, 1)",
    violetC: "rgba(125, 114, 140, 1)",
    violetD: "rgba(168, 161, 179, 1)",
    violetE: "rgba(212, 208, 217, 1)",

    cherry: "rgba(123, 0, 81, 1)",
    cherryB: "rgba(149, 51, 116, 1)",
    cherryC: "rgba(176, 102, 151, 1)",
    cherryD: "rgba(202, 153, 185, 1)",
    cherryE: "rgba(229, 204, 220, 1)",

    sail: "rgba(166, 203, 231, 1)",
    sailB: "rgba(184, 213, 236, 1)",
    sailC: "rgba(202, 224, 241, 1)",
    sailD: "rgba(219, 234, 245, 1)",
    sailE: "rgba(237, 245, 250, 1)",

    yellow: "#FFE893",

    abaxCyan: "#005458",

    reenPurple: "#261440",
    reenPurpleHover: "#211238",
    reenYellow: "FFE893",
    reenGreen: "#65BF95",
    reenWhite: "#F7F8F8",
    reenRed: "#FF6A6A",
    reenPink: "#FF7EA9",
    reenBlack: "black"
}