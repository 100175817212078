<template>
  <div class="selectedField">
      <h5>{{ label }}: </h5>
      <div class="selectDropdown">
          <b-form-select
            class="dropdown"
            v-model="value"
            @change="$emit('input', value)"
            :options="values"         
          >
          </b-form-select>
          <br />
      </div>
  </div>
</template>

<script>

export default {
  name: "SelectComponent",
  components: {
  },
  data() {
    return {
      value: "",
    };
  },

  props: {
    label: String,
    values: Array,
  },
};
</script>

<style scoped>
.bi-question-circle {
  cursor: pointer;
}

.dropdown {
  background-color: #ffffff !important;
  color: black;
}

.selectDropdown {
  position: relative;
  text-align: center;
  width: 100%;
  margin: auto;
}
.selectedField {
  padding-top: 30px;
  width: 100%;
}
.custom-select {
  border: 1px solid #B5B5B5;
  width: 100%;
}
</style>