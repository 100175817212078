<template>
  <WidgetBase :title="$t('vehicles')" class="base">
    <!-- <RegionToggle v-on:status="getVehicleAmount($event)"></RegionToggle> -->

    <Spinner v-if="loading"></Spinner>
    <div v-else>
      <div class="amount">{{ amount }}</div>
      <span @click="$router.push('fleetmanagement/vehicle')">
        <ArrowButton class="button"></ArrowButton>
      </span>
    </div>
  </WidgetBase>
</template>

<script>
import WidgetBase from "./widgetComponents/WidgetBase.vue";
import ArrowButton from "./widgetComponents/ArrowButton.vue";
import { getAllVehicles, getVehicles } from "@/services/reenapi.js";
import Spinner from "./widgetComponents/Spinner";
// import RegionToggle from "./widgetComponents/RegionToggle.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Vehicles",
  components: {
    WidgetBase,
    Spinner,
    ArrowButton,
    // RegionToggle,
  },
  props: {
    options: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["getCurrentRegion"]),
  },
  data() {
    return {
      amount: null,
      allVehicles: [],
      loading: false,
    };
  },
  watch: {
    async getCurrentRegion(newValue) {
      this.loading = true;
      let params = {
        region: newValue,
      };
      console.log(newValue);
      if (newValue == "All") {
        let response = await getVehicles();
        this.allVehicles = response.data;
        this.amount = response.data.length;
      } else {
        let response = await getVehicles(params);
        this.allVehicles = response.data;
        this.amount = response.data.length;
      }
      this.loading = false;
    },
  },
  methods: {
    ...mapActions(["setTableData"]),
    async sendDataToTable() {
      let response = await this.getAll();
      this.setTableData(response);
    },
    async getVehicleAmount(status) {
      this.loading = true;
      if (status == false) {
        let response = await getVehicles();
        this.allVehicles = response.data;
        this.amount = response.data.length;
      } else {
        let params = { region: this.getCurrentRegion };
        let response = await getVehicles(params);
        this.allVehicles = response.data;
        this.amount = response.data.length;
      }
      this.loading = false;
    },
  },

  mounted() {
    this.getVehicleAmount();
  },
};
</script>

<style scoped>
.button {
  float: right;
  margin: 8px 0px 8px 8px;
}
.amount {
  float: left;
  font-size: 5vh;
  position: absolute;
  bottom: 0;
}
</style>
