<template>
  <div>
    <!-- <div id="loginLayout"></div>
    <div
      id="btn"
      @click="loginWithRedirect()"
      :style="getCurrentTheme.login.style"
    >
      <span>{{ $t("login") }}</span>
      <IconComponent
        class="icon"
        :data="getCurrentTheme.login.icon"
      ></IconComponent>
    </div> -->
  </div>
</template>

<script>
import IconComponent from "@/components/icons/Icons.vue";
import { mapGetters } from "vuex";

export default {
  name: "Login",
  components: {
    IconComponent,
  },
  computed: {
    ...mapGetters(["getCurrentTheme"]),
  },
  methods: {
    loginWithRedirect() {
      this.$auth.loginWithRedirect();
    },
  },
  mounted() {
    this.loginWithRedirect();
  },
};
</script>

<style scoped>
#loginLayout {
  opacity: 0.6;
  background-color: rgb(128, 128, 128);
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  z-index: 1000;
}

#btn {
  background-color: rgb(230, 230, 230);
  color: rgb(0, 0, 0);
  font-size: 16px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 25px;
  padding: 13px 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1025;
  cursor: pointer;
  -webkit-box-align: center;
}
.icon {
  padding-left: 18px;
}
</style>