<template>
  <div>
    <div class="addWidgetContainer">
      <div class="addWidget" @click="$emit('addWidget', true)">
        <p class="leftAlign">Add widget</p>
        <p class="rightAlign">+</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.addWidget p {
  font-weight: 100;
  font-size: 0.8rem;
  display: inline-block;
}
.addWidgetContainer {
  height: 48px;
}
.addWidget {
  border-radius: 5px;
  border: 1px solid white;
  white-space: nowrap;
  padding: 8px 16px;
  height: 48px;
  cursor: pointer;
  width: 185px;
}
.rightAlign {
  float: right;
  font-size: 1.2rem !important;
}
</style>