<template>
  <Modal v-if="containerType" :id="id" :headline="containerType.name">
    <b-row>
      <b-col>
        <h5>Hentes med:</h5>
        <b-input disabled :value="containerType.mount_type"></b-input>

        <h5>Volum:</h5>
        <b-input-group append="m³">
          <b-input disabled :value="containerType.volume"></b-input>
        </b-input-group>
      </b-col>

      <b-col>
        <h5>Konstruksjon:</h5>
        <b-input disabled :value="containerType.construction"></b-input>

        <!-- <h5>Avfallstype:</h5>
        <b-input disabled v-model="containerType.use_type"></b-input> -->

        <h5>Vekt:</h5>
        <b-input-group append="kg">
          <b-input disabled :value="containerType.weight"></b-input>
        </b-input-group>
      </b-col>
    </b-row>
  </Modal>
</template>

<script>
import Modal from "./ModalWindow";

export default {
  name: "ContainerTypeModal",

  components: {
    Modal,
  },

  props: {
    id: String,
    containerType: Object,
  },

  methods: {
    getValue(parent, child) {
      if (this.containerType[parent] != undefined) {
        const value = this.containerType[parent][child];

        if (parent === "location" && child === "timestamp") {
          let d = new Date(value);
          return d.toLocaleString();
        }

        return value;
      }

      return null;
    },
  },
};
</script>

<style scoped>
h3 {
  margin-top: 20px;
}

h5 {
  margin-top: 20px;
}
</style>