<template>
  <div>
    <div id="btn" @click="show = !show" v-show="!show">
      <img :src="icon" alt="" id="image" />
    </div>
    <div v-show="show" class="mapOptionsContainer">
      <b-container>
        <b-row class="mapOptionsHeader">
          <b-col>
            <span class="headline">{{ $t(`mapType.title`) }}</span>
            <span @click="show = !show">
              <IconComponent
                class="exitCross"
                :data="getCurrentTheme.closeWindow"
              ></IconComponent>
            </span>
          </b-col>
        </b-row>
        <b-row no-gutters class="optionsRow">
          <span
            v-for="(t, index) in getTerrainTypes"
            :key="index"
            class="optionItem"
          >
            <b-col>
              <img
                class="mapOptionsButton"
                @click="setTerrainType(t)"
                :src="t.icon"
                alt=""
              />
            </b-col>
            <b-col>
              <span class="mapOptionsText">{{ $t(`mapType.${t.key}`) }}</span>
            </b-col>
          </span>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import IconComponent from "@/components/icons/Icons.vue";
import { mapGetters } from "vuex";
import Layer from "@/assets/mapOptions/layer.png";

export default {
  name: "colorSwitch",
  components: {
    IconComponent,
  },
  props: {
    map: Object,
  },
  data() {
    return {
      icon: Layer,
      show: false,
    };
  },
  methods: {
    // ...mapActions(["setTerrainType"]),
    setTerrainType(t) {
      console.log(t);
      this.map.setOptions({
        styles: t.map.style,
        mapTypeId: t.value,
      });
    },
  },
  computed: {
    ...mapGetters(["getCurrentTheme", "getTerrainTypes", "getCurrentType"]),
  },
  mounted() {},
};
</script>

<style lang="css" scoped>
.optionItem {
  margin: auto;
}
.exitCross {
  position: absolute;
  top: -8px;
  right: 10px;
  cursor: pointer;
}
.headline {
  float: left;
}
.mapOptionsButton {
  border-radius: 50%;
  cursor: pointer;
  border: solid rgb(166, 203, 231) 2px;
  box-shadow: 0px 0px 3px 0.5px black;
}
.mapOptionsButton:hover {
  background-color: white;
  transform: scale(1.1);
}
.mapOptionsContainer {
  background-color: #f7f8f8;
  color: black;
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  text-align: center;
  z-index: 5000;
}
.mapOptionsHeader {
  font-size: 20px;
  text-align: center;
  border-bottom: solid gray 2px;
  margin: 5px 0px 5px 0px;
  padding-top: 10px;
}
.mapOptionsText {
  padding: 5px;
  font-size: 14px;
}
#btn:hover {
  cursor: pointer;
}
  #image {
    position: absolute;
    top: 7px;
    right: 7.5px;
  }
#btn {
  width: 40px;
  height: 40px;
  background-color: white;
}
.optionsRow {
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (min-width: 992px) {
  #image {
    position: absolute;
    top: 7px;
    right: 7.5px;
  }
  .optionsRow {
    padding-top: 0;
  }
  .mapOptionsContainer {
    position: fixed;
    bottom: 25px;
    right: 25px;
    border-radius: 25px;
    padding-bottom: 5px;
    text-align: center;
    width: max-content;
    box-shadow: 0px 0px 5px 1px gray;
  }
  .mapOptionsText {
    padding: 5px;
    font-size: 14px;
  }
  .mapOptionsHeader {
    margin-bottom: 10px;
  }
}
</style>