<template>
  <div>
    <b-container class="addContainer">
      <b-row class="header">
        <b-col cols="3" style="padding-right: 0"
          ><h4 class="title">Add Widget</h4></b-col
        >
        <b-col cols="8"
          ><b-input
            class="searchField"
            size="sm"
            placeholder="Search..."
            v-model="filter"
          ></b-input
        ></b-col>
        <b-col
          cols="1"
          class="exitCrossContainer"
          @click="$emit('addWidget', false)"
          ><b-icon-x class="exitCross h2"></b-icon-x
        ></b-col>
      </b-row>

      <b-row class="options">
        <b-col cols="3">
          <b-row
            v-for="(category, index) in getCategories"
            :key="index"
            class="categories"
            ><div class="categoryBtn" @click="selectedCategory = category">
              {{ category }}
            </div></b-row
          >
        </b-col>
        <b-col cols="9" class="optionsContainer">
          <div
            class="optionBox"
            v-for="(option, index) in getCategoryWidgets"
            :key="index"
          >
          <span >
            <b-icon :icon="option.icon" class="h2 optionIcon"></b-icon>
            <h6 class="optionTitle">{{ $t(option.title) }}</h6>
            <p class="optionDescription">{{ option.info }}</p>
            <button class="optionButton" v-if="!isActive(option)" @click="$emit('add', option.i)">Add Widget</button>
            <button class="optionButton" v-else @click="$emit('remove', option.i)">Remove widget</button>
          </span>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";


export default {
  props: {
    userData: {
      type: Object,
    },
  },
  data() {
    return {
      filter: "",
      selectedCategory: "All",
    };
  },
  computed: {
    ...mapGetters(['getWidgets']),
    getCategories() {
      let categories = ['All']
      this.getWidgets.filter(widget => {
        categories.push(widget.category)
      })
      let result = [...new Set(categories)]
      return result
    },
    getCategoryWidgets() {
      if (this.selectedCategory == 'All') return this.getWidgets
      const result = this.getWidgets.filter(widget => widget.category == this.selectedCategory)
      return result
    },

  },
  methods: {
    ...mapActions(['removeWidget', 'addWidget']),
    isActive(widget) {
      return this.userData.widgets.includes(widget.i)
    }   
  },
  mounted () {
    console.log(this.userData)
  },
};
</script>

<style lang="css" scoped>
.addContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  overflow: scroll;
  z-index: 9999;
}
.container {
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 5px 1px gray;
  width: 684px;
  height: 663px;
}
.categories :hover {
  background-color: rgb(224, 224, 224);
}
.categoryBtn {
  border-radius: 5px;
  padding: 3px;
  padding-left: 5px;
  cursor: pointer;
  margin-top: 5px;
  width: 160px;
  margin-left: 15px;
}
.optionsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  padding-left: auto;
}
.optionsContainer :hover {
  background-color: rgb(224, 224, 224);
}
.optionDescription {
  font-size: 0.8rem;
  font-weight: 100;
}
.optionBox {
  width: 206px;
  height: 171px;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
  padding: 10px;
  position: relative;
}
.optionButton {
  background-color: #6349c8 !important;
  color: white;
  border-radius: 5px;
  border: 0;
  position: absolute;
  bottom: 0px;
  left: 50%;
  width: 80%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-weight: 100;
  font-size: 0.8rem;
  height: 26px;
}
.optionIcon {
  color: #6349c8;
  margin-top: 10px;
}
.searchField {
  border: 0;
  background-color: rgb(247, 239, 255);
  width: 80%;
}
.exitCrossContainer {
  text-align: right;
  cursor: pointer;
}
.exitCrossContainer :hover {
  background-color: rgb(224, 224, 224);
}
.options {
  margin-top: 10px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.addContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.addContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>