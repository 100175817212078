<template>
  <div class="inventoryContainer">
    <b-container>
      <b-row>
        <b-row>
          <div class="headline">
            <h1>Tilgjengelige Lager</h1>
          </div>
        </b-row>

        <b-row class="row">
          <b-col
            xl="3"
            sm="8"
            class="col"
            v-for="(item, index) in areas"
            :key="index"
          >
            <b-row class="card-header">
              <div class="title">{{ item.name }}</div>
            </b-row>
            <b-row class="card-address">{{ item.area_type }}</b-row>

            <b-row>
              <b-button pill @click="selectedArea = item" class="btn"
                ><strong>Se Informasjon</strong></b-button
              >
            </b-row>
          </b-col>
        </b-row>
      </b-row>
    </b-container>
    <AreaModal
      v-if="selectedArea"
      :areaObj="selectedArea"
      v-on:close="selectedArea = $event"
      :regions="regions"
      :areaTypes="areaTypes"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  getAreas,
  getOrganizations,
  getRegions,
  getAreaTypes,
} from "../services/reenapi";
import AreaModal from "../components/fleetManagement/modalComponents/AreaModal.vue";

export default {
  components: {
    AreaModal,
  },
  data() {
    return {
      selectedArea: null,
      inventoryData: [],
      loading: true,
      areas: [],
      regions: [],
      areaTypes: [],
    };
  },

  methods: {
    ...mapActions(["setCurrentInventory", "setRegions"]),

    async getOrganizationsFunc() {
      return (await getOrganizations()).data;
    },

    async getRegionsFunc(id) {
      return (await getRegions(id)).data;
    },

    async getAreaTypes() {
      return (await getAreaTypes()).data;
    },

    async returnRegions() {
      let organization = await this.getOrganizationsFunc();
      let regions = await this.getRegionsFunc(organization[0].id);
      return regions;
    },

    login() {
      this.$auth.loginWithRedirect();
    },

    async getAreasFunc() {
      return (await getAreas()).data;
    },

    formatRegionsForBootstrap(regions) {
      let formattedRegions = [];

      for (let region of regions) {
        let regionObj = {
          value: region,
          text: region.name,
        };
        
        formattedRegions.push(regionObj);
      }

      this.setRegions(formattedRegions);
      return formattedRegions;
    },

    formatAreaTypesForBootstrap(areaTypes) {
      let formattedAreaTypes = [];
      for (let area of areaTypes) {
        let areaObj = {
          value: area.value,
          text: area.value,
        };

        formattedAreaTypes.push(areaObj);
      }
      return formattedAreaTypes;
    },

    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },

  async mounted() {
    this.areas = await this.getAreasFunc();
    let regions = await this.returnRegions();
    this.regions = this.formatRegionsForBootstrap(regions);
    let areaTypes = await this.getAreaTypes();
    this.areaTypes = this.formatAreaTypesForBootstrap(areaTypes);
  },
};
</script>

<style scoped>
.card-address {
  font-size: 20px;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
}

.inventoryContainer {
  overflow: scroll !important;
  background: #f7f8f8;
  position: relative;
  height: 100vh;
}

.headline {
  color: black;
  position: relative;
  text-align: center;
  padding: 75px;
  top: 40px;
}

.title {
  font-size: 30px;
  color: #65bf95;
}

/* .loadingIcon {
  position: fixed;
  left: 45%;
  top: 50%;
  width: 10rem;
  height: 10rem;
} */

.exitCross {
  position: absolute;
  top: 8px;
  right: 10px;
  cursor: pointer;
  color: white !important;
}

.inventoryContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.inventoryContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-flow: wrap;
}

.col {
  background: #f7f8f8;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid grey;
  transition: 0.3s;
  margin: 10px;
  height: 300px;
  color: black;
  border: none;
}

.col:hover {
  transform: scale(1.05);
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.25);
  border: 3px solid #65bf95;
}

.card-header {
  text-align: center;
  padding: 40px 10px;
  color: black;
  border: none;
  background: #f7f8f8;
}

.card-header-text {
  color: black;
  text-align: center;
  font-size: 20px;
  height: 50px;
}

.card-body .btn {
  display: block;
  color: black;
  text-align: center;
  background-color: #65bf95;
  margin-top: 30px;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 20px;
}

.btn {
  height: 60px !important;
  width: 55% !important;
  position: absolute;
  bottom: 20px;
  background-color: #65bf95;
  font-size: 16px;
  vertical-align: middle;
  padding-top: 15px !important;
  margin-bottom: 12px;
  box-shadow: 2px 3px 15px 1px rgba(0, 0, 0, 0.2);
  border: none;
}

.btn:hover {
  background-color: #7dddb0;
}

.col:hover {
  transform: scale(1.05);
  box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.25);
}
</style>