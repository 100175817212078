<template>
  <div>
    <ContainerUnloaded
      class="unloadedSuccess"
      v-if="isUnloaded"
      @change="$emit('change', $event), (isUnloaded = false)"
    ></ContainerUnloaded>
    <b-container>
      <b-col>
        <div class="row-element">
          <b-row>
            <b-col>
              <!-- Dette feltet skal enten vise container
              hvis skannet, eller sjåfør skal velge container
              fra oppdraget (dropdown?) -->
              <h5>Velg container:</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-input type="text"></b-form-input>
            </b-col>
          </b-row>
        </div>

        <b-row>
          <b-col>
            <Select
              label="Type tømming"
              v-on:input="type = $event"
              :values="unloadValues"
            />
          </b-col>
        </b-row>

        <div class="row-element">
          <b-row>
            <b-col>
              <h5>Tømmedato:</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-input type="date"></b-form-input>
            </b-col>
          </b-row>
        </div>

        <div class="row-element">
          <b-row>
            <b-col>
              <h5>Volum:</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-input type="number"></b-form-input>
            </b-col>
          </b-row>
        </div>

        <div class="row-element">
          <b-row>
            <b-col>
              <h5>Høydeforskjell:</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-input type="number"></b-form-input>
            </b-col>
          </b-row>
        </div>

        <div class="row-element">
          <b-row>
            <b-col>
              <h5>Kommentar:</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-input type="text"></b-form-input>
            </b-col>
          </b-row>
        </div>

        <div class="row-element">
          <b-row class="text-center">
            <b-col>
              <div class="buttonContainer">
                <button @click="$emit('change', 'RegisterDeviations')">
                  Registrer avvik
                </button>
              </div>
            </b-col>
          </b-row>
        </div>

        <div class="row-element">
          <b-row class="text-center">
            <b-col class="buttonContainer">
              <button class="mr-5" @click="$emit('change', 'Assignment')">
                Avbryt
              </button>
              <button @click="isUnloaded = true">Lagre</button>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-container>
  </div>
</template>

<script>
import Select from "../driver/subComponents/SelectInput";
import ContainerUnloaded from "./subComponents/ContainerUnloaded";

export default {
  components: {
    Select,
    ContainerUnloaded,
  },
  data() {
    return {
      isUnloaded: false,
      unloadValues: ["Hent container", "Tøm container", "Slamtømming"],
    };
  },
};
</script>

<style scoped>
.container {
  color: black;
  display: flex;
  justify-content: center;
  margin-top: 70px;
  padding: 3%;
  position: relative;
}
.unloadedSuccess {
  z-index: 1000;
}
.row-element {
  margin-top: 20px;
}
button {
  margin: 5px;
  background-color: #65bf95;
  box-shadow: 2px 2px 4px #b5b5b5;
  color: #000000;
  border: 0px;
  border-radius: 10px;
  padding: 5px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 600;
  /* letter-spacing: 1px; */
  /* font-size: 1.125em; */
  /* text-shadow: 0px 0px 4px #000000a6; */
}
.buttonContainer :hover {
  background-color: #7dddb0;
}
</style>
