<template>
  <div class="divider"></div>
</template>

<script>
export default {

}
</script>

<style scoped>
.divider {
  border-bottom: 1px solid #65bf95;
  margin-top: 15px;
  margin-bottom: 25px;
}
</style>