import * as theme from "../../components/theme/theme";
// import * as mapTheme from "../../components/theme/mapTheme";

const state = {
  darkTheme: theme.darkTheme,
  lightTheme: theme.lightTheme,
  currentTheme: theme.lightTheme,

  // By swapping this to false or true you can change the default theme
  darkmode: false,
};

const getters = {
  getCurrentTheme() {
    if (state.darkmode) return state.darkTheme;
    else return state.lightTheme;
  },
  // getCurrentGoogleStyle() {
  //   if (state.darkmode) return mapTheme.DarkGoogleMap();
  //   else return mapTheme.LightGoogleMap();
  // }
};

const actions = {
  swapTheme({
    commit
  }) {
    commit("mutateMode");
  },
};

const mutations = {
  mutateMode: (state) => (state.darkmode = !state.darkmode),
};

export default {
  state,
  getters,
  actions,
  mutations,
};