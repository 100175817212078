import Dark from "@/assets/mapOptions/dark.png"
import Default from "@/assets/mapOptions/default.png"
import Satellite from "@/assets/mapOptions/satellite.png"
import Terrain from "@/assets/mapOptions/terrain.png"

import * as mapTheme from "../../components/theme/mapTheme";


const state = {
    terrainTypes: [

        {
            name: "Terreng",
            value: "terrain",
            icon: Terrain,
            map: mapTheme.LightGoogleMap(),
            key: "terrain"
        },
        {
            name: "Satelitt",
            value: "satellite",
            icon: Satellite,
            map: mapTheme.LightGoogleMap(),
            key: "satellite"


        },
        {
            name: "Veikart",
            value: "roadmap",
            icon: Default,
            map: mapTheme.LightGoogleMap(),
            key: "roadmap"


        },
        {
            name: "Mørk",
            value: "roadmap",
            icon: Dark,
            map: mapTheme.DarkGoogleMap(),
            key: "dark"

        }
    ],
    chosenType: {
        name: "Satelitt",
        value: "satellite",
        icon: Satellite,
        map: mapTheme.LightGoogleMap()
    }
};

const getters = {
    getCurrentType: (state) => state.chosenType,
    getTerrainTypes: (state) => state.terrainTypes,
    getCurrentGoogleStyle() {
        if (state.darkmode) return mapTheme.DarkGoogleMap();
        else return mapTheme.LightGoogleMap();
    }
};

const actions = {
    setTerrainType({
        commit
    }, variable) {
        const response = variable;
        commit("mutateTerrain", response)
        console.log(state.chosenType)
    },
};

const mutations = {
    mutateTerrain: (state, chosenType) => (state.chosenType = chosenType)
};

export default {
    state,
    getters,
    actions,
    mutations,
};