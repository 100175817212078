<template>
  <div>
    <b-row>
      <b-col>
        <h1>{{ title }}</h1>
      </b-col>
      
      <b-col class="right-align">
        <slot />
      </b-col>
    </b-row>

    <Divider />
  </div>
</template>

<script>
import Divider from "../fleetManagement/Divider";

export default {
  name: "PageTitle",
  props: {
    title: String,
  },
  components: {
    Divider,
  },
};
</script>

<style scoped>
.right-align {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>