<template>
  <b-container>
    <PageTitle :title="$t('containers')" />

    <List
      :labels="labels"
      :valueFunc="getContainersFunc"
      @rowClicked="showContainerModal"
      defaultSort="qr_qode"
      :key="listKey"
    />

    <ContainerModal
      id="container-modal"
      :container="containerShowedInModal"
      @update="listKey = listKey + 1"
    />
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { GetContainerAmount, getContainers } from "../../services/reenapi.js";

import PageTitle from "../../components/common/PageTitle";
import List from "../../components/common/List";
import ContainerModal from "../../components/fleetManagement/modalComponents/ContainerModal";
import { extractOrganizationId } from '../../services/utils.js';

export default {
  components: {
    PageTitle,
    List,
    ContainerModal,
  },
  props: {
    containerClicked: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      containerShowedInModal: {},
      listKey: 0,
    };
  },

  computed: {
    labels() {
      return [
        // Om den kan filtreres på i lista styres av backend.
        // F.eks. søk er ikke implementert for .alias
        // { name: "Alias", value: "alias", filterable: false },
        {
          name: this.$t("qr"),
          value: "qr_code",
          filterable: true,
        },
        {
          name: this.$t("type"),
          value: "container_type.name",
          filterable: true,
        },
        {
          name: this.$t("unitSerialNumber"),
          value: "serial_number",
          filterable: true,
        },
        // {
        //   name: this.$t("organization"),
        //   value: "organization_id",
        //   filterable: true,
        // },
        {
          name: this.$t("region"),
          value: "region.name",
          filterable: true,
        },
      ];
    },
  },

  methods: {
    ...mapActions([
      "cacheContainerStates",
      "cacheContainerTypes",
      "cacheOrganizations",
      "cacheRegions",
    ]),

    async showContainerModal(container) {
      this.containerShowedInModal = {};
      this.containerShowedInModal = container;
      await this.cacheRegions(extractOrganizationId(container));
      this.$bvModal.show("container-modal");
    },

    async getContainersFunc(params) {
      return {
        data: (await getContainers(params)).data,
        totalCount: (await GetContainerAmount(params)).data,
      };
    },
  },

  async mounted() {
    await this.cacheContainerStates();
    await this.cacheContainerTypes();
    await this.cacheOrganizations();
    if (this.containerClicked) await this.showContainerModal(this.containerClicked)
  },
};
</script>
