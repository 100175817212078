<template>
  <div class="controlcenter">
    <b-container>
      <h1>{{ unitId }}</h1>
      <DashboardGrid :layout="modules" :unitId="unitId">
        <component
          v-for="module of modules"
          :key="module.name"
          :is="module.name"
          :layout="module"
          :options="{ unitId }"
        />
      </DashboardGrid>
    </b-container>
  </div>
</template>

<script>
import DashboardGrid from "@/components/dashboard/Dashboard";
import Distance from "@/components/controlcenter/modules/Distance";

export default {
  name: "ControlCenter",
  components: {
    DashboardGrid,
    Distance,
  },
  data() {
    return {
      modules: [
        {
          name: "Distance",
          x: 0,
          y: 0,
          w: 1,
          h: 1,
        },
      ],
    };
  },
  created() {
    this.unitId = this.$route.params.unitId;
  },
};
</script>
