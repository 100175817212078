<template>
  <div class="userModalContainer">
    <b-row>
      <b-col>
        <h3>{{$t('userModal.title')}}</h3>
      </b-col>
      <b-col>
        <ButtonGroup v-on:click="saveOrDelete($event)"></ButtonGroup>
      </b-col>
    </b-row>

    <b-form>
      <b-row>
        <b-col>
          <b-col>
            <b-form-group  id="input-group-1" :label="$t('name')" label-for="input-1">
              <b-form-input
                id="input-1"
                v-model="userObj.name"
                type="text"
                :placeholder="userObj.name"
                required                
              ></b-form-input
            ></b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="input-group-2" :label="$t('email')" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="userObj.email"
                type="text"
                :placeholder="userObj.email"
                required
              ></b-form-input
            ></b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="input-group-3" :label="$t('role')" label-for="input-3">
               <b-form-input
                id="input-3"
                type="text"
                :placeholder="userObj.app_metadata.roles[0]"
                v-if="userObj.app_metadata"
                v-model="userObj.app_metadata.roles[0]"
                required
              ></b-form-input
            ></b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-4"
              :label="$t('number')"
              label-for="input-4"
            >
              <b-form-input
                id="input-4"
                v-model="userObj.phone_number"
                type="text"
                :placeholder="userObj.phone_number"
                required
              ></b-form-input
            ></b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              id="input-group-5"
              :label="$t('userModal.lastLogin')"
              label-for="input-5"
            >
              <b-form-input
                disabled
                id="input-5"
                v-model="userObj.last_login"
                type="text"
                :placeholder="userObj.last_login"
                required
              ></b-form-input
            ></b-form-group>
          </b-col>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { putUser, deleteUser } from "../../../services/reenapi";

import ButtonGroup from "../buttons/ButtonGroup.vue";

export default {
  components: {
    ButtonGroup,
  },

  props: {
    userObj: Object,
  },

  methods: {
    async saveOrDelete(event) {
      console.log(event);
      if (event == "save") {
        this.userObj.id = this.userObj.user_id;

        let response = await putUser(this.userObj);

        console.log(response);
        location.reload();
      }
      if (event == null) this.$emit("close", null);
      if (event == "delete") {
        let response = await deleteUser(this.userObj.user_id);
        console.log(response);
        location.reload();
      }
    },
  },
  mounted() {
  }
};
</script>

<style scoped>
h5 {
  margin-top: 20px;
}

h3 {
  margin-top: 20px;
}

.userModalContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 25px;
  border-radius: 25px;
  width: 70vw;
  box-shadow: 0px 0px 5px 0.3px black;
  max-height: 80vh;
  overflow: hidden;
}


</style>