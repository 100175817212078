<template>
  <div>
    <!-- Initializes the map, and once that's done, updates the map object reference, so we can pass that to child components -->
    <MapInitializer
      v-on:map="map = $event"
      :mapStyle="getCurrentType"
      :center="currentLocation.coords"
      :zoom="15"
    ></MapInitializer>

    <!-- This is what allows the user to type in a place, and then be dragged to that location -->
    <Autocomplete
      v-if="map"
      :map="map"
      mapType="googleMap"
      :loading="loading"
    ></Autocomplete>

    <!-- Places the markers on the map, and adds them to the cluster -->
    <div v-if="map && oms && cluster">
      <Markers
        :map="map"
        :data="currentMarkers"
        :oms="oms"
        :cluster="cluster"
        :key="currentMarkers.containers.length"
      ></Markers>
    </div>

    <!-- Collects data from the API, then emits it -->
    <DataGetter
      v-if="map"
      :map="map"
      v-on:dataCollected="combinedData = $event"
      :center="currentLocation.coords"
      mapType="googleMap"
      v-on:loading="loading = $event"
    ></DataGetter>

    <GooglePolygon
      class="polygon"
      v-if="map && polyCoords.length >= 1"
      :map="map"
      :coordinates="polyCoords"
      :key="polyCoords.length"
      v-on:polygonCreated="polyCoords = $event"
    ></GooglePolygon>

    <Directions v-if="map" :map="map"></Directions>

    <TableComponent
      :tableData="currentMarkers.containers"
      v-if="getShowList"
    ></TableComponent>

    <AreasList
      :coords="polyCoords"
      :map="map"
      v-if="map"
      v-on:clicked="makePolygon($event)"
    ></AreasList>

    <!-- Allows the user to change the styling of the map -->
    <MapStyleSwitch
      class="mapStyleSwitch"
      v-if="map"
      :map="map"
    ></MapStyleSwitch>
  </div>
</template>

<script>
import DataGetter from "../universalComponents/DataGetter.vue";
import GooglePolygon from "./components/GooglePolygon";
import Autocomplete from "../universalComponents/Autocomplete.vue";
import MapInitializer from "./components/MapInitializer";
import Markers from "./components/Marker";
import MapStyleSwitch from "./components/MapStyleSwitch";
import Directions from "./components/Directions";
import AreasList from "./components/area/AreasList";
import TableComponent from "./../../infoCards/Table";
import { mapGetters } from "vuex";

export default {
  props: {
    currentLocation: navigator.GeolocationPosition,
  },
  components: {
    Autocomplete,
    MapStyleSwitch,
    DataGetter,
    MapInitializer,
    Markers,
    GooglePolygon,
    Directions,
    AreasList,
    TableComponent,
  },
  data() {
    return {
      loading: false,
      oms: "",
      map: "",
      cluster: {},
      // Initial radius for the first call to the API
      radius: 2000,

      // The data from the API
      combinedData: {
        vehicles: [],
        containers: [],
      },
      // Existing data on the map
      currentMapStyle: "",
      currentBounds: { lat: 59.05328, lng: 10.03517 },
      place: "",
      polyCoords: [],
      location: null,
    };
  },
  computed: {
    ...mapGetters([
      "getCurrentType",
      "getShowDraw",
      "getShowList",
      "getCurrentFilter",
      "getSelectedType",
      "getSelectedRegion"
    ]),
    currentMarkers() {
      // Create markers object
      let markers = {
        containers: [],
        vehicles: [],
      };

      if (this.getSelectedType == "sensor") {
        for (var container of this.combinedData.containers) {
          if (container.fill_depth) markers.containers.push(container);
        }
      }
      // Loop through the types of items
      for (var type in this.combinedData) {
        // loop through the items
        if (this.getSelectedType == null || this.getSelectedType == type) {
          for (var item of this.combinedData[type]) {
            // If an area is drawn, this will return only items inside the area
            // if no area is drawn, returns everything
            var inArea = this.withinBounds(item);
            let inRegion = true
            if (this.getSelectedRegion && item.region) {
              if (item.region.name == this.getSelectedRegion) inRegion = true
              else inRegion = false
            }
            // Because some containers for some reason doesn't have a container type. Go figure
            if (item.container_type) {
              // Check if the item is within the paramaters of the current filter
              let inCurrentFilter = this.filterMarker(
                this.getCurrentFilter,
                item.container_type
              );
              // Render the marker if the item is both in the current filter and inside the drawn area
              if (inArea == true && inCurrentFilter && inRegion) {
                markers[type].push(item);
              }
            } else {
              // This is for vehicles, does same as above
              if (inArea) {
                markers[type].push(item);
              }
            }
          }
        }
      }
      console.log(markers.length)
      return markers;
    },
  },
  methods: {
    filterMarker(arg, item) {
      // This function takes the current filter option and the current item to be rendered as a marker as arguments
      // It then checks if the filtered value exists in the item, if it does not, it will not render
      if (arg == null) return true;
      if (item[arg.index] == arg.item) return true;
      else return false;
    },
    initSpiderifier() {
      // Initializes the OMS
      // This is what allows the markers to spread out
      this.oms = new window.OverlappingMarkerSpiderfier(this.map, {
        markersWontMove: true,
        markersWontHide: true,
        basicFormatEvents: true,
      });
    },
    addClickEventListener() {
      // This is required so that the user can create custom areas.
      this.map.addListener(
        "click",
        (e) => {
          let newLoc = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
          };
          if (this.getShowDraw == true) this.polyCoords.push(newLoc);
        },
        {
          passive: true,
        }
      );
    },
    // Initalizes the cluster.
    // The markers are added to the cluster in the marker component
    initCluster() {
      this.cluster = new window.MarkerClusterer(this.map, [], {
        imagePath:
          "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        maxZoom: 19,
      });
    },
    withinBounds(item) {
      if (this.polyCoords.length >= 3) {
        var latLng = {
          lat: item.location.latitude,
          lng: item.location.longitude,
        };
        let square = new window.google.maps.Polygon({
          paths: this.polyCoords,
        });
        return window.google.maps.geometry.poly.containsLocation(
          latLng,
          square
        );
      } else return true;
    },
    makePolygon(data) {
      let coords = [];

      for (let item of data.coordinates) {
        let coordObj = {
          lat: item.y,
          lng: item.x,
        };

        coords.push(coordObj);
      }

      this.polyCoords = coords;
    },
  },
  async mounted() {
    this.initSpiderifier();
    this.initCluster();
    this.addClickEventListener();
  },
};
</script>

<style scoped>
#map {
  height: calc(100vh - 60px) !important;
  width: 100vw !important;
  position: absolute !important;
  top: 60px !important;
  overflow: hidden !important;
}
.mapStyleSwitch {
  position: fixed;
  bottom: 75px;
  right: 10px;
}
.polygon {
  position: fixed;
  bottom: 0px;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
}
</style>