<template>
  <Modal headline="Oppdragsdetaljer">
    <b-row>
      <b-col>
        <h3>Opplysninger om oppdraget</h3>
        <h5>Oppdragsnavn:</h5>
        <b-input v-model="taskName"></b-input>
        <h5>Område:</h5>
        <b-input v-model="rodeName"></b-input>
        <Select label="Status" />
        <Select label="Ansvarlig" />
        <Select label="Type" />
        <Select label="Kjøretøy" />
        <h5>Beskrivelse</h5>
        <b-form-input type="text" v-model="descriptionText"></b-form-input>
      </b-col>
      <b-col>
        <h3>Visning i kart</h3>
      </b-col>
    </b-row>

    <Collapse collapseId="container" headline="Containere" :isVisible="false">
      <b-row class="listStyle headlineStyle">
        <b-col v-for="(headline, index) in headlines" :key="index">
          <li>{{ headline }}</li>
        </b-col>
      </b-row>
      <ul>
        <b-row
          class="listStyle"
          v-for="(item, index) of containerList"
          :key="index"
        >
          <b-col v-for="(colItem, index) in item" :key="index">
            <li>{{ colItem }}</li>
          </b-col>
        </b-row>
      </ul>
    </Collapse>
  </Modal>
</template>

<script>
import Modal from "../modalComponents/ModalWindow";
import Select from "../../driver/subComponents/SelectInput";
import Collapse from "../CollapseComponent";

export default {
  components: {
    Modal,
    Select,
    Collapse,
  },
  data() {
    return {
      taskName: "",
      rodeName: "",
      descriptionText: "",
      headlines: [
        "QR-kode",
        "Tilstand",
        "Containertype",
        "Avfallstype",
        "Volum",
        "Vekt",
        "Fyllingsgrad",
      ],
      containerList: [
        {
          qr: "ABCDEF",
          state: "OK",
          type: "C-10L",
          useType: "Glass",
          volume: "4 m3",
          weight: "123kg",
          fillDepth: "43%",
        },
        {
          qr: "GHIJKL",
          state: "OK",
          type: "C-10L",
          useType: "Glass",
          volume: "4 m3",
          weight: "123kg",
          fillDepth: "43%",
        },
        {
          qr: "MNOPQR",
          state: "OK",
          type: "C-10L",
          useType: "Glass",
          volume: "4 m3",
          weight: "123kg",
          fillDepth: "43%",
        },
      ],
    };
  },
};
</script>

<style scoped>
h3 {
  margin-top: 20px;
}
h5 {
  margin-top: 20px;
}
.headlineStyle {
  padding: 0.375rem 0rem 0.375rem 0.75rem !important;
  margin: 0 !important;
  font-weight: 600;
  width: 100%;
  border-bottom: none !important;
}
.listStyle {
  border-bottom: 1px solid #b5b5b5;
  padding: 0.375rem 0rem 0.375rem 0.75rem;
  margin: 0;
}
.listStyle:last-child {
  border-bottom: none;
}
ul {
  border-radius: 0.25rem;
  border: 1px solid #b5b5b5;
  background-color: white;
  width: 100%;
  padding-left: 0;
}
li {
  list-style-type: none;
}
</style>