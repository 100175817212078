import {
    getAreaTypes,
    getContainerStates,
    getContainerTypes,
    getOrganizations,
    getRegions,
} from "../../services/reenapi";

import { sortByKey } from "../../services/utils";

const state = {
    areaTypes: [],
    containerStates: [],
    containerTypes: [],
    organizations: [],
    regions: [],
};

const actions = {
    async cacheAreaTypes({ commit }) {
        const response = await getAreaTypes();
        const payload = response.data.map(o => {
            return {
                text: o.value,
                value: o.value,
                ...o,
            }
        });
        commit("areaTypes", sortByKey(payload, "text"));
    },

    async cacheContainerStates({ commit }) {
        const response = await getContainerStates();
        const payload = response.data.condition.map(o => {
            return {
                text: o.value,
                value: o.value,
                ...o,
            }
        });
        commit("containerStates", sortByKey(payload, "text"));
    },

    async cacheContainerTypes({ commit }) {
        const response = await getContainerTypes();
        const payload = response.data.map(o => {
            return {
                text: o.name,
                value: o.id,
                ...o,
            }
        });
        commit("containerTypes", sortByKey(payload, "text"));
    },

    async cacheOrganizations({ commit }) {
        const response = await getOrganizations();
        let payload = [];
        let o = response.data;
        for (let i = 0; i < o.length; i++) {
            payload = [
                ...payload,
                {
                    text: o[i].name,
                    value: o[i].id,
                    ...o[i]
                },
            ]
        }
        commit("organizations", sortByKey(payload, "text"));
    },

    async cacheRegions({ commit }, organization_id) {
        const response = await getRegions(organization_id);
        const payload = response.data.map(o => {
            return {
                text: o.name,
                value: o.id,
                ...o,
            }
        });
        commit("regions", sortByKey(payload, "text"));
    }
};

const getters = {
    containerStates: (state) => state.containerStates,
    containerTypes: (state) => state.containerTypes,
    areaTypes: (state) => state.areaTypes,
    organizations: (state) => state.organizations,
    regions: (state) => state.regions,
};

const mutations = {
    containerStates: (state, value) => state.containerStates = value,
    containerTypes: (state, value) => state.containerTypes = value,
    areaTypes: (state, value) => state.areaTypes = value,
    organizations: (state, value) => state.organizations = value,
    regions: (state, value) => state.regions = value,
};

export default {
    state,
    getters,
    actions,
    mutations,
};