<template>
  <div>
    <keep-alive>
      <component
        :is="currentComponent"
        v-on:change="currentComponent = $event"
      />
    </keep-alive>
  </div>
</template>

<script>
import AssignmentsList from "../components/driver/AssignmentsList";
import Assignment from "../components/driver/Assignment";
import UserSettings from "../components/driver/UserSettings";
import UnloadContainer from "../components/driver/UnloadContainer";
import RegisterDeviations from "../components/driver/RegisterDeviations";
// import UnloadedContainer from "../components/driver/subComponents/ContainerUnloaded";

export default {
  name: "Driver",
  components: {
    AssignmentsList,
    Assignment,
    UserSettings,
    UnloadContainer,
    RegisterDeviations,
    // UnloadedContainer
  },
  data() {
    return {
      currentComponent: "AssignmentsList",
    };
  },
};
</script>

<style scoped>
</style>
