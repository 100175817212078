<template>
  <div class="mapContainer">
    <!-- Initializes the map, and once that's done, updates the map object reference, so we can pass that to child components -->
    <MapInitializer
      v-on:map="map = $event"
      :mapStyle="getCurrentType"
      :center="currentLocation.coords"
      :zoom="15"
    ></MapInitializer>

    <!-- Places the markers on the map, and adds them to the cluster -->
    <div v-if="map && oms && cluster">
      <Markers
        :map="map"
        :data="combinedData"
        :oms="oms"
        :cluster="cluster"
        :key="combinedData.containers.length"
      ></Markers>
    </div>

    <!-- Collects data from the API, then emits it -->
    <DataGetter
      v-if="map"
      :map="map"
      v-on:dataCollected="combinedData = $event"
      :center="currentLocation.coords"
      mapType="googleMap"
      v-on:loading="loading = $event"
    ></DataGetter>
  </div>
</template>

<script>
import DataGetter from "@/components/map/universalComponents/DataGetter.vue";
import MapInitializer from "./mapComponents/MapInitializer.vue";
import Markers from "./mapComponents/Marker";
import { mapGetters } from "vuex";

export default {
  props: {
    currentLocation: navigator.GeolocationPosition,
  },
  components: {
    DataGetter,
    MapInitializer,
    Markers,
  },
  data() {
    return {
      oms: "",
      map: "",
      cluster: {},
      // Initial radius for the first call to the API
      radius: 2000,

      // The data from the API
      combinedData: {
        vehicles: [],
        containers: [],
      },
      // Existing data on the map
      currentMapStyle: "",
      currentBounds: { lat: 59.05328, lng: 10.03517 },
      location: null,
    };
  },
  computed: {
    ...mapGetters(["getCurrentType"]),
  },
  methods: {
    initSpiderifier() {
      // Initializes the OMS
      // This is what allows the markers to spread out
      this.oms = new window.OverlappingMarkerSpiderfier(this.map, {
        markersWontMove: true,
        markersWontHide: true,
        basicFormatEvents: true,
      });
    },
    // Initalizes the cluster.
    // The markers are added to the cluster in the marker component
    initCluster() {
      this.cluster = new window.MarkerClusterer(this.map, [], {
        imagePath:
          "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        maxZoom: 19,
      });
    },
  },
  async mounted() {
    this.initSpiderifier();
    this.initCluster();
  },
};
</script>

<style scoped>
.mapContainer {
    height: 100%;
    width: 100%;
}
</style>