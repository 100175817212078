  <div class="chart"></div>

<script>
import { Bar } from "vue-chartjs";
export default {
  extends: Bar,
  props: ["chartData", "chartOptions"],
  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  },
};
</script>

<style scoped>
#bar-chart {
  max-height: 400px;
  font-size: 3rem;
  padding-bottom: 50px;
}
</style>