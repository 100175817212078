const state = {
    areas: {},
    showAreaList: false,
    chosenArea: ""

};

const getters = {
    getChosenArea: (state) => state.chosenArea,
    getAreas: (state) => state.area,
    getShowAreaList: (state) => state.showAreaList
};

const actions = {
    setAreas({
        commit
    }, variable) {
        const response = variable;
        commit("mutateAreas", response)
    },
    setShowAreaList({
        commit
    }, variable) {
        const response = variable
        commit("mutateShowAreaList", response)
    },
    setChosenArea({
        commit
    }, variable) {
        const response = variable;
        commit("mutateChosenArea", response)
    }

};

const mutations = {
    mutateAreas: (state, area) => (state.area = area),
    mutateShowAreaList: (state, showAreaList) => (state.showAreaList = showAreaList),
    mutateChosenArea: (state, chosenArea) => (state.chosenArea = chosenArea)
};

export default {
    state,
    getters,
    actions,
    mutations,
};