<template>
  <div class="componentContainer">
    <select
      v-model="$i18n.locale"
      class="selectBox"
      @change="$emit('language', $i18n.locale)"
    >
      <option
        v-for="(lang, index) in langs"
        :key="index"
        :value="lang.value"
        class="option"
      >
        {{ lang.text }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "SelectLocale",
  data() {
    return {
      langs: [
        {
          value: "en",
          text: "🇬🇧 English",
        },
        {
          value: "no",
          text: "🇳🇴 Norsk",
        },
      ],
    };
  },
};
</script>


<style scoped>
.selectBox {
  color: white;
  background-color: #261440;
  height: 48px;
  width: 150px;
  border: 1px solid white;
  border-radius: 5px;
  padding: 8px 16px;
  font-weight: 100;
  font-size: 0.9rem;
}
.option {
  font-weight: 100;
}
</style>