<template>
  <WidgetBase class="base" :title="$t(item.title)">
    <!-- <RegionToggle v-on:status="getAmountOfContainers($event)"></RegionToggle> -->

    <Spinner v-if="loading"></Spinner>
    <div v-else>
      <h1 class="amount">{{ lostContainers.length }}</h1>
      <span @click="$router.push('fleetmanagement/containerslost')">
       <ArrowButton class="button"></ArrowButton>
      </span>
    </div>
  </WidgetBase>
</template>

<script>
import WidgetBase from "./widgetComponents/WidgetBase.vue";
import ArrowButton from "./widgetComponents/ArrowButton.vue";
// import RegionToggle from "./widgetComponents/RegionToggle.vue";
import { GetLostContainers } from "@/services/reenapi.js";
import Spinner from "./widgetComponents/Spinner";
import { mapGetters } from "vuex";

export default {
  name: "ContainerTotal",
  components: {
    WidgetBase,
    Spinner,
    ArrowButton,
    // RegionToggle,
  },
  props: {
    item: {
      type: Object,
      required: false,
    },
    item: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["getCurrentRegion"]),
  },
  data() {
    return {
      amount: null,
      loading: false,
      lostContainers: [],
    };
  },
  watch: {
    async getCurrentRegion(newValue) {
      this.loading = true;
      let params = {
        region: newValue,
      };
      console.log(newValue);
      if (newValue == "All") {
        let response = await GetLostContainers();
        this.lostContainers = response.data;
      } else {
        let response = await GetLostContainers(params);
        this.lostContainers = response.data;
      }
      this.loading = false;
    },
  },
  methods: {
    async getAmountOfContainers(status) {
      this.loading = true;
      if (status == false || status == undefined) {
        let response = await GetLostContainers();
        this.lostContainers = response.data;
      } else {
        let params = {
          region: this.currentRegion,
        };
        let response = await GetLostContainers(params);
        this.lostContainers = response.data;
      }
      this.loading = false;
    },
  },

  mounted() {
    this.getAmountOfContainers();
  },
};
</script>

<style scoped>
.base {
  position: relative;
}
.button {
  float: right;
  margin: 8px 0px 8px 8px;
}
.amount {
  float: left;
  font-size: 5vh;
  position: absolute;
  bottom: 0;
}
</style>
