<template>
  <b-container>
    <PageTitle :title="$t('deviceOverview.title')" />

    <List :labels="labels" :valueFunc="getUnitsFunc" />
  </b-container>
</template>

<script>
import { GetContainerAmount, getUnits } from "../../services/reenapi";

import PageTitle from "../../components/common/PageTitle";
import List from "../../components/common/List";

export default {
  components: {
    PageTitle,
    List,
  },

  computed: {
    labels() {
      return [
        {
          name: "Type",
          value: "type",
          filterable: true,
          filter_type: "text",
        },
        {
          name: "Serienr",
          value: "serial_number",
          filterable: true,
          filter_type: "text",
        },
        {
          name: "Helse",
          value: "health",
          filterable: true,
          filter_type: "select",
        },
        {
          name: "Aktivert",
          value: "status",
          filterable: true,
          filter_type: "select",
        },
      ];
    },
  },

  methods: {
    async getUnitsFunc(params) {
      let units = (await getUnits(params)).data;
      let newList = [];
      for (var unit in units) {
        let singleUnit = units[unit];
        if (singleUnit.serial_number != null) {
          newList.push(singleUnit);
        }
      }
      return {
        data: newList,
        totalCount: -1,
      }
    },
  },
};
</script>
