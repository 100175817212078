const state = {
    assignmentInEdit: {
        name: "",
        area: {}, // "Rode"
        type: "",
        containers: [],
        state: "",
        assignedTo: "",
        description: "",
    },
};

const getters = {};

const actions = {
    doesIncludeContainer({ state }, container) {
        let containers = state.assignmentInEdit.containers.map(c => c.qr_code);
        console.log(container.qr_code, containers);
        return containers.includes(container.qr_code);
    }
};

const mutations = {
    addContainer: (state, container) => state.assignmentInEdit.containers.push(container),
    removeContainer: (state, container) => {
        const indexToRemove = state.assignmentInEdit.containers.map(c => c.qr_code).indexOf(container.qr_code);
        console.log("removed", container.qr_code);
        return state.assignmentInEdit.containers.splice(indexToRemove, 1);

        // function objectsAreEqual(o1, o2) {
        //     for (const key of Object.keys(o1)) {
        //         if (o1[key] !== o2[key]) {
        //             return false;
        //         }
        //     }

        //     for (const key of Object.keys(o2)) {
        //         if (o2[key] !== o1[key]) {
        //             return false;
        //         }
        //     }

        //     return true;
        // }

        // for (const [index, [, item]] of Object.entries(
        //     Object.entries(state.assignmentInEdit.containers)
        // )) {
        //     if (objectsAreEqual(item, container)) {
        //         this.checkedItems.splice(index, 1);
        //     }
        // }

        // return state.assignmentInEdit.containers.push(container)
    },
    setType: (state, type) => state.assignmentInEdit.type = type,
    setName: (state, name) => state.assignmentInEdit.name = name,
    setArea: (state, area) => state.assignmentInEdit.area = area,
    setState: (state, newState) => state.assignmentInEdit.state = newState,
    setAssignee: (state, assignee) => state.assignmentInEdit.assignee = assignee,
    setDescription: (state, description) => state.assignmentInEdit.description = description,
};

export default {
    state,
    getters,
    actions,
    mutations,
};