<template>
  <button @click="$emit('clicked')">
    <span v-if="name">{{ name }}</span>
    <slot v-else />
  </button>
</template>

<script>
export default {
    props: {
        name: String
    }
};
</script>

<style scoped>
button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #65bf95;
  /* background-color: #7B0051; */
  box-shadow: 2px 2px 4px #b5b5b5;
  color: #000000;
  border: 0px;
  border-radius: 5px;
  padding: 5px 30px;
  font-weight: 600;
}

button:hover {
  background-color: #7dddb0;
}
</style>