<template>
  <Modal
    v-if="area"
    :id="id"
    @save="save"
    @delete="deleteArea"
    @close="temp = {}"
    :headline="area.name"
  >
    <b-row>
      <b-col>
        <!-- Area name -->
        <h5>{{ $t("name") }}:</h5>
        <b-input
          disabled
          :value="area.name"
          :placeholder="area.name"
          @input="temp.name = $event"
        />

        <!-- Area address -->
        <h5>{{ $t("address") }}:</h5>
        <b-input
          disabled
          :value="area.address"
          :placeholder="area.address"
          @input="temp.address = $event"
        />
      </b-col>

      <b-col>
        <!-- Region -->
        <h5>{{ $t("region") }}:</h5>
        <b-select
          disabled
          :options="regions"
          :value="area.region ? area.region.id : undefined"
          @change="temp.region_id = $event"
        />

        <!-- Area type -->
        <h5>{{ $t("areaType") }}:</h5>
        <b-select
          disabled
          :options="areaTypes"
          :value="area.area_type"
          @change="temp.area_type = $event"
        />
      </b-col>
    </b-row>

    <!-- Containerliste -->
    <Collapse
      collapseId="container"
      :headline="$t('containers')"
      :isVisible="false"
    >
      <b-row class="filterRow">
        <b-col style="display: flex; align-items: center">
          <span style="vertical-align: center">Filtrer på hentetype:</span>
        </b-col>
        <b-col>
          <b-form-select v-model="containerFilter" :options="filterOptions" label-field="" />
        </b-col>
        <b-col style="display: flex; align-items: center">
          <span v-if="isLoading">&nbsp;</span>
          <span v-else>Viser {{filterLength}} resultater.</span>
        </b-col>
      </b-row>

      <b-row class="listStyle headlineStyle">
        <b-col v-for="(headline, index) in headlines" :key="index">
          <li>{{ headline }}</li>
        </b-col>
      </b-row>
      <ul v-if="!isLoading" class="containersList">
        <b-row
          class="listStyle"
          v-for="(container, index) of containers"
          :key="index"
        >
          <b-col v-for="(colItem, index) in container" :key="index">
            <li>{{ colItem }}</li>
          </b-col>
        </b-row>
      </ul>
      <ul v-else>
        <b-spinner style="display: block; margin: 0.5rem auto" variant="info"></b-spinner>
      </ul>
    </Collapse>

    <!-- Kartvisning -->
    <Collapse collapseId="map" :headline="$t('map')" :isVisible="false">
      <span
        ><StaticMap
          :paths="returnObjectCoordinates(area.coordinates)"
        ></StaticMap
      ></span>
    </Collapse>
  </Modal>
</template>

<script>
import { mapGetters } from "vuex";
import {
  putArea,
  deleteArea,
  getContainersWithinArea,
} from "../../../services/reenapi";

import Modal from "../modalComponents/ModalWindow";
import Collapse from "../CollapseComponent";
import StaticMap from "../MapStaticArea";

export default {
  name: "AreaModal",

  components: {
    Modal,
    Collapse,
    StaticMap,
  },

  props: {
    id: String,
    area: Object,
  },

  watch: {
    area: {
      handler: function(new_area) {
        this.getContainersWithinArea(new_area.coordinates);
      }, 
      immediate: true
    },
    containerFilter: {
      handler() {
        this.getContainersWithinArea(this.area.coordinates);
        this.filterLength = this.containers.length;
      },
      immediate: true,
    },
  },

  data() {
    return {
      // selectedRegion: {},
      isLoading: false,
      containerFilter: null,
      filterOptions: [
        { text: "Alle", value: null },
        { text: "Combi", value: "Combi" },
        { text: "Hook", value: "Hook" },
        { text: "Lift", value: "Lift" },
      ],
      filterLength: undefined,
      containers: [],
      headlines: [
        "QR-kode",
        "Tilstand",
        "Containertype",
        "Hentetype",
        "Sist sett",
      ],
      temp: {},
    };
  },

  computed: {
    ...mapGetters(["getChosenArea", "regions", "areaTypes"]),
  },

  methods: {
    async save() {
      // Only send fields which are needed:
      const changes = {
        id: this.area.id,
        tenant_id: this.area.tenant_id,
        name: this.temp.name ? this.temp.name : undefined,
        address: this.temp.address ? this.temp.address : undefined,
        region_id: this.temp.region_id ? this.temp.region_id : undefined,

        area_type: this.temp.area_type
          ? this.temp.area_type
          : this.area.area_type,
      };

      await putArea(changes);

      this.temp = {};
      this.$emit("update");
    },

    async deleteArea() {
      await deleteArea(this.area.id);
      this.$emit("update");
      this.$bvModal.hide(this.id);
    },

    returnObjectCoordinates(data) {
      // API returns X = lng, Y = lat, this needs to be inverted to interact with google maps functions.
      try {
        let coordinates = [];
        for (let value of data) {
          let coord = {
            y: value.x,
            x: value.y,
          };

          coordinates.push(coord);
        }
        return coordinates;
      } catch (e) {
        console.log(e);
      }
    },

    async getContainersWithinArea(coordinates) {
      this.isLoading = true;
      coordinates = this.returnObjectCoordinates(coordinates);

      for (let coord of coordinates) {
        let x = parseFloat(coord.y).toFixed(6);
        let y = parseFloat(coord.x).toFixed(6);

        coord.x = x;
        coord.y = y;
      }

      let response = await getContainersWithinArea(coordinates);
      this.containers = [];
      for (let container of response) {
        if (container.container_type) {
          if (!this.containerFilter || (!!this.containerFilter && container.container_type.mount_type === this.containerFilter)) {
            let containerObj = {
              qr: container.qr_code,
              state: container.state,
              type: container.container_type.name,
              mount_type: container.container_type.mount_type,
              last_seen: container.location?.timestamp,
            };
            if (containerObj.last_seen) {
              containerObj.last_seen = new Date(containerObj.last_seen).toLocaleString();
            }
            this.containers.push(containerObj);
          }
        }
      }

      this.filterLength = this.containers.length;
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.containersList {
  max-height: 30vh;
  overflow: scroll;
}

.headlineStyle {
  padding: 0.375rem 1.9rem 0.375rem 0.75rem !important;
  margin: 0 !important;
  font-weight: 600;
  width: 100%;
  border-bottom: none !important;
}

.listStyle {
  border-bottom: 1px solid #b5b5b5;
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
  margin: 0;
}

.filterRow {
  padding: 0.375rem 1.9rem 0.375rem 0.75rem !important;
  margin: 0;
  width: 100%;
  border: 1px solid #ccc;
}

.listStyle:last-child {
  border-bottom: none;
}

ul {
  border-radius: 0.25rem;
  border: 1px solid #b5b5b5;
  background-color: white;
  width: 100%;
  padding-left: 0;
}

li {
  list-style-type: none;
}
</style>
