const state = {
    // This is where you declare the variable.
    itemCache: [],

    // If more filters are to be shown, update this with new filter type
    dict: {
        volume: " m³",
        weight: " kg",
        name: "",
        mount_type: "",
    }
};

const getters = {
    // Getters makes the variable available to the components.
    getItemCache: (state) => state.itemCache,
    getDict: (state) => state.dict
};

const actions = {
    // the Action is a function that can be used in components. 
    // in this case the commit means that we wish to do a mutation, and the variable that is sent as a parameter
    // is what we will mutate the variable to.
    // in the commit we reference the mutation we wish to do.
    setItemCache({
        commit
    }, variable) {
        const response = variable;
        commit("mutateCache", response)
    }

};

const mutations = {
    // The mutation is the change of the value of a variable
    mutateCache: (state, itemCache) => (state.itemCache = itemCache)
};

export default {
    state,
    getters,
    actions,
    mutations,
};