<template>
  <WidgetBase :title="$t(item.title)">
    <Spinner v-if="loading"></Spinner>
    <DoughnutChart
      v-if="chartData && !loading"
      class="chart"
      :chartData="chartData"
      :chartOptions="chartOptions"
    ></DoughnutChart>
  </WidgetBase>
</template>

<script>
import DoughnutChart from "./widgetComponents/charts/DoughnutChart.vue";
import Spinner from "./widgetComponents/Spinner";
import WidgetBase from "./widgetComponents/WidgetBase.vue";

import {
  getAllContainersByState,
  getContainerStats,
} from "@/services/reenapi.js";

export default {
  name: "ContainerTotal",
  components: {
    WidgetBase,
    Spinner,
    DoughnutChart,
  },
  props: {
    options: {
      type: Object,
      required: false,
    },item: {
      type: Object
    }
  },
  data() {
    return {
      states: null,
      loading: false,
      chartData: null,
      chartOptions: {
        plugins: {
          tooltip: {
            
          },
          legend: {
          }
        },
        layout: {
          padding: 15,
        },
        responsive: true,
        maintainAspectRatio: false,

      }
    };
  },
  methods: {
    createDataObject() {
        let chartResponse = [
          {
            name: this.$t("pretty"),
            count: this.states.a,
          },
          {
            name: this.$t("ugly"),
            count: this.states.b,
          },
          {
            name: this.$t("ruined"),
            count: this.states.c,
          },
        ];
        return chartResponse;
    },
    createChartData() {
      let data = this.createDataObject()

      var chartData = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [
              "rgb(101, 191, 149)",
              "rgb(252, 214, 116)",
              "rgb(255,106,106)",
            ],
            hoverOffset: 4,
          },
        ],
      };

      for (let item of data) {
        chartData.labels.push(item.name);
        chartData.datasets[0].data.push(item.count);
      }
      this.chartData = chartData
    },
    async getStateAmount() {
      this.loading = true;
      let response = await getContainerStats();
      this.loading = false;
      return response.data.state_count;
    },
  },
  async mounted() {
    this.states = await this.getStateAmount();
    this.createChartData()
  },
};
</script>

<style scoped>
.chart {
  cursor: pointer;
  overflow: hidden;
  margin: auto;
}
</style>
