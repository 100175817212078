<template>
  <b-row class="listStyle">
    <b-checkbox @input="checked" :checked="isCheckedFunc(listItem)" />
    <b-col v-for="colItem in listItem" :key="colItem[uniqueIdentifier]">
      {{ colItem }}
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "ListItem",
  props: {
    listItem: Object,
    isCheckedFunc: Function,
    uniqueIdentifier: String,
  },
  data() {
    return {
      isChecked: false,
    }
  },
  methods: {
    checked(isChecked) {
      if (isChecked) this.$emit("checked", this.listItem);
      else this.$emit("unchecked", this.listItem);
    },
  },
  updated() {
    this.isChecked = this.isCheckedFunc(this.listItem);
    console.log("updateeded");
  }
};
</script>
