<template>
  <div>
    <b-container>
      <b-col>
        <b-row>
          <h2>Innstillinger</h2>
        </b-row>

        <div class="divider"></div>

        <b-row>
          <ul>
            <br />
            <h5>Brukerinformasjon</h5>
            <div
              class="assignmentList"
              v-for="(item, index) in userList"
              :key="index"
            >
              <li><strong>Navn:</strong> {{ item.name }}</li>
              <li><strong>Email:</strong> {{ item.mail }}</li>
              <li><strong>Adresse:</strong> {{ item.adress }}</li>
              <li><strong>Telefon:</strong> {{ item.mobile }}</li>
            </div>

            <br />
            <h5>Kjøretøyinformasjon</h5>
            <div
              class="assignmentList"
              v-for="(item, index) in carList"
              :key="index"
            >
              <li><strong>Registreringsnummer:</strong> {{ item.regNr }}</li>
              <li><strong>Merke:</strong> {{ item.merke }}</li>
              <li><strong>Modell:</strong> {{ item.model }}</li>
              <li><strong>Kjøretøytype:</strong> {{ item.car }}</li>
            </div>
          </ul>
        </b-row>
      </b-col>
    </b-container>
    <Menu
      class="menu"
      highlight="UserSettings"
      @change="$emit('change', $event)"
    ></Menu>
  </div>
</template>

<script>
import Menu from "../driver/subComponents/Menu";

export default {
  components: {
    Menu,
  },
  data() {
    return {
      userList: [
        {
          name: "Kåre Vanndam",
          mail: "kåre.vanndam@renovest.no",
          adress: "Damveien 32, 1234 Innsjø",
          mobile: "123 45 678",
        },
      ],
      carList: [
        {
          regNr: "AB12345",
          merke: "TOYOTA",
          model: "HIACE",
          car: "Bil",
        },
      ],
    };
  },
};
</script>

<style scoped>
.container {
  color: black;
  display: flex;
  justify-content: center;
  margin-top: 70px;
  padding-bottom: 70px !important;
  padding: 3%;
  position: relative;
}
.assignmentList {
  /* margin-top: 25px; */
  cursor: pointer;
  border-bottom: 1px solid #b5b5b5;
  border-radius: 0.25rem;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  width: 100%;
}
/* .assignmentList:hover {
  box-shadow: 0px 4px #65bf95;
} */
ul {
  padding-left: 0;
  width: 100%;
}
li {
  list-style-type: none;
}
.divider {
  border-bottom: 1px solid #65bf95;
  margin-top: 25px;
  margin-left: -15px;
  margin-right: -15px;
}
.menu {
  position: absolute;
  bottom: 0;
  width: 100vw;
  z-index: 5000;
}
</style>
