<template>
  <b-container>
    <PageTitle :title="$t('users')"> </PageTitle>

    <List
      :labels="labels"
      :valueFunc="getUsersFunc"
      v-on:rowClicked="selectedUser = $event"
    />
    <UserModal
      v-if="selectedUser"
      :userObj="selectedUser"
      v-on:close="selectedUser = $event"
    />
  </b-container>
</template>

<script>
import { getUsers } from "../../services/reenapi.js";

import PageTitle from "../../components/common/PageTitle";
import List from "../../components/common/List";
import UserModal from "../../components/fleetManagement/modalComponents/UserModal";

export default {
  components: {
    PageTitle,
    List,
    UserModal,
  },

  data() {
    return {
      selectedUser: null,
      userShowedInModal: null,
      doShowModal: false,
    };
  },

  computed: {
    labels() {
      return [
        { name: this.$t("email"), value: "email", filterable: false },
        { name: this.$t("name"), value: "name", filterable: false },
        { name: this.$t("roles"), value: "app_metadata.roles", filterable: false },
      ];
    },
  },

  methods: {
    showUserModal(user) {
      this.doShowModal = true;
      this.userShowedInModal = user;
      this.$bvModal.show("modalWindow");
    },

    async getUsersFunc(params) {
      return {
        data: (await getUsers(params)).data,
        totalCount: -1,
      };
    },
  },
};
</script>
