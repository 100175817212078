import { getContainer, getVehicle } from "../../services/reenapi";

const state = {
    mapRef: "",
    selectedMarker: null,
};

const getters = {
    getMapRef: (state) => state.mapRef,
    selectedMarker: (state) => state.selectedMarker,
};

const actions = {
    setMapRef({ commit }, mapRef) {
        commit("mutateMapRef", mapRef);
    },

    async setSelectMarker({ commit }, marker) {
        if (marker === null) {
            commit("selectedMarker", null);
            return;
        }
        else {
            commit("selectedMarker", marker)
            return;
        }

        // let fullMarker;
        // if (marker.qr_code) {
        //     // Marker is a container
        //     fullMarker = await getContainer(marker.id);
        // } else {
        //     // Marker is a vehicle
        //     fullMarker = await getVehicle(marker.id);
        // }

        // commit("selectedMarker", fullMarker.data);
    }
};

const mutations = {
    mutateMapRef: (state, mapRef) => (state.mapRef = mapRef),
    selectedMarker: (state, marker) => (state.selectedMarker = marker),
};

export default {
    state,
    getters,
    actions,
    mutations,
};