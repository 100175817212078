
<template>
  <div class="widgetContainer">
    <component
      v-for="(widget, index) of returnActiveWidgets"
      :key="index"
      class="widgetComponents"
      :style="createStyle(widget)"
      :is="widget.i"
      :item="widget"
    ></component>
  </div>
</template>

<script>
import Inventory from "@/components/dashboard/widgets/Inventory";
import Vehicles from "@/components/dashboard/widgets/Vehicles";
import Map from "@/components/dashboard/widgets/Map";
import ContainerState from "@/components/dashboard/widgets/ContainerState";
import ContainerOut from "@/components/dashboard/widgets/ContainerOut";
import ContainerLost from "@/components/dashboard/widgets/ContainerLost";
import ContainerTotal from "@/components/dashboard/widgets/ContainerTotal";
import AreaOverview from "@/components/dashboard/widgets/AreaOverview";
import ContainerStock from "@/components/dashboard/widgets/ContainersStock";

import { mapGetters } from "vuex";

export default {
  components: {
    Inventory,
    Vehicles,
    Map,
    ContainerState,
    ContainerOut,
    ContainerLost,
    ContainerTotal,
    AreaOverview,
    ContainerStock
  },
  props: ["data"],
  computed: {
    ...mapGetters(["getTableData", "getWidgets"]),
    returnActiveWidgets() {
      let widgets = this.getWidgets.filter(
        (widget) => this.data.widgets.includes(widget.i) == true
      );
      return widgets;
    },
  },
  methods: {
    createStyle(item) {
      let widgetOptions = item.widgetOptions;
      let style = {
        backgroundColor: widgetOptions.backgroundColor,
        color: widgetOptions.color,
        height: "100%",
        width: "100%",
        minHeight: "100%",
        maxHeight: "100%",
        minWidth: "100%",
        maxWidth: "100%",
      };

      if (widgetOptions.type == "thin") {
        style.gridColumnStart = "span 1";
        style.gridRowStart = "span 1";
      } else if (widgetOptions.type == "medium") {
        style.gridColumnStart = "span 1";
        style.gridRowStart = "span 7";
      } else if (widgetOptions.type == "large") {
        style.gridColumnStart = "span 2";
        style.gridRowStart = "span 12";
      } else if (widgetOptions.type == "wide") {
        style.gridColumnStart = "span 2";
        style.gridRowStart = "span 6";
      }
      return style;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.widgetContainer {
  padding: 1%;
  min-height: calc(100vh - 90px);
  max-width: calc(100vw - 230px);
  max-height: calc(100vh - 90px);
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-template-rows: 12% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6% 6%;
  justify-items: center;
  grid-auto-flow: row;
  gap: 1.5% 1%;
}
.widgetComponents {
  place-self: center;
}
</style>
