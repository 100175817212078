<template>
  <div class="list">
    <!-- Filtreringer -->
    <b-row>
      <b-col v-for="(label, index) in labelsFilterable" :key="index">
<!--          v-model="filters[label.value]"-->
        <b-form-input
          :value="filters[label.value]"
          :placeholder="label.formInputName ? label.formInputName : label.name"
          @input="filters[label.value] = $event"
          @keyup.enter="filterList"
        />
<!--          @input="prepareFilters(label.value, $event)"-->
      </b-col>
      <b-col v-if="labelsFilterable.length > 0" class="search-btn">
        <Button @clicked="filterList">{{ $t("filter") }}</Button>
      </b-col>
    </b-row>

    <b-row class="results">
      <b-col v-if="isLoading">&nbsp;</b-col>
      <b-col v-else>Viser {{ displayedList.length }} {{ totalCount !== -1 ? (totalCount !== -2 ? `av totalt ${totalCount}` : "") : "av max 500" }} resultater.</b-col>
    </b-row>

    <Divider />

    <!-- Listen -->
    <b-row v-if="!isLoading">
      <ul>
        <!-- Kolonneoverskrifter (labels) -->
        <b-row class="headline">
          <b-col v-for="(label, index) in labels" :key="index">
<!--            <li class="sortButton">-->
            <li>
              <span v-if="label.value === sortKey && sortAsc">▼</span>
              <span v-if="label.value === sortKey && !sortAsc">▲</span>
<!--              <span class="sortButton" @click="sortLists(label.value)">-->
                {{ label.name }}
<!--              </span>-->
            </li>
          </b-col>
        </b-row>

        <!-- Listedata (displayedList) -->
        <b-row
          class="list-row"
          v-for="(listItem, index) of displayedList"
          :key="index"
          @click="$emit('rowClicked', values[index])"
        >
          <b-col v-for="(colItem, index) in listItem" :key="index">
            <li>{{ colItem }}</li>
            <!-- <li>{{  }}</li> -->
          </b-col>
        </b-row>
      </ul>
    </b-row>
    <b-row v-else>
      <b-spinner style="margin: auto" variant="info"></b-spinner>
    </b-row>
  </div>
</template>

<script>
import { sortByKey } from "../../services/utils";

import Divider from "../fleetManagement/Divider"

export default {
  name: "ListComponent",

  components: {
    Divider,
  },

  data() {
    return {
      sortKey: null,
      sortAsc: true,
      displayedList: [],
      values: [],
      filters: {},
      totalCount: 0,
      isLoading: false,
    };
  },

  props: {
    labels: Array,
    valueFunc: {
      type: Function,
      required: true,
    },
    defaultSort: String,
  },

  computed: {
    labelsFilterable() {
      return this.labels.filter((l) => l.filterable);
    },
  },

  methods: {
    // prepareFilters(filter, event) {
    //   if (this.filters[filter] !== undefined) return;
    //
    //   if (event === "") {
    //     this.$delete(this.filters, filter);
    //   } else {
    //     this.$set(this.filters, filter, event);
    //   }
    // },

    async filterList() {
      this.setup(this.filters);
    },

    /**
     * Sorterer både den viste arrayen og verdi-arrayen.
     */
    sortLists(key) {
      // Endre sorteringsrekkefølgen hvis trykker på samme headline flere ganger.
      this.sortAsc = this.sortKey === key ? !this.sortAsc : true;
      this.sortKey = key;

      this.displayedList = sortByKey(this.displayedList, key, this.sortAsc);
      this.values = sortByKey(this.values, key, this.sortAsc);
    },

    /**
     * Filtrerer en array uten å endre på kilde-arrayen.
     */
    filter(arr, filterKeys) {
      // Ex: filterKeys = ["serial_number", "qr_code", "container_type.name"]

      let list = [];

      for (const item of arr) {
        let keys = Object.keys(item);
        // Ex: keys = ["id", "serial_number", "location", "qr_code", "container_type"]

        let keysFiltered = keys.filter((key) => {
          return filterKeys
            .map((filterKey) => filterKey.split(".")[0])
            .includes(key);
        });
        // Ex: keysFiltered = ["serial_number", "qr_code", "container_type"]

        // Sort keysFiltered to be in the same order as filterKeys
        keysFiltered.sort((a, b) => {
          let ia = filterKeys.indexOf(a);
          let ib = filterKeys.indexOf(b);
          return ia > ib;
        });

        let keysReduced = {};
        for (let i = 0; i < filterKeys.length; i++) {
          const key = keysFiltered[i]; // "container_type"
          const path = filterKeys[i]; // "container_type.name"

          let value = this.getValue(item, path);

          let possibleDate = new Date(value);
          if (typeof value === "string" && possibleDate != "Invalid Date") {
            value = possibleDate.toLocaleString(undefined, { dateStyle: "medium" })
          }

          keysReduced = {
            ...keysReduced,
            [key]: value,
          };
        }
        // Ex: keysReduced = {serial_number: "AM123456", qr_code: "ABCDEF", container_type: {...}}

        list.push(keysReduced);
      }

      return list;
    },

    getValue(obj, path) {
      let pathArray = path.split(".");
      let value = obj;

      for (const property of pathArray) {
        if (!value[property]) {
          return undefined;
        }
        value = value[property];
      }

      return value;
    },

    async setup(search_params) {
      this.isLoading = true;
      let processed_search_params = {};
      for (let param in search_params) {
        let splitted = param.split(".");
        processed_search_params = {
          ...processed_search_params,
          [splitted[0]]: search_params[param].trim ? search_params[param].trim() : search_params[param],
        }
      }

      const values = await this.valueFunc(processed_search_params);
      this.values = values.data;
      this.totalCount = values.totalCount;
      this.isLoading = false;

      // Her filtrerer vi lista så den kun inneholder verdiene som spesifisert i `labels`.
      this.displayedList = this.filter(
        this.values,
        this.labels.map((l) => l.value)
      );

      this.sortLists(this.defaultSort);
    },
  },

  async mounted() {
    for (const label of this.labelsFilterable) {
      if (label.defaultValue) {
        this.filters[label.value] = label.defaultValue;
      }
    }
    await this.setup();
  },
};
</script>

<style scoped>
.headline,
.list-row {
  padding: 0 1.75rem;
  width: 100%;
  user-select: none;
}

.headline {
  /* margin-top: 50px !important; */
  font-weight: bold;
  /* padding-top: 0 !important; */
  /* padding-bottom: 0 !important; */
  cursor: default !important;
  margin-bottom: -15px !important;
}

.headline:hover {
  box-shadow: none !important;
}

.list-row {
  padding: 0.375rem 1.75rem;
  margin-top: 20px;
  cursor: pointer;
  border: 1px solid #b5b5b5;
  background-color: white;
  border-radius: 0.25rem;
}

.list-row:hover {
  box-shadow: 0px 4px #65bf95;
}

.list > .row {
  margin-top: 1em;
  margin-bottom: 1em;
}

.list > .row:first-of-type {
  margin-top: 0;
}

.list > .row:last-of-type {
  margin-bottom: 0;
}

.results {
  text-align: right;
}

ul {
  padding-left: 0;
  width: 100%;
  /* margin-top: -20px; */
  margin-left: 30px;
}

li {
  list-style-type: none;
}

.sortButton {
  cursor: pointer;
}

.search-btn {
  flex-grow: 0;
}

.search-btn button {
  height: 100%;
}
</style>