<template>
  <b-container>
    <b-col>
      <b-row>
        <b-col>
          <h2>Avviksregistrering</h2>
        </b-col>
      </b-row>

      <div class="divider"></div>

      <b-row>
        <b-col>
          <Select
            label="Velg avvik-kategori"
            v-on:input="type = $event"
            :values="deviationTypes"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <Select
            label="Velg type avvik"
            v-on:input="type = $event"
            :values="deviationList"
          />
        </b-col>
      </b-row>

      <div class="row-element">
        <b-row>
          <b-col>
            <h5>Kommentar:</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-input type="text"></b-form-input>
          </b-col>
        </b-row>
      </div>

      <div class="row-element">
        <b-row class="text-center">
          <b-col class="buttonContainer">
            <button class="mr-5" @click="$emit('change', 'UnloadContainer')">
              Avbryt
            </button>
            <button>Lagre</button>
          </b-col>
        </b-row>
      </div>
    </b-col>
  </b-container>
</template>

<script>
import Select from "../driver/subComponents/SelectInput";

export default {
  components: {
    Select,
  },
  data() {
    return {
      deviationTypes: ["Feilsortering", "Forsøpling", "Skade på container"],
      deviationList: [
        "Ikke riktig avfall",
        "Overfylt container",
        "Skade - Lukk mangler",
        "Klage fra kunde",
      ],
    };
  },
};
</script>

<style scoped>
.container {
  color: black;
  display: flex;
  justify-content: center;
  margin-top: 70px;
  padding: 3%;
  position: relative;
}
.divider {
  border-bottom: 1px solid #65bf95;
  margin-top: 25px;
  margin-left: -15px;
  margin-right: -15px;
}
.row-element {
  margin-top: 20px;
}
button {
  margin: 5px;
  background-color: #65bf95;
  box-shadow: 2px 2px 4px #b5b5b5;
  color: #000000;
  border: 0px;
  border-radius: 10px;
  padding: 5px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 600;
  /* letter-spacing: 1px; */
  /* font-size: 1.125em; */
  /* text-shadow: 0px 0px 4px #000000a6; */
}
.buttonContainer :hover {
  background-color: #7dddb0;
}
</style>
