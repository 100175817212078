const state = {
    tableData: null,
    currentRegion: "",
    currentMountype: "",
    widgets: [{
            i: "ContainerTotal",
            title: "containers",
            info: "Alle containere.",
            category: "Containers",
            widgetOptions: {
                backgroundColor: '#6349C8',
                color: 'white',
                type: 'thin',
            },
            icon: "trash-fill"
        }, {
            i: "ContainerLost",
            title: "dashboard.lost",
            category: "Containers",
            info: "Containere på avveie.",
            widgetOptions: {
                backgroundColor: '#6349C8',
                color: 'white',
                type: 'thin',

            },
            icon: "trash-fill"
        },
        {
            i: "ContainerStock",
            title: "dashboard.stock",
            info: "Containere på lager.",
            category: "Containers",
            widgetOptions: {
                backgroundColor: '#6349C8',
                color: 'white',
                type: 'thin',
            },
            icon: "trash-fill"
        },
        {
            i: "Vehicles",
            category: "Vehicles",
            title: "vehicles",
            info: "Oversikt over ledige kjøretøy.",
            widgetOptions: {
                backgroundColor: '#6349C8',
                color: 'white',
                type: 'thin',

            },
            icon: "truck"
        },
        {
            i: "Map",
            title: "map",
            info: "Se kartet.",
            category: "Map",
            widgetOptions: {
                backgroundColor: 'white',
                color: 'black',
                type: 'large',
            },
            icon: "map"
        },
        {
            i: "AreaOverview",
            category: "Areas",
            title: "dashboard.areaOverview",
            info: "OVerview of all areas in the selected region",
            widgetOptions: {
                backgroundColor: 'white',
                color: 'black',
                type: 'wide',

            },
            icon: "bounding-box"
        },
        {
            i: "ContainerState",
            category: "Containers",
            title: "dashboard.containerStatus",
            info: "Tilstand på containere.",
            widgetOptions: {
                backgroundColor: 'white',
                color: 'black',
                type: 'medium',

            },
            icon: "trash-fill"

        },

    ],
};

const getters = {
    getCurrentRegion: (state) => state.currentRegion,
    getCurrentMountType: (state) => state.currentMountType,
    getWidgets: (state) => state.widgets,
};

const actions = {
    setCurrentRegion({
        commit
    }, variable) {

        commit('mutateCurrentRegion', variable)
    },
    setCurrentMountType({
        commit
    }, variable) {
        commit('mutateCurrentMountType', variable)
    },
    setWidgets({
        commit
    }, widgets) {
        commit("mutateWidgets", widgets)
    },
    addWidget({
        commit,
        getters
    }, name) {
        let newWidgets = [];
        for (const widget of getters.getWidgets) {
            newWidgets.push({
                ...widget,
                inUse: (widget.i === name) ? true : widget.inUse
            });
        }
        commit("mutateWidgets", newWidgets);
    },
    removeWidget({
        commit,
        getters
    }, name) {
        let newWidgets = [];
        for (const widget of getters.getWidgets) {
            newWidgets.push({
                ...widget,
                inUse: (widget.i === name) ? false : widget.inUse
            });
        }
        commit("mutateWidgets", newWidgets);
    }
};

const mutations = {
    mutateWidgets: (state, widgets) => (state.widgets = widgets),
    mutateCurrentRegion: (state, currentRegion) => (state.currentRegion = currentRegion),
    mutateCurrentMountType: (state, currentMountType) => (state.currentMountype = currentMountType)
};

export default {
    state,
    getters,
    actions,
    mutations,
};