<template>
  <Modal
    v-if="container"
    :id="id"
    @save="save"
    @delete="deleteContainer"
    @close="closeModal"
    :headline="container.qr_code"
  >
    <b-row>
      <b-col>
        <!-- QR code -->
        <h5>{{ $t("qr") }}:</h5>
        <b-input disabled v-model="container.qr_code"></b-input>

        <!-- Organization -->
        <h5>{{ $t("organization") }}:</h5>
        <b-select
          :options="organizations"
          :value="container.organization_id"
          @change="changeOrganization"
        />

        <!-- Region -->
        <h5>{{ $t("region") }}:</h5>
        <b-select
          :options="regionOptions"
          :value="regionValue"
          @change="temp.region_id = $event"
        />

        <!-- Container type -->
        <h5>{{ $t("type") }}:</h5>
        <b-input-group>
          <b-select
            :options="containerTypes"
            :value="getValue('container_type', 'id')"
            @change="temp.container_type_id = $event"
          />
          <b-button @click="$bvModal.show('container-type-modal')">
            <b-icon icon="info-circle"></b-icon>
          </b-button>
        </b-input-group>

        <!-- State -->
        <h5>{{ $t("state") }}:</h5>
        <b-select
          :options="containerStatesTranslated"
          :value="container.state"
          @change="temp.state = $event"
        />
      </b-col>

      <b-col>
        <!-- Serial number -->
        <h5>{{ $t("serialNumber") }}:</h5>
        <b-input disabled v-model="container.serial_number"></b-input>

        <!-- Unit serial number registrated on container -->
        <h5>{{ $t("unit") }}:</h5>
        <b-input disabled :value="getValue('unit', 'type')"></b-input>

        <!-- Temperature -->
        <h5>{{ $t("temp") }}:</h5>
        <b-input-group append="°C">
          <b-input disabled :value="getValue('temperature', 'value')"></b-input>
        </b-input-group>

        <!-- Last seen date -->
        <h5>{{ $t("seen") }}:</h5>
        <b-input disabled :value="getLastSeen()"></b-input>

        <div v-if="container.fill_depth">
          <h5>{{ $t("fill") }}:</h5>
          <b-input disabled :value="getFillLevel(container) + '%'"></b-input>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="showExternalDimensions" class="external-dimensions">
      <b-col>
        <h5>{{ $t("external_length") }}</h5>
        <b-input
            type="number"
            :value="container.external_length"
            @change="temp.external_length = $event"
        />
      </b-col>

      <b-col>
        <h5>{{ $t("external_width") }}</h5>
        <b-input
            type="number"
            :value="container.external_width"
            @change="temp.external_width = $event"
        />
      </b-col>

      <b-col>
        <h5>{{ $t("external_height") }}</h5>
        <b-input
            type="number"
            :value="container.external_height"
            @change="temp.external_height = $event"
        />
      </b-col>
    </b-row>

    <b-row
      v-if="
        isCertificationExpired &&
        container.last_certified != '0001-01-01T00:00:00Z'
      "
    >
      <b-col>
        <h5 class="certificationExpired">{{ $t("expired") }}</h5>
      </b-col>
    </b-row>
    <b-row
      v-if="
        container.last_certified &&
        container.last_certified != '0001-01-01T00:00:00Z'
      "
    >
      <b-col>
        <h5>{{ $t("certified") }}</h5>
        <b-form-datepicker
            today-button
            :value="container.last_certified"
            @input="updateCertification"
            :date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
        />
      </b-col>
      <b-col>
        <h5>{{ $t("expires") }}</h5>
        <b-form-datepicker
            disabled
            v-model="certificationExpiration"
            :date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
        />
      </b-col>
      <b-col>
        <h5>{{ $t("expiresIn") }}</h5>
        <b-input
          disabled
          v-model="certificationCountdown"
        ></b-input>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <!-- Description / notes -->
        <h5>{{ $t("notes") }}:</h5>
        <b-form-textarea
          @input="temp.notes = $event"
          :value="container.notes"
        ></b-form-textarea>
      </b-col>
    </b-row>

    <Collapse collapseId="map" headline="Kart" :isVisible="false">
      <b-row>
        <b-col v-if="container.location">
          <p>Trykk på kartet for å åpne i Google Maps.</p>
          <a
            :href="`https://www.google.com/maps/place/${this.container.location.latitude}N+${this.container.location.longitude}E`"
            target="_BLANK"
          >
            <MapStatic
              :location="{
                lat: container.location.latitude,
                lng: container.location.longitude,
              }"
            />
          </a>
        </b-col>
      </b-row>
    </Collapse>

    <ContainerTypeModal
      id="container-type-modal"
      :containerType="container.container_type"
    />
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Modal from "./ModalWindow";
import ContainerTypeModal from "./ContainerType.vue";
import Collapse from "../CollapseComponent";
import { deleteContainer, putContainer } from "@/services/reenapi";
import { getFillLevel, userHasTenant, userIsSuperadmin } from "@/services/utils";
import MapStatic from "../MapStaticContainer.vue";

export default {
  name: "ContainerModal",

  components: {
    Modal,
    ContainerTypeModal,
    Collapse,
    MapStatic,
  },

  props: {
    id: String,
    container: Object,
  },

  data() {
    return {
      certificationExpiration: "",
      certificationCountdown: null,
      isCertificationExpired: false,
      temp: {},
    };
  },

  /*
   * Runs when modal gets new data.
   */
  watch: {
    container(c) {
      if (!c || !c.last_certified) {
        return;
      }

      this.certificationExpiration = this.getDate(c.last_certified, 1, 0, 0);
      this.certificationCountdown = this.findDifference(new Date(), c.last_certified);
      this.isCertificationExpired = this.isExpired(c.last_certified);
    },
  },

  computed: {
    ...mapGetters([
      "containerStates",
      "containerTypes",
      "organizations",
      "regions",
      "user",
    ]),

    showExternalDimensions() {
      // Show external dimensions only if:
      // * the container has one or more of the dimension properties set
      // * the user is NG Metall
      // * the user is superadmin (REEN)
      const dimensionProps = ["external_length", "external_width", "external_height"];
      const anyPropHasValue = dimensionProps.some(dimensionProp => !!this.container[dimensionProp]);
      const userIsNGMetall = userHasTenant(this.user, "998ca0e4-a145-446f-9ca6-ae8ece11f1a2");

      return anyPropHasValue || userIsNGMetall || userIsSuperadmin(this.user);
    },

    containerStatesTranslated() {
      return this.containerStates.map((state) => {
        return {
          ...state,
          text: this.$t(`states.${state.value}`),
        };
      });
    },

    /**
     * Gets the correct default value.
     * @returns {string?}
     */
    regionValue() {
      if (this.regions.length === 1) {
        return this.regions[0].value;
      }
      if (this.container.region && this.regions.find((r) => r.id === this.container.region.id)) {
        return this.container.region.id;
      }
      return null;
    },

    regionOptions() {
      if (this.regions.length === 0) {
        return [{ text: this.$t('noRegion'), value: null }]
      }
      if (!this.regions.some(r => r.id === this.regionValue)) {
        return [...this.regions, { text: this.$t('selectARegion'), value: null }]
      }
      return this.regions;
    },
  },

  methods: {
    ...mapActions(["cacheRegions"]),
    getFillLevel,

    findDifference(currentDate, expiryDate) {
      let d1 = currentDate;
      let d2 = new Date(this.getDate(expiryDate, 1, 0, 0));

      let diffInDate = d2.getTime() - d1.getTime();
      let diffDateInDays = Math.floor(diffInDate / (1000 * 3600 * 24));

      if (diffDateInDays < 0) return "EXPIRED";
      else return diffDateInDays + " Days";
    },

    isExpired(date) {
      let d1 = new Date();
      let d2 = new Date(this.getDate(date, 1, 0, 0));

      d1 = d1.getTime();
      d2 = d2.getTime();
      return d2 < d1;
    },

    getDate(date, year, month, day) {
      if (!date) {
        return;
      }

      let fullDate = new Date(date);
      let y = fullDate.getFullYear();
      let m = fullDate.getMonth();
      let d = fullDate.getDate();
      let t = fullDate.getHours();

      // let timeZone = fullDate.getTimezoneOffset();
      // timeZone = (timeZone * -1) / 60;
      // let positiveOrNegativeGMT = '-'

      // if (timeZone > 0) positiveOrNegativeGMT = "+";

      let alteredDate = new Date(y + year, m + month, d + day, t);
      return alteredDate.toISOString();
    },

    updateCertification(event) {
      this.temp.last_certified = event;
      this.certificationExpiration = this.getDate(this.temp.last_certified, 1, 0, 0);
      this.certificationCountdown = this.findDifference(new Date(), this.temp.last_certified);
      this.isCertificationExpired = this.isExpired(this.temp.last_certified);
    },

    async save() {
      // Region can not be null.
      if (this.temp.region_id === null) {
        alert("Region kan ikke være blank.");
        return;
      }

      // Check all the external dimensions if the value has been removed by the user.
      for (const dimensionProp of ["external_length", "external_width", "external_height"]) {
        if (this.container[dimensionProp] && this.temp[dimensionProp] === "") {
          this.temp[dimensionProp] = -1;
        }
      }

      // Only send fields which are needed:
      const changes = {
        id:              this.container.id,
        tenant_id:       this.container.tenant_id,
        last_certified:  this.temp.last_certified,
        container_type:  this.temp.container_type_id ? { id: this.temp.container_type_id } : undefined,
        state:           this.temp.state             ? this.temp.state                     : undefined,
        notes:           this.temp.notes             ? this.temp.notes                     : undefined,
        organization_id: this.temp.organization_id   ? this.temp.organization_id           : undefined,
        region_id:       this.temp.region_id         ? this.temp.region_id                 : undefined,
        external_length: this.temp.external_length   ? this.temp.external_length           : undefined,
        external_width:  this.temp.external_width    ? this.temp.external_width            : undefined,
        external_height: this.temp.external_height   ? this.temp.external_height           : undefined,
      };

      await putContainer(changes);

      this.temp = {};
      this.$emit("update"); // Notify parent that something has changed.
    },

    async deleteContainer() {
      let isConfirmed = confirm(
        `Er du sikker på at du vil slette containeren ${this.container.qr_code}?`
      );
      if (!isConfirmed) return;

      let result = await deleteContainer(this.container.id);
      console.log(result);

      if (result.status === 200) {
        this.$bvToast.toast(this.container.qr_code, {
          title: "Containeren er slettet",
          variant: "success",
          autoHideDelay: 5000,
        });
        this.$bvModal.hide(this.id);
        this.$emit("update");
      } else {
        this.$bvToast.toast(this.container.qr_code, {
          title: "Kunne ikke slette containeren",
          variant: "danger",
          autoHideDelay: 5000,
        });
      }
    },

    closeModal() {
      this.container = null;
    },

    getLastSeen() {
      if (!this.container.unit || !this.container.location) return null;
      let date = new Date(this.container.unit.timestamp);
      if (date == "Invalid Date") date = new Date(this.container.location.timestamp);
      return date.toLocaleString("uk");
    },

    getValue(parent, child) {
      if (this.container[parent] != undefined) {
        return this.container[parent][child];
      }
      return null;
    },

    async changeOrganization(organization_id) {
      this.temp.organization_id = organization_id;
      await this.cacheRegions(organization_id);
      this.temp.region_id = this.regionValue;
    },
  },
};
</script>

<style scoped>
.certificationExpired {
  background-color: rgba(255, 0, 0, 0.726);
  text-align: center;
  margin: auto;
  width: 50%;
  border-radius: 10px;
  border: 3px solid red;
}
</style>