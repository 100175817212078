<template>
  <div class="areaList" v-if="getShowAreaList">
    <div v-if="!addArea" class="list">
      <b-icon-x-circle
        class="exitCross h3"
        @click="setShowAreaList(false)"
      ></b-icon-x-circle>
      <h2 id="areaCounter">Områder {{ "(" + areas.length + ")" }}</h2>
      <div class="listItemContainer">
        <div
          class="listItem"
          v-for="(item, index) in areas"
          :key="index"
          @click="panTo(item), (chosenArea = item), $emit('clicked', item)"
        >
          <b-icon-bounding-box></b-icon-bounding-box>
          <div>{{ item.name }}</div>
        </div>
      </div>
      <button id="button" @click="setShowDraw(), (addArea = !addArea)">
        Opprett Område
      </button>
    </div>
    <AreaInformation v-if="!addArea" :item="chosenArea"></AreaInformation>
    <CreateArea v-if="addArea" @abort="addArea = !addArea" :coords="coords"></CreateArea>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getAreas } from "../../../../../services/reenapi";

import CreateArea from "./SaveArea";
import AreaInformation from "./AreaInformation.vue";

export default {
  components: {
    CreateArea,
    AreaInformation,
  },
  props: {
    coords: Array,
    map: Object,
  },
  computed: {
    ...mapGetters(["getShowAreaList"]),
  },
  data() {
    return {
      addArea: false,
      areas: "",
      chosenArea: "",
    };
  },
  methods: {
    ...mapActions(["setShowAreaList", "setShowDraw", "setAreas"]),

    async getAreas() {
      let response = await getAreas();
      this.areas = response.data;
      this.setAreas(response.data);
    },
    
    panTo(location) {
      let totalLat = 0;
      let totalLng = 0;
      let avgLat = 0;
      let avgLng = 0;

      // Add all location values
      for (let loc of location.coordinates) {
        totalLat += loc.y;
        totalLng += loc.x;
      }

      // Get the average
      avgLat = totalLat / location.coordinates.length;
      avgLng = totalLng / location.coordinates.length;

      // Pan to the average coordinates. This is so the pan hits the center of the area
      this.map.panTo({
        lat: avgLat,
        lng: avgLng,
      });
      this.map.setZoom(19);
    },
  },
  mounted() {
    this.getAreas();
  },
};
</script>

<style scoped>
#button {
  width: 100%;
  height: 30px;
  margin-top: 10px;
  text-decoration: bold;
  background-color: rgb(166, 203, 231);
  border-radius: 5px;
  border: 0;
}
.exitCross {
  position: absolute;
  right: 25px;
  cursor: pointer;
}
.areaList {
  position: fixed;
  left: 15px;
  top: 150px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.list {
  background-color: white;
  width: 400px;
  border-radius: 25px;
  box-shadow: 0px 0px 5px 1px black;
  padding: 25px;
  max-height: 60vh;
  display:flex;
  flex-direction: column;
}
.listItem {
  gap: 5px;
  padding: 5px;
  display: flex;
  align-items: centeR;
  cursor: pointer;
}
.listItem:nth-child(odd) {
  background-color: rgb(243, 243, 243);
}
#areaCounter {
  text-decoration: underline;
}
.listItemContainer {
  max-height: 300px;
  overflow: scroll;
}
.listItemContainer :hover {
  background-color: #d7edfd;
}

.listItemContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.listItemContainer {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>