<template>
  <div>
    <div
      class="openTripPlanner"
      @click="showPlanner = !showPlanner"
      v-if="!showPlanner"
    >
      <b-icon-card-list color="black" class="openIcon h4"></b-icon-card-list>
    </div>
    <div class="container" v-if="showPlanner">
      <div>
        <h3 class="title">{{$t('directions.title')}}</h3>
      </div>
      <div class="exitCross">
        <b-icon-x-circle
          class="h2"
          color="gray"
          @click="showPlanner = !showPlanner"
        ></b-icon-x-circle>
      </div>
      <Draggable
        v-model="addresses"
        @start="dragging = true"
        @end="dragging = false"
        ghost-class="ghost"
      >
        <transition-group class="group">
          <div class="item" v-for="(i, index) in addresses" :key="index">
            <div class="iconContainer">
              <b-icon
                class="icon"
                :icon="returnIcon(index)"
                color="black"
              ></b-icon>
            </div>
            <div class="address">
              <div>{{ i.t }}</div>
            </div>
            <div class="removeItem" @click="removeWaypoint(index)">
              <b-icon-x-circle color="gray"></b-icon-x-circle>
            </div>
          </div>
        </transition-group>
      </Draggable>
      <div class="addContainer">
        <div class="addWaypointButton" @click="toggleAddMode">
          <b-icon-x-circle></b-icon-x-circle>
         {{$t('directions.addTitle')}}
        </div>

        <div class="addModeDropdown" v-if="addMode">
          <b-form-group
            class="addWaypointsForm"
            :label="$t('directions.addLabel')"
          >
            <b-form-input
              v-model="newLocation"
              placeholder="Tulleveien 3, 1234 Tulleland..."
            ></b-form-input>
            <b-button @click="addLocation(newLocation)">{{$t('directions.add')}}</b-button>
          </b-form-group>
        </div>
      </div>
      <div class="buttonContainer">
        <button @click="createRouteObject(false)">{{$t('directions.createRoute')}}</button>
        <button @click="createRouteObject(true)">{{$t('directions.optRoute')}}</button>
      </div>
      <div class="buttonContainer">
        <button @click="removeRoute()">{{$t('directions.remRoute')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import Draggable from "vuedraggable";
export default {
  components: {
    Draggable,
  },
  props: ["data", "map"],
  data() {
    return {
      showPlanner: false,
      addresses: [
        {
          t: "Håkons Gate 90B, 3258 Larvik",
        },
        {
          t: "Hammergata 24, 3264 Larvik",
        },
        {
          t: "Olavs Gate 26, 3256 Larvik",
        },
        {
          t: "Frøyhovveien 30, 5269 Larvik",
        },
      ],
      dragging: false,
      addMode: false,
      newLocation: "",
      directionsService: null,
      directionsRenderer: null,
    };
  },
  computed: {
    google: gmapApi,
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
  },
  watch: {
    data: function (newValue) {
      this.newLocation = newValue.t;
    },
  },
  methods: {
    addLocation(address) {
      if (address) {
        let newLoc = {
          t: address,
        };
        this.addresses.push(newLoc);
      }
    },
    toggleAddMode() {
      this.addMode = !this.addMode;
    },
    removeWaypoint(index) {
      console.log(index);
      this.addresses.splice(index, 1);
    },
    returnIcon(i) {
      let lastElement = this.addresses.length - 1;
      if (i == 0) {
        return "vinyl";
      }
      if (lastElement == i) return "flag-fill";
      else return "record2";
    },
    reverseGeocode(latLng) {
      new window.google.maps.Geocoder().geocode(
        { location: latLng },
        (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              let newLocation = {
                t: results[0].formatted_address,
              };
              console.log(newLocation);
              console.log(results)
              this.newLocation = newLocation.t;
            }
          }
        }
      );
    },

    addClickEventListener() {
      this.map.addListener(
        "click",
        (e) => {
          let newLoc = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
          };
          if (this.addMode) {
            this.reverseGeocode(newLoc);
          }
        },
        {
          passive: true,
        }
      );
    },
    createRouteObject(optimize) {
      let info = this.addresses;

      let request = {
        origin: info[0].t,
        travelMode: "DRIVING",
        waypoints: [],
        destination: info[info.length - 1].t,
        optimizeWaypoints: optimize,
      };
      for (let i = 0; i < info.length; i++) {
        if (i != 0 && i != info.length - 1) {
          let waypoint = {
            location: info[i].t,
            stopover: true,
          };
          request.waypoints.push(waypoint);
        }
      }
      this.planRoute(request);
    },
    planRoute(request) {
      // Clears the existing route
      this.removeRoute();

      // Initializes the service and renderer.
      this.directionsService = new window.google.maps.DirectionsService();
      this.directionsRenderer = new window.google.maps.DirectionsRenderer();

      // Connects the map and display.
      this.directionsRenderer.setMap(this.map);

      let display = this.directionsRenderer;
      this.directionsService.route(request, function (result, status) {
        if (status == "OK") {
          display.setDirections(result);
        }
      });
    },
    removeRoute() {
      if (this.directionsRenderer != null) {
        this.directionsRenderer.setMap(null);
        this.directionsRenderer = null;
      }
    },
  },
  mounted() {
    this.addClickEventListener();
  },
};
</script>

<style scoped>
.openIcon {
  position: absolute;
  height: 25px;
  width: 25px;
  margin: 0;
  top: 5px;
  right: 5px;
}
.openTripPlanner {
  position: fixed;
  bottom: 125px;
  right: 10px;
  height: 40px;
  width: 40px;
  background-color: #f7f8f8;
  border: 2px solid white;
  cursor: pointer;
  box-shadow: 0px 0px 5px 1px black;
}
.addModeDropdown {
  background-color: #f7f8f8;
  text-align: center;
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
}
.addWaypointsForm {
  color: black;
}
.addIconContainer {
  position: absolute;
  margin-left: -5px;
  margin-top: 5px;
}
.addContainer {
  cursor: pointer;
  padding: 0 10% 0 10%;
}
.addWaypointButton {
  border-radius: 10px;
  padding: 5px;
  text-align: center;
}
.removeItem {
  cursor: pointer;
  position: absolute;
  right: 40px;
  padding: 5px;
}
.removeItem :hover {
  color: black;
}
.container {
  position: fixed;
  top: 60px;
  right: 0px;
  max-height: 100vh;
  width: 100vw;
  background-color: #f7f8f8;
  color: black;
  padding: 25px;
  overflow: scroll;
}
.iconContainer {
  padding: 5px;
  cursor: pointer;
}
.address {
  font-weight: 600;
  margin: 0;
  padding: 5px;
}
.exitCross {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}
.exitCross :hover {
  color: black;
}
.title {
  padding-bottom: 5px;
  border-bottom: 2px solid black;
}
.item {
  border: 2px solid black;
  border-radius: 5px;
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
}
.group {
  cursor: grab;
}
.ghost {
  opacity: 0.5;
  background: #647075;
}

button {
  margin: 5px;
  background-color: #65bf95;
  box-shadow: 0px 0px 5px 0.5px gray;
  color: black;
  border: 0px;
  border-radius: 10px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.buttonContainer {
  text-align: center;
  margin-top: 10px;
  margin-bottom: -5px;
}
.buttonContainer :hover {
  background-color: #8cacc4;
}
@media (min-width: 768px) {
  .container {
    top: 70px;
    right: 15px;
    max-height: 70vh;
    width: 400px;
    border-radius: 25px;
    box-shadow: 0px 0px 10px 2px black;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>