<template>
  <WidgetBase :title="$t(item.title)" class="base">
    <!-- <RegionToggle v-on:status="getAmountOfContainers($event)"></RegionToggle> -->
    <!-- <MountTypeToggle
      v-on:status="getAmountOfContainers($event)"
    ></MountTypeToggle> -->

    <Spinner v-if="loading"> </Spinner>

    <div v-else class="bottom">
      <div class="amount">{{ amount }}</div>
      <span @click="$router.push('fleetmanagement/containers')">
        <ArrowButton class="button"></ArrowButton>
      </span>
    </div>
  </WidgetBase>
</template>

<script>
import WidgetBase from "./widgetComponents/WidgetBase.vue";
import ArrowButton from "./widgetComponents/ArrowButton.vue";
import {
  GetContainerAmount,
  getAllContainers,
  getContainers,
} from "@/services/reenapi.js";
import Spinner from "./widgetComponents/Spinner";
import { mapActions, mapGetters } from "vuex";
// import RegionToggle from "./widgetComponents/RegionToggle.vue";
// import MountTypeToggle from "./widgetComponents/MountTypeToggle.vue";

export default {
  name: "ContainerTotal",
  components: {
    WidgetBase,
    Spinner,
    ArrowButton,
    // RegionToggle,
    // MountTypeToggle,
  },
  props: {
    options: {
      type: Object,
      required: false,
    },
    item: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["getCurrentRegion"]),
  },
  watch: {
    async getCurrentRegion(newValue) {
      this.loading = true;
      let params = {
        region: newValue,
      };
      console.log(newValue);
      if (newValue == "All") {
        let response = await GetContainerAmount();
        this.amount = response.data;
      } else {
        let response = await GetContainerAmount(params);
        this.amount = response.data;
      }
      this.loading = false;
    },
  },
  data() {
    return {
      amount: null,
      allContainers: [],
      loading: false,
    };
  },
  methods: {
    ...mapActions(["setTableData"]),
    // async sendDataToTable() {
    //   let response = await this.getAll();
    //   this.setTableData(response);
    //   console.log(response);
    // },
    async getAmountOfContainers(status) {
      this.loading = true;
      if (status == false) {
        let response = await GetContainerAmount();
        this.amount = response.data;
      } else {
        let params = {
          region: this.getCurrentRegion,
        };
        let response = await GetContainerAmount(params);
        this.amount = response.data;
      }
      this.loading = false;
    },
    async getAll() {
      let response = await getAllContainers();
      return response;
    },
  },
  mounted() {
    this.getAmountOfContainers();
  },
};
</script>

<style scoped>
.base {
  position: relative;
}
.button {
  float: right;
  margin: 8px 0px 8px 8px;
}
.amount {
  float: left;
  font-size: 5vh;
  position: absolute;
  bottom: 0;
}
</style>
